import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'


import AuthService from '../../features/auth/auth.service'


import { Button, Modal, Form  } from 'react-bootstrap';

import Select from 'react-select';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import Dialog from '../../services/Dialogs'




import ModalNuevoEvento from './ModalNuevoEvento'

import ModalEditarEvento from './ModalEditarEvento'

import ModalDetalleEvento from './ModalDetalleEvento'


import Archivos from '../Archivos/Archivos'



import {labelType} from '../Tickets/labelType'


import { esFechaFinMayor } from '../../services/funcionesPublicas'

import { SERVER_ } from '../../config'







const Eventos = () =>{


  const dialog = new Dialog();

  const user = AuthService.getCurrentUser()



  const formateoFecha = (fecha:any) =>{ return moment(fecha).format('DD [DE] MMMM [DE] YYYY').toUpperCase() }

const getEventStatus = (input:any)=>{
    // console.log(input)
    let {start, end, event} = input;

    if (event < start){
        return "Por Empezar";
    } else if (event > start && event < end){
        return "En Proceso";
    } else {
        return "Acabó";
    }
}


      const [ eventos, setEventos ] = useState<any>([])
      const [ solo_del_dia, setSoloDelDia ] = useState<boolean>(true)

      const cargar = async (startDate:any, endDate:any) => {
        try{
            var sD = moment(startDate).format('YYYY-MM-DD')
            var eD = moment(endDate).format('YYYY-MM-DD')
            setStartDateSelected(sD);
            setEndDateSelected(eD);
            let res = await RequestService.getEndPointPost(`eventos/${solo_del_dia}/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}`, { dependencias : etiquetas_seleccionadas })
            setEventos(res.data)

        }
        catch(e){
          toast.error('Problemas cargando')
          toast.error(JSON.stringify(e))
        }
      }

      React.useEffect(() => {
        cargar(startDate,endDate)
        cargarDependencias()
      }, []);


  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const onChange = (dates:any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };





  const [ show_ModalNuevoEvento, setModalNuevoEvento ] = useState<boolean>(false)
  const handleShow_ModalNuevoEvento = () => {
    setModalNuevoEvento(true)
  }
  const handleClose_ModalNuevoEvento = () => {
    cargar(startDate,endDate)
    setModalNuevoEvento(false)
  }


  const returnIcon = (s:string)=>{
    let r = <i className="fa fa-asterisk" />

    if(s==='Público') r = <i className="fa fa-globe" />
    if(s==='Privado') r = <i className="fa fa-lock" />
    if(s==='Sin confirmar') r = <i className="fa fa-asterisk" />
    if(s==='Confidencial') r = <i className="fa fa-lock" />

    return r
  }



  const eliminar =async (idevento:string)=>{
    try{
      if(await dialog.confirm('¿Estás seguro?',{},'El Evento se eliminará permanentemente y ningún usuario podrá verlo')){
        let res = await RequestService.delete('evento/'+idevento)
        if(res.data){
          toast.warning('El Evento se elimino correctamente')
          cargar(startDate,endDate)
        }
        else{ toast.error('Error al eliminar Evento') }
      }
    }
    catch(e){
      toast('Error al intentar eliminar el Evento')
    }
  }






  const [ evento_seleccionado, setEvento ] = useState<any>({})
  const [ show_ModalEditarEvento, setModalEditarEvento ] = useState<boolean>(false)
  const handleShow_ModalEditarEvento = (evento:any) => {
    setEvento(evento)
    setModalEditarEvento(true)
  }
  const handleClose_ModalEditarEvento = () => {
    cargar(startDate,endDate)
    setModalEditarEvento(false)
  }



  const [startDate_selected, setStartDateSelected] = useState<any>(null);
  const [endDate_selected, setEndDateSelected] = useState<any>(null);




const defaultValue : labelType[] = []
  const [ etiquetas_seleccionadas, setEtiquetasSeleccionada ] = useState<any>([])


  const [ dependencias, setDependencias ] = useState<any>([])
  const cargarDependencias = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('dependencias')
      setDependencias(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar dependencias')
    }
  }




  const [ show_ModalDetalleEvento, setModalDetalleEvento ] = useState<boolean>(false)
  const handleShow_ModalDetalleEvento = (evento:any) => {
    setEvento(evento)
    setModalDetalleEvento(true)
  }
  const handleClose_ModalDetalleEvento = () => {
    setModalDetalleEvento(false)
  }




  const [ show_Archivos, setArchivos ] = useState<boolean>(false)
  const handleShow_Archivos = (registro:any) => {
    setEvento(registro)
    setArchivos(true)
  }
  const handleClose_Archivos = () => {
    setArchivos(false)
  }




    const [ generando_reporte, setGenerandoReporte ] = useState<boolean>(false)

  const abrirReporteExcel = async () => {

    try{
        
        setGenerandoReporte(true)
        let res = await RequestService.getEndPointPostExcel(`reporte_eventos/0/${startDate_selected}/${endDate_selected}`, {  })
        if(res){
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Reporte Acumulado `+moment().format('YYYY_MM_DD_HH_mm')+`.xlsx`);
          document.body.appendChild(link);
          link.click();
        }
        setGenerandoReporte(false)
      }
      catch(e){
        setGenerandoReporte(false)
      }

  }


  const abrirReportePDF = async () => {

    try{
        
        setGenerandoReporte(true)
        let res = await RequestService.getEndPointPostExcel(`reporte_eventos_pdf/0/${startDate_selected}/${endDate_selected}`, {  })
        if(res){
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Reporte Acumulado `+moment().format('YYYY_MM_DD_HH_mm')+`.pdf`);
          document.body.appendChild(link);
          link.click();
        }
        setGenerandoReporte(false)
      }
      catch(e){
        setGenerandoReporte(false)
      }

  }








      return (
          <>

                          
            <div className="container-fluid contenedor">
              



              <h3 className="mt-3 mb-3">Calendario de Eventos</h3>



              <div className="row">
                <div className="col-md-2">


                        <div className="text-center">
                          
                        <div className="myContainer">
                          <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            inline
                            className="myDatePicker"
                          />

                          </div>

                          <button disabled={!endDate} onClick={()=>{cargar(startDate,endDate)}} className="btn btn-xs btn-default">Cargar Lista de Eventos <i className="fa fa-refresh"/></button>

                        </div>
{
  // JSON.stringify(user.is_admin)
}

                        {
                          user.is_admin && (

                                    <Form.Group
                                      controlId="ticket_type_id"
                                      className="mb-3"  >
                                          <Form.Label>Dependencias</Form.Label>
                                          <Select
                                            isMulti
                                            defaultValue={defaultValue}
                                            onChange={(opt:any,e:any)=>{ setEtiquetasSeleccionada(opt) }}
                                            isClearable={true}
                                            name="colors"
                                            options={dependencias}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                          />
                                  </Form.Group>

                          )
                        }





{
  // JSON.stringify(user.role_id)
}



                        <h6>Opciones</h6>

                        <ul className="list-group list-group-flush">
                          <li onClick={handleShow_ModalNuevoEvento} className="list-group-item"><button type="button" className="btn btn-link">Nuevo Evento</button></li>
                          <li className="list-group-item"><a onClick={abrirReporteExcel} className="btn btn-link"><i className="fa fa-file-excel-o"/> Reporte</a></li>
                          <li className="list-group-item"><a onClick={abrirReportePDF} className="btn btn-link"><i className="fa fa-file-pdf-o"/> Reporte</a></li>
                          <li className="list-group-item">
                              <Form.Check 
                                type="checkbox"
                                checked={solo_del_dia}
                                onChange={(e:any)=>{setSoloDelDia(e.target.checked)}}
                                id={`default-radio`}
                                label={`Eventos del día`}
                                />
                          </li>
                        </ul>







                </div>
                <div className="col-10">


                          {
                            startDate_selected === endDate_selected && (
                                <h4>Publicaciones para el día {startDate_selected}</h4>
                              )
                          }
                      

                          {
                            startDate_selected !== endDate_selected && (
                                <h4>Publicaciones entre el día {startDate_selected} y el {endDate_selected}</h4>
                              )
                          }




{
  eventos.length > 0 && (


                      <table className="table table-sm mb-3">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col">Creado por</th>
                            <th scope="col">Dependencia</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col">Inicio</th>
                            <th scope="col">Fin</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>



                              {
                                eventos.map((evento:any)=>

                                  <tr key={ Math.random().toString(36).substr(2, 9) }>

                                    <td className="v text-center" style={{width:30, background: evento.color, color : 'white'}}>
                                      {
                                        returnIcon(evento.publicacion)
                                      }
                                    </td>
                                    <td className="v pointer" onClick={(e)=>{handleShow_ModalDetalleEvento(evento)}}>
                                      <p className="nm" style={{fontSize:12}}><b>{evento.title}</b></p>
                                            {
                                              evento.labels.map( (label:any) => <span key={ Math.random().toString(36).substr(2, 9) } style={{marginRight:5, backgroundColor : label.color}} className="badge"><small>{label.title}</small></span> )
                                            }
                                    </td>
                                    <td className="v" style={{width:220}}>
                                      {
                                        <p className="nm" style={{fontSize:10}}>{evento.created_by.first_name} {evento.created_by.last_name}</p>
                                      }
                                    </td>
                                    <td className="v" style={{width:180}}>
                                      {
                                        evento.dependencia && (
                                              <p className="nm" style={{fontSize:10}}><b>{evento.dependencia.country}</b></p>
                                        )
                                      }
                                    </td>





                                    {
                                      esFechaFinMayor(`${evento.start_date} ${evento.start_time}`,`${evento.end_date} ${evento.end_time}`) && (
                                          <React.Fragment>

                                                <td className="v text-center" style={{width:30}}>
                                                    { 
                                                      // evento.termino === 1 && (<><i className="fa fa-hourglass-2"/> termino</>) 
                                                    }
                                                    <small>{ 
                                                          // evento.estatus_evento
                                                      getEventStatus({ start : Date.parse(`${evento.start_date}T${evento.start_time}`), end : evento.end_date ? Date.parse(`${evento.end_date}T${evento.end_time}`) : Date.parse(`${evento.start_date}T${evento.start_time}`), event :  new Date(moment().subtract(1, 'hours').format('YYYY-MM-DD HH:mm:ss')) })
                                                        }</small>
                                                </td>

                                                <td className="text-center v" style={{"width":"50px"}}>
                                                  {
                                                    // JSON.stringify(evento.dependencias)
                                                  }
                                                  {
                                                    evento.dependencias.length > 0 && ( <i className="fa fa-users" /> )
                                                  }
                                                </td>


                                              <td style={{width:100}}>
                                                {
                                                  evento.start_date && (
                                                      <React.Fragment>
                                                        <p className="nm"><b><small>{moment(evento.start_date).format('YYYY-MM-DD')}</small></b></p>
                                                        <small>{evento.start_time}</small>       
                                                      </React.Fragment>
                                                    )
                                                }
                                              </td>
                                              <td style={{width:100}}>
                                                {
                                                  evento.end_date && (
                                                      <React.Fragment>
                                                        <p className="nm"><b><small>{moment(evento.end_date).format('YYYY-MM-DD')}</small></b></p>
                                                        <small>{evento.end_time}</small>       
                                                      </React.Fragment>
                                                    )
                                                }
                                                {
                                                  // JSON.stringify(evento)
                                                }
                                              </td>

                                          </React.Fragment> 
                                        )
                                    }



                                    {
                                      !esFechaFinMayor(`${evento.start_date} ${evento.start_time}`,`${evento.end_date} ${evento.end_time}`) && (
                                          <td colSpan={4}>
                                            <div className="alert alert-danger nm text-center" role="alert">
                                              <small>Error con las fechas</small>
                                            </div>
                                          </td>
                                        )
                                    }


                                      {
                                        evento.client_id!==user.client_id && (
                                          <>
                                            <td colSpan={3} className="v text-center">
                                              <p className="nm"><small><b>Invitado</b></small></p>
                                            </td>
                                          </>
                                        )
                                      }


                                      {
                                        evento.client_id===user.client_id && (
                                          <>
                                            <td className="text-center v" style={{"width":"50px"}}>

                                                <button type="button" onClick={(e)=>{handleShow_ModalEditarEvento(evento)}} className="btn btn-sm btn-warning"><i className="fa fa-edit" /></button>

                                            </td>
                                            <td className="v" style={{width:50}}>
                                              <button type="button" onClick={(e)=>{eliminar(evento.id)}} className="btn btn-sm btn-danger"><i className="fa fa-trash" /></button>
                                            </td>
                                              <td className="v" style={{width:50}}>
                                                <button type="button" onClick={(e)=>{handleShow_Archivos(evento)}} className="btn btn-sm btn-primary"><i className="fa fa-cloud" /></button>
                                              </td>
                                          </>
                                        )
                                      }
                                  </tr>


                                )
                              }


                      </tbody>
                    </table>


  )
}


                      {
                        eventos.length === 0 && (

                      <div className="alert alert-primary d-flex align-items-center" role="alert">
                        
                        <div>
                          <i className="fa fa-calendar" /> No tienes eventos para este día
                        </div>
                      </div>

                        )
                      }








                </div>
              </div>


              
                



            </div>
              


  {
    show_ModalNuevoEvento && (
      <ModalNuevoEvento show={show_ModalNuevoEvento} handleClose={handleClose_ModalNuevoEvento}  />
      )
  }


  {
    show_ModalEditarEvento && (
      <ModalEditarEvento show={show_ModalEditarEvento} handleClose={handleClose_ModalEditarEvento} evento={evento_seleccionado} />
      )
  }


  {
    show_ModalDetalleEvento && (
      <ModalDetalleEvento show={show_ModalDetalleEvento} handleClose={handleClose_ModalDetalleEvento} evento={evento_seleccionado} />
      )

  }



  {
    show_Archivos && (
      <Archivos show={show_Archivos} handleClose={handleClose_Archivos} tipo_archivo="eventos" registro={evento_seleccionado} />
      )

  }




          </>

      );
  

}

export default Eventos
