import React, { useState, useRef } from 'react';

import RequestService from "../../services/request.service";


import { toast } from 'react-toastify'

import moment from 'moment'

import { Tab, Tabs } from 'react-bootstrap';


import { Button, Modal, Form  } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import Select from 'react-select';



import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';


import Dialog from '../../services/Dialogs'



import ModalNuevaTarea from './ModalNuevaTarea'
import ModalEditarTarea from './ModalEditarTarea'




const OverLayMiembro = ( params : any) => {

  const dialog = new Dialog();

  const { idproyecto, cargarProyectoDetalles } = params


  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event:any) => {
    setShow(!show);
    setTarget(event.target);
  };

  const schema = yup.object().shape({
    user_id: yup.string().required(),
    project_id: yup.string().required(),
    is_leader: yup.boolean(),
  });


  const [ usuarios_miembros_disponibles, setMiembrosDisponibles ] = useState<any>([])
  const cargar = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('proyecto/miembros_disponibles/'+idproyecto)
      setMiembrosDisponibles(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar dependencias')
    }
  }

  React.useEffect(() => {
    cargar()
  }, []);


  return (

    <li className="list-inline-item">

      <div ref={ref}>

        <button type="button" onClick={handleClick} className="btn btn-sm btn-link"><small>Agregar Miembro</small></button>

        <Overlay
          show={show}
          target={target}
          placement={'right'}
          container={ref}
          containerPadding={20}
        >
          <Popover id="popover-contained">
            <Popover.Header as="h3">Agregar Miembro</Popover.Header>
            <Popover.Body>





            <Formik
              validationSchema={schema}
              onSubmit={async(values:any)=>{
              
                        try{

                            if(await dialog.confirm('¿Estás seguro?',{})){
                                await RequestService.create('proyecto/miembros/agregar_miembro', { ...values } )
                                setShow(false)
                                toast.success('El Miembro fue agregado correctamente')
                                cargarProyectoDetalles()
                                cargar()
                            }
              
                        }
                        catch(e){
                          toast.error('Error al dar de alta')
                        }
                        // console.log({ ...values })
                      }}
              initialValues={{

                user_id : "",
                project_id : idproyecto,
                is_leader : true,

              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form id='my-form' noValidate onSubmit={handleSubmit} style={{width:200}}>


                    <Form.Group
                      controlId="user_id"
                      className="mb-3"
                    >

                      <Form.Label>Posible Miembro</Form.Label>
                      <Form.Control
                        type="hidden"
                        name="user_id"
                        value={values.user_id}
                        onChange={handleChange}
                        isValid={touched.user_id && !errors.user_id}
                        isInvalid={!!errors.user_id}
                      />
                    <Select options={usuarios_miembros_disponibles} name="user_id"  onChange={(opt:any,e:any)=>{ setFieldValue('user_id', opt.value ) }} />
                      <Form.Control.Feedback type="invalid">{errors.user_id}</Form.Control.Feedback>
                    </Form.Group>






                </Form>
              )}
            </Formik>


              <button className="btn btn-success btn-sm"  form='my-form' type="submit">Agregar</button>

            </Popover.Body>
          </Popover>
        </Overlay>
      </div>


    </li>

  );
}

const ModalDetallesProyecto = ( params : any ) => {


  const dialog = new Dialog();

  const { show, handleClose, proyecto } = params


  const [ proyectoDetalles, setProyectoDetalles ] = useState<any>({ miembros : [{ user_id : { first_name : "", last_name : "" } }], tareas : [{ title : "", description : "", status_id : { id : 0 }, assigned_to: { first_name : "", last_name : "" }, labels : [{ color : "", title : "" }], colaboradores : [] }] })

  const cargarProyectoDetalles = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('proyecto/'+proyecto.id)
      setProyectoDetalles(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar proyectos')
    }
  }

  React.useEffect(() => {
    cargarProyectoDetalles()
  }, []);




  const [ show_ModalNuevaTarea, setModalNuevaTarea ] = useState<boolean>(false)
  const handleShow_ModalNuevaTarea = () => {
    setModalNuevaTarea(true)
  }
  const handleClose_ModalNuevaTarea = () => {
    cargarProyectoDetalles()
    setModalNuevaTarea(false)
  }

  const eliminarMiembro = async (idmiembro:string)=>{
    try{
      if(await dialog.confirm('¿Estás seguro?',{},'Este miembro será eliminado')){
        let res = await RequestService.delete('proyecto/miembros/eliminar/'+idmiembro)
        if(res.data){
          toast.warning('El Miembro se elimino correctamente')
          cargarProyectoDetalles()
        }
        else{ toast.error('Error al eliminar Miembro') }
      }
    }
    catch(e){
      toast('Error al intentar eliminar el Miembro')
    }
  }



  const [ tarea_seleccionada, setTareaSeleccionada ] = useState<any>({})
  const [ show_ModalEditarTarea, setModalEditarTarea ] = useState<boolean>(false)
  const handleShow_ModalEditarTarea = (tarea:any) => {
    setTareaSeleccionada(tarea)
    setModalEditarTarea(true)
  }
  const handleClose_ModalEditarTarea = () => {
    cargarProyectoDetalles()
    setModalEditarTarea(false)
  }




  const eliminarTarea =async (idtarea:string)=>{
    try{
      if(await dialog.confirm('¿Estás seguro?',{},'Vas a eliminar esta tarea')){
        let res = await RequestService.delete('proyecto/tarea/'+idtarea)
        if(res.data){
          toast.warning('La tarea se elimino correctamente')
          cargarProyectoDetalles()
        }
        else{ toast.error('Error al eliminar proyecto') }
      }
    }
    catch(e){
      toast('Error al intentar eliminar el proyecto')
    }
  }




const [key, setKey] = useState<any>('home');


const colorb = (c:number)=>{
  let v = ''
  if(c===1) v = '#f9a52d';
  if(c===2) v = '#1672b9';
  if(c===3) v = '#00b393';
  return v
}

  return (
    <>
      <Modal show={show} dialogClassName="modal-xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Ficha del Proyecto</Modal.Title>
        </Modal.Header>
        <Modal.Body>




    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="home" title="Vista General">


        <h6>Vista General del Proyecto</h6>


          <small><b>Nombre del Proyecto</b></small>
          <p>{proyecto.title}</p>

          <small><b>Detalles del Proyecto</b></small>
          <p>{proyecto.description}</p>


          <ul className="list-inline">
            <li className="list-inline-item" style={{marginRight:40}}>
                <small><b>Fecha de inicio</b></small>
                <p>{moment(proyecto.start_date).format('dddd DD [DE] MMMM [DE] YYYY').toUpperCase()}</p>
            </li>
            <li className="list-inline-item" style={{marginRight:40}}>
                <small><b>Fecha límite</b></small>
                <p>{ proyecto.deadline ? moment(proyecto.deadline).format('dddd DD [DE] MMMM [DE] YYYY').toUpperCase() : 'Sin Fecha'}</p>
            </li>
            <li className="list-inline-item" style={{marginRight:40}}>
                <small><b>Fecha de Creación</b></small>
                <p>{moment(proyecto.created_date).format('DD [DE] MMMM [DE] YYYY').toUpperCase()}</p>
            </li>
          </ul>



          {
            proyecto.labels.map( (label:any) => <span key={ Math.random().toString(36).substr(2, 9) } style={{marginRight:5, backgroundColor : label.color}} className="badge">{label.title}</span> )
          }


          {
            // JSON.stringify(proyecto)
          }

      </Tab>

      <Tab eventKey="homerun" title="Miembros del Proyecto">

        <h6>Miembros del Proyecto</h6>

          {
            // JSON.stringify(proyectoDetalles.miembros)
          }

              <ul className="list-inline">
                  <OverLayMiembro idproyecto={proyecto.id} cargarProyectoDetalles={cargarProyectoDetalles} />                
              </ul>


              <ul className="list-group list-group-light">
              {
                proyectoDetalles.miembros.map( (miembro:any) =>


                <li key={ Math.random().toString(36).substr(2, 9) } className="list-group-item d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <img src="avatar.jpg" alt="" style={{width: '45px', height: '45px'}}
                      className="rounded-circle" />
                    <div className="ms-3">
                      <p className="fw-bold mb-1">{miembro.user_id.first_name} {miembro.user_id.last_name}</p>
                      <p className="text-muted mb-0">john.doe@gmail.com</p>
                    </div>
                  </div>
                  <span onClick={()=>{eliminarMiembro(miembro.id)}} className="badge bg-danger pointer"><i className="fa fa-trash"/></span>
                </li>

                  )
              }


              </ul>

      </Tab>
      <Tab eventKey="task" title="Lista de Tareas">

        <h6>Lista de Tareas</h6>

          <ul className="list-inline">
            <li className="list-inline-item">
                <button onClick={handleShow_ModalNuevaTarea} type="button" className="btn btn-sm btn-link">Nueva Tarea</button>
            </li>
          </ul>




                  <React.Fragment>

        
                        <table className="display dataTable no-footer mb-3">
                          <thead>
                            <tr>
                              <th style={{width:20}}></th>
                              <th  >Tarea</th>
                              <th style={{width:170}}>Asignado a</th>
                              <th style={{width:30}}></th>
                              <th style={{width:95}}>Estatus</th>
                              <th style={{width:95}}>Fecha de Inicio</th>
                              <th style={{width:95}}>Fecha Límite</th>
                              <th style={{width:95}}>Creación</th>
                              <th></th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>

                          {
                            proyectoDetalles.tareas.map( (tarea:any) =>

                                <tr key={ Math.random().toString(36).substr(2, 9) }>
                                  <td style={{background: colorb(tarea.status_id.id),color:'white'}} >
                                    { tarea.status_id.id === 1 && ( <i className="fa fa-wrench" /> ) }
                                    { tarea.status_id.id === 2 && ( <i className="fa fa-clock-o" /> ) }
                                    { tarea.status_id.id === 3 && ( <i className="fa fa-check-circle" /> ) }
                                    
                                  </td>
                                  <td className="v">
                                      <small>{tarea.title}</small>
                                      {
                                        // JSON.stringify(tarea.colaboradores)
                                      }

                                      <p className="nm"><small>{
                                        tarea.labels.map( (label:any) => <span key={ Math.random().toString(36).substr(2, 9) } style={{marginRight:5, backgroundColor : label.color}} className="badge">{label.title}</span> )
                                      }</small></p>
                                  </td>
                                  <td className="v">
                                      {
                                        // JSON.stringify(tarea.assigned_to.first_name)
                                      }
                                      <small>{ tarea.assigned_to ? tarea.assigned_to.first_name + ' '+ tarea.assigned_to.last_name : ' - '} </small>
                                  </td>
                                  <td className="v">
                                      {
                                        tarea.colaboradores.length > 0 && (
                                          <React.Fragment>
                                            <small><i className="fa fa-users pointer" /></small>
                                          </React.Fragment>
                                        )
                                      }
                                  </td>
                                  <td className="v">
                                      <small>{tarea.status_id.title}</small>
                                  </td>
                                  <td className="v">
                                      <small>{ tarea.start_date ? moment(tarea.start_date).format('DD-MM-YYYY').toUpperCase() : ''}</small>
                                  </td>
                                  <td className="v">
                                      <small>{ tarea.deadline ? moment(tarea.deadline).format('DD-MM-YYYY').toUpperCase() : ''}</small>
                                  </td>
                                  <td className="v">
                                      <small>{moment(tarea.created_date).format('DD-MM-YYYY').toUpperCase()}</small>
                                  </td>
                                  <td style={{width:40}}>
                                    <button type="button" onClick={(e:any)=>{handleShow_ModalEditarTarea(tarea)}} className="btn btn-sm btn-light"><i className="fa fa-edit" /></button>
                                  </td>
                                  <td style={{width:40}}>
                                    <button type="button" onClick={(e:any)=>{eliminarTarea(tarea.id)}} className="btn btn-sm btn-light"><i className="fa fa-trash" /></button>
                                  </td>
                                </tr>

                                )
                          }
                          </tbody>
                        </table>


                  </React.Fragment>




      </Tab>




    </Tabs>




        </Modal.Body>
      </Modal>





  {
    show_ModalNuevaTarea && (
      <ModalNuevaTarea show={show_ModalNuevaTarea} handleClose={handleClose_ModalNuevaTarea} idproyecto={proyecto.id} />
      )

  }

  {
    show_ModalEditarTarea && (
      <ModalEditarTarea show={show_ModalEditarTarea} handleClose={handleClose_ModalEditarTarea} tarea={tarea_seleccionada} />
      )

  }


    </>
  );
}


export default ModalDetallesProyecto;