import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import Dialog from '../../services/Dialogs'

const ModalCambiarLogo = ( params : any ) => {

  const { show, handleClose, dependencia } = params


  const dialog = new Dialog();

  const [ cargando_, setCargando ] = useState( true )

  const [ nuevo_logo, setNuevoLogo ] = useState<any>("")

  const handleFileLogoA = (e:any) => {
    if(e.target.files.length>0){
      var files = e.target.files
        const reader = new FileReader()
            reader.onloadend = () => {
              if(reader.result){
              setNuevoLogo(reader.result)
            }
        }
        reader.readAsDataURL(files[0])
    }
  };



const cambiarLogo = async ( event:any) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ''
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setCargando(true)
        let res_ = await RequestService.update( `dependencia/logo/${dependencia.id}` , { logo : nuevo_logo })

          if(res_){
            toast.success('El logo se actualizó correctamente')
              handleClose()
          }

        setCargando(false)

        }
        catch(e:any){
          console.log('ah=?')
          console.log(e.response)
          toast.error(e.response.data)
        setCargando(false)
          // console.log(e.response.data)
        } 
        
      }
     
}



  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Modal ModalCambiarLogo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(dependencia)
          }

          <small>Dependencia</small>
          <h4>{dependencia.company_name}</h4>
          <h6>{dependencia.country}</h6>


                              <div className="form-group">
                                <label htmlFor="logo" className="form-label">Seleccionar Logo</label>
                                <input id="logo" type="file" name="file" className="form-control" accept="image/jpeg,image/jpg,image/png" multiple={false} onChange={handleFileLogoA} />
                              </div>

                             {nuevo_logo !== ""  && (
                                <div className="mt-2">
                                 <p>
                                   <img src={nuevo_logo} className="rounded float-left" alt="..." width="100%" />
                                 </p>
                                </div>

                              )}
                        
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" onClick={cambiarLogo} type="button">Cambiar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalCambiarLogo;