import axios from 'axios';
// import authHeader from './auth-header';
import { SERVER_ } from  '../config';
// const API_URL = 'http://localhost:8080/api/test/';
// const API_URL = Config.ip;

import AuthService from '../features/auth/auth.service'

import { toast } from 'react-toastify'



class RequestService {

  token : string = ""
  instance


  constructor(){

      this.token = AuthService.getToken()

      this.instance = axios.create({
        baseURL: SERVER_,
        headers: {
          "Content-Type": "application/json",
          "Authorization" : 'Bearer '+ AuthService.getToken()
        },
      });

      this.instance.interceptors.response.use(
        (res) => {
          return res;
        },
        async (err) => {

          // const originalConfig = err.config;

            if (err.response.status === 405 ) {
              // EXPIRO 405

              if( [405].indexOf( err.response.data.error_code ) >= 0 ){

                  
                  var data_user = JSON.parse(localStorage.getItem('user') || '')
                  const rs = await this.instance.post( SERVER_ + "user/refresh-token", {
                    uuid: data_user.uuid
                  });
                  const { accessToken } = rs.data;
                  localStorage.setItem("accessToken", JSON.stringify( accessToken ));
                  this.token = accessToken


                  setTimeout(() => {
                      toast.warning('Tu Token de acceso fue actualizado. Por favor intenta de nuevo tu consulta.')
                  }, 2000);

              }

              if([407,406,408].indexOf( err.response.data.error_code ) >= 0 ){
                  localStorage.removeItem("user");
                  localStorage.removeItem("rol");
                  localStorage.removeItem("accessToken");
                  toast.error('Problemas con tu cuenta, reinicia sesión')

                  setTimeout(() => {
                      window.location.reload();
                    }, 3000);

              }


            }
          

          return Promise.reject(err);
        }
      );


  }



  getPublicContent() {
    return axios.get( SERVER_ + 'all', { headers: {"Authorization" : 'Bearer '+this.token } });
  }


  getEndPoint(endpoint : String){
    return this.instance.get( SERVER_ +endpoint, { headers: {"Authorization" : 'Bearer '+this.token } })
    // return axios.get( SERVER_ +endpoint, { headers: {"Authorization" : 'Bearer '+this.token } })
  }

  getEndPointPost(endpoint : String,data:any){
    return this.instance.post( SERVER_ +endpoint, data, { headers: {"Authorization" : 'Bearer '+this.token } })
    // return axios.get( SERVER_ +endpoint, { headers: {"Authorization" : 'Bearer '+this.token } })
  }
  getEndPointPostExcel(endpoint : String, data:any){
    return this.instance.post( SERVER_ +endpoint, data, { headers: {"Authorization" : 'Bearer '+this.token }, responseType: 'arraybuffer' })
    // return axios.get( SERVER_ +endpoint, { headers: {"Authorization" : 'Bearer '+this.token } })
  }
  
  create(endpoint:String,data:any){
    return axios.post( SERVER_ +endpoint, data, { headers: {"Authorization" : 'Bearer '+this.token } } )
  }

  update(endpoint:String,data:any){
    return axios.patch( SERVER_ +endpoint, data, { headers: {"Authorization" : 'Bearer '+this.token } } )
  }
  delete(endpoint:String){
    return axios.delete( SERVER_ +endpoint, { headers: {"Authorization" : 'Bearer '+this.token } } )
  }

  downloadFile(endpoint:String){
    return axios.get( SERVER_ +endpoint, { responseType: 'blob', headers: {"Authorization" : 'Bearer '+this.token } } )
  }






}

export default new RequestService();