// import React from 'react'
import React, { useState, useEffect } from 'react'

import { Link, Route, Routes, useLocation } from 'react-router-dom'



// import { useNavigate } from "react-router"

// import { Offcanvas, Button } from 'react-bootstrap'


import { PrivateRoute } from './PrivateRoute'
import NotFound from './pages/NotFound'
import Login from './pages/Login'
// import Users from './pages/Users'
import Dashboard from './pages/Dashboard'
// import { ROLE } from './features/auth/auth'
import { ROLE } from './features/auth/role'

// import { useDispatch, useSelector } from 'react-redux'
// import { logout, selectCurrentUser, selectIsAuthenticated } from 'features/auth/authSlice'
import AuthService from './features/auth/auth.service'



import Profile from './pages/Profile'


import Publicaciones from './pages/Publicaciones/Publicaciones'
import MisPublicaciones from './pages/Publicaciones/MisPublicaciones'



import Redes from './pages/Redes/Redes'
import ReportarRedes from './pages/Redes/ReportarRedes'




import Dependencias from './pages/Dependencias/Dependencias'

import Proyectos from './pages/Proyectos/Proyectos'


import Eventos from './pages/Eventos/Eventos'




import MisTickets from './pages/Tickets/MisTickets'





import Boletines from './pages/Boletines/Boletines'


import Reportes from './pages/Reportes/Reportes'





import TareasSemanales from './pages/TareasSemanales/TareasSemanales'
import MisTareasSemanales from './pages/TareasSemanales/MisTareasSemanales'









import Usuarios from './pages/Usuarios/Usuarios'




import './App.scss';


function BootHeader() {
  // const dispatch = useDispatch()
  // const user = useSelector(selectCurrentUser)
  const user = AuthService.getCurrentUser()
  const isAuthenticated = AuthService.selectIsAuthenticated

 
let location = useLocation();
// console.log(location.pathname)

let activo_perfil = location.pathname === '/profile' ? 'active' : ''
let activo_reporte_redes = location.pathname === '/reporte_redes' ? 'active' : ''
let activo_reportar_redes = location.pathname === '/reportar_redes' ? 'active' : ''
// let activo_publicaciones = location.pathname === '/publicaciones' ? 'active' : ''
let activo_mis_publicaciones = location.pathname === '/mis_publicaciones' ? 'active' : ''
let activo_tickets = location.pathname === '/tickets' ? 'active' : ''
let activo_dependencias = location.pathname === '/dependencias' ? 'active' : ''
let activo_proyectos = location.pathname === '/proyectos' ? 'active' : ''
let activo_eventos = location.pathname === '/eventos' ? 'active' : ''
let activo_boletines = location.pathname === '/boletines' ? 'active' : ''
let activo_reportes = location.pathname === '/reportes' ? 'active' : ''
let activo_usuarios = location.pathname === '/usuarios' ? 'active' : ''

let activo_tareas_semanales = location.pathname === '/tareas_semanales' ? 'active' : ''
let activo_mis_tareas_semanales = location.pathname === '/mis_tareas_semanales' ? 'active' : ''



  const [ ROLE_ADMIN, setAdmin ] = useState(false)
  const [ ROLE_VOCERO, setVocero ] = useState(false)
  const [ ROLE_DISENO, setDiseno ] = useState(false)
  // const [ ROLE_ANALYST, setAnalyst ] = useState(false)
  // const [ ROLE_VIEW, setView ] = useState(false)

  useEffect(() => {

      if(user.rol === "ROLE_ADMIN") setAdmin(true)
      if(user.rol === "ROLE_VOCERO") setVocero(true)
      if(user.rol === "ROLE_DISENO") setDiseno(true)

  },[ ROLE_ADMIN, ROLE_VOCERO,ROLE_DISENO, user]);


  // const navegar = (ruta:any) => {
  //   navigate(ruta)
  // }


  return (
    <>
    { isAuthenticated && ( 

      <>


      <nav className="navbar fixed-top navbar-expand-lg  navbar-0dark bg.-primary">
        <div className="container-fluid">
          <a className="navbar-brand" href="/#">
            <img src="./banner_logo.png" alt="" height="35px" className="d-inline-block align-text-top" style={{"marginRight":"85px"}} />
          </a>

          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">


                { (ROLE_ADMIN || ROLE_VOCERO || ROLE_DISENO )&& (
                  <li className="nav-item">
                    <Link className={'nav-link ' + activo_perfil } to="/profile"> <i className="fa fa-user-circle" /> </Link>
                  </li>
                )}





                { (ROLE_ADMIN) && (
                  <li className="nav-item">
                    <Link className={'nav-link ' + activo_dependencias } to="/dependencias"> <i className="fa fa-building" /> </Link>
                  </li>
                )}



                { (ROLE_ADMIN) && (
                  <li className="nav-item">
                    <Link className={'nav-link ' + activo_usuarios } to="/usuarios"> <i className="fa fa-users" /> </Link>
                  </li>
                )}




                { (ROLE_ADMIN || ROLE_VOCERO)&& (
                  <li className="nav-item">
                    <Link className={'nav-link ' + activo_eventos } to="/eventos"> <i className="fa fa-calendar" /> </Link>
                  </li>
                )}

                { 
                //   (ROLE_ADMIN || ROLE_VOCERO) && (
                //   <li className="nav-item">
                //     <Link className={'nav-link ' + activo_publicaciones } to="/publicaciones"> Publicaciones </Link>
                //   </li>
                // )
              }

                { (ROLE_ADMIN || ROLE_VOCERO) && (
                  <li className="nav-item">
                    <Link className={'nav-link ' + activo_proyectos } to="/proyectos"> <i className="fa fa-folder" /> </Link>
                  </li>
                )}


                { (ROLE_ADMIN || ROLE_VOCERO) && (
                  <li className="nav-item">
                    <Link className={'nav-link ' + activo_boletines } to="/boletines"> <i className="fa fa-star" /> </Link>
                  </li>
                )}



                { (ROLE_ADMIN || ROLE_VOCERO) && (
                  <li className="nav-item">
                    <Link className={'nav-link ' + activo_mis_publicaciones } to="/mis_publicaciones"> <i className="fa fa-comment"/> </Link>
                  </li>
                )}


                


                { (ROLE_ADMIN ) && (
                  <li className="nav-item">
                    <Link className={'nav-link ' + activo_reporte_redes } to="/reporte_redes"> <i className="fa fa-link"/> </Link>
                  </li>
                )}

                { (ROLE_VOCERO) && (
                  <li className="nav-item">
                    <Link className={'nav-link ' + activo_reportar_redes } to="/reportar_redes"> <i className="fa fa-link"/> </Link>
                  </li>
                )}




                { (ROLE_ADMIN) && (
                  <li className="nav-item">
                    <Link className={'nav-link ' + activo_tareas_semanales } to="/tareas_semanales"> <i className="fa fa-book"/> </Link>
                  </li>
                )}



                { (ROLE_VOCERO) && (
                  <li className="nav-item">
                    <Link className={'nav-link ' + activo_mis_tareas_semanales } to="/mis_tareas_semanales"> <i className="fa fa-book"/> </Link>
                  </li>
                )}






                { (ROLE_ADMIN || ROLE_VOCERO || ROLE_DISENO) && (
                  <li className="nav-item">
                    <Link className={'nav-link ' + activo_tickets } to="/tickets"> <i className="fa fa-tag" /> </Link>
                  </li>
                )}




                { (ROLE_ADMIN) && (
                  <li className="nav-item">
                    <Link className={'nav-link ' + activo_reportes } to="/reportes"> <i className="fa fa-file-pdf-o" /> </Link>
                  </li>
                )}




            </ul>

            <span className="navbar-text">
              Usuario: <Link className="link" to="/profile"> {user?.email} </Link>
            </span>

          </div>

        </div>
      </nav>
      
      </>


    ) }

      </>
  )




}

function App() {


let location = useLocation();
// console.log(location.pathname)

let activo_perfil = location.pathname === '/profile' ? 'active' : ''
let activo_reporte_redes = location.pathname === '/reporte_redes' ? 'active' : ''
let activo_reportar_redes = location.pathname === '/reportar_redes' ? 'active' : ''
// let activo_publicaciones = location.pathname === '/publicaciones' ? 'active' : ''
let activo_mis_publicaciones = location.pathname === '/mis_publicaciones' ? 'active' : ''
let activo_publicaciones = location.pathname === '/publicaciones' ? 'active' : ''
let activo_tickets = location.pathname === '/tickets' ? 'active' : ''
let activo_dependencias = location.pathname === '/dependencias' ? 'active' : ''
let activo_proyectos = location.pathname === '/proyectos' ? 'active' : ''
let activo_eventos = location.pathname === '/eventos' ? 'active' : ''
let activo_boletines = location.pathname === '/boletines' ? 'active' : ''
let activo_reportes = location.pathname === '/reportes' ? 'active' : ''
let activo_usuarios = location.pathname === '/usuarios' ? 'active' : ''


let activo_tareas_semanales = location.pathname === '/tareas_semanales' ? 'active' : ''
let activo_mis_tareas_semanales = location.pathname === '/mis_tareas_semanales' ? 'active' : ''


  const user = AuthService.getCurrentUser()
  const isAuthenticated = AuthService.selectIsAuthenticated

  const [ ROLE_ADMIN, setAdmin ] = useState(false)
  const [ ROLE_VOCERO, setVocero ] = useState(false)
  const [ ROLE_DISENO, setDiseno ] = useState(false)
  // const [ ROLE_ANALYST, setAnalyst ] = useState(false)
  // const [ ROLE_VIEW, setView ] = useState(false)

  useEffect(() => {

      if(user.rol === "ROLE_ADMIN") setAdmin(true)
      if(user.rol === "ROLE_VOCERO") setVocero(true)
      if(user.rol === "ROLE_DISENO") setDiseno(true)

  },[ ROLE_ADMIN, ROLE_VOCERO, ROLE_DISENO, user]);




  return (
    <>


    
      <BootHeader />


<div className="container-fluid">
    <div className="row flex-nowrap">
        <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark-" style={{background:'white', width:'250px'}}>
            <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">




                <ul className="nav nav-pills flex-column" id="acto" style={{paddingTop:'70px', width:'100%'}}>


                    <li className="nav-item">
                        <Link className={'nav-link ' + activo_perfil } to="/profile"><i className="fa fa-user-circle" /> Perfil </Link>
                    </li>


                { (ROLE_ADMIN) && (
                    <li className="nav-item">
                        <Link className={'nav-link ' + activo_dependencias } to="/dependencias"> <i className="fa fa-building" /> Dependencias </Link>
                    </li>
                )}


                { (ROLE_ADMIN) && (
                    <li className="nav-item">
                        <Link className={'nav-link ' + activo_usuarios } to="/usuarios"> <i className="fa fa-users" /> Usuarios </Link>
                    </li>
                )}


                { (ROLE_ADMIN || ROLE_VOCERO) && (
                    <li className="nav-item">
                        <Link className={'nav-link ' + activo_eventos } to="/eventos"><i className="fa fa-calendar" /> Eventos </Link>
                    </li>
                )}

                { (ROLE_ADMIN || ROLE_VOCERO) && (
                    <li className="nav-item">
                        <Link className={'nav-link ' + activo_proyectos } to="/proyectos"> <i className="fa fa-folder" /> Proyectos </Link>
                    </li>
                )}


                { (ROLE_ADMIN || ROLE_VOCERO) && (
                    <li className="nav-item">
                        <Link className={'nav-link ' + activo_boletines } to="/boletines"> <i className="fa fa-star" /> Boletines </Link>
                    </li>
                )}


                { (ROLE_ADMIN || ROLE_VOCERO) && (
                    <li className="nav-item">
                        <Link className={'nav-link ' + activo_mis_publicaciones } to="/mis_publicaciones"> <i className="fa fa-comment" /> Parrilla </Link>
                    </li>
                )}





                { (ROLE_ADMIN ) && (
                    <li className="nav-item">
                        <Link className={'nav-link ' + activo_reporte_redes } to="/reporte_redes"> <i className="fa fa-link" /> Reporte Redes </Link>
                    </li>
                )}

                { (ROLE_VOCERO ) && (
                    <li className="nav-item">
                        <Link className={'nav-link ' + activo_reportar_redes } to="/reportar_redes"> <i className="fa fa-link" /> Reporte Redes </Link>
                    </li>
                )}


                { (ROLE_ADMIN ) && (
                    <li className="nav-item">
                        <Link className={'nav-link ' + activo_tareas_semanales } to="/tareas_semanales"> <i className="fa fa-book" /> Tareas Semanales </Link>
                    </li>
                )}


                { (ROLE_VOCERO ) && (
                    <li className="nav-item">
                        <Link className={'nav-link ' + activo_mis_tareas_semanales } to="/mis_tareas_semanales"> <i className="fa fa-book" /> Tareas Semanales </Link>
                    </li>
                )}






                { (ROLE_ADMIN || ROLE_VOCERO || ROLE_DISENO) && (
                    <li className="nav-item">
                        <Link className={'nav-link ' + activo_tickets } to="/tickets"> <i className="fa fa-tag" /> Tickets </Link>
                    </li>
                )}

                { (ROLE_ADMIN) && (
                    <li className="nav-item">
                        <Link className={'nav-link ' + activo_reportes } to="/reportes"> <i className="fa fa-file-pdf-o" /> Reportes </Link>
                    </li>
                )}


                </ul>
            </div>
        </div>
        <div className="col oadding3">


                  <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="*" element={<NotFound />} />

                    <Route path="dashboard" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.VOCERO]} component={Dashboard} />} />

                    <Route path="publicaciones" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.VOCERO]} component={Publicaciones} />} />
                    <Route path="mis_publicaciones" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.VOCERO]} component={MisPublicaciones} />} />



                    <Route path="tickets" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.VOCERO, ROLE.DISENO]} component={MisTickets} />} />



                    <Route path="eventos" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.VOCERO]} component={Eventos} />} />


                    <Route path="dependencias" element={<PrivateRoute roles={[ROLE.ADMIN]} component={Dependencias} />} />


                    <Route path="proyectos" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.VOCERO]} component={Proyectos} />} />


                    <Route path="boletines" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.VOCERO]} component={Boletines} />} />



                    <Route path="reportes" element={<PrivateRoute roles={[ROLE.ADMIN]} component={Reportes} />} />



                    <Route path="reporte_redes" element={<PrivateRoute roles={[ROLE.ADMIN]} component={Redes} />} />
                    <Route path="reportar_redes" element={<PrivateRoute roles={[ROLE.VOCERO]} component={ReportarRedes} />} />




                    <Route path="tareas_semanales" element={<PrivateRoute roles={[ROLE.ADMIN]} component={TareasSemanales} />} />
                    <Route path="mis_tareas_semanales" element={<PrivateRoute roles={[ROLE.VOCERO]} component={MisTareasSemanales} />} />




                    <Route path="usuarios" element={<PrivateRoute roles={[ROLE.ADMIN]} component={Usuarios} />} />



                    <Route path="profile" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.VOCERO, ROLE.DISENO]} component={Profile} />} />


                  </Routes>



        </div>
    </div>
</div>



    </>
  )
}

export default App
