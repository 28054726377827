import React, { useState } from 'react';

import RequestService from "../../services/request.service";



import { Button, Modal, Form } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Select from 'react-select'


import Dialog from '../../services/Dialogs'


import './nuevo_boletin.scss'


const ModalNuevoBoletin = ( params : any ) => {


  const { show, handleClose } = params


  const dialog = new Dialog();
  
  const mensajes_validacion_generico = "Este campo es obligatorio"


const schema = yup.object().shape({
  folio : yup.string().required(mensajes_validacion_generico),
  titulo : yup.string().required(mensajes_validacion_generico),
  bullets : yup.string().nullable(),
  idtipo_boletin : yup.string().nullable().required(mensajes_validacion_generico),
  boletin : yup.string().required(mensajes_validacion_generico),

  url_imagenes : yup.string().nullable(),
  url_audio : yup.string().nullable(),
  url_video : yup.string().nullable(),

  fecha : yup.string().nullable().required(mensajes_validacion_generico),
});







  const [ tipo_boletin, setTipoBoletin ] = useState<any>([])
  const cargar = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('tipo_boletin')
      setTipoBoletin(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar los tipo boletin')
    }
  }

  React.useEffect(() => {
    cargar()
  }, []);




  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Nuevo Producto</Modal.Title>
        </Modal.Header>
        <Modal.Body>





            <Formik
              validationSchema={schema}
              onSubmit={async(values:any)=>{
              
                        try{

                              if(await dialog.confirm('¿Estás seguro?',{})){
                                // console.log(values)
                                  await RequestService.create('boletin/nuevo', { ...values } )
                                  toast.success('El Boletín se creo correctamente')
                                  handleClose()
                              }
              
                        }
                        catch(e){
                          toast.error('Error al dar de alta')
                        }
                        // console.log({ ...values })
                      }}
              initialValues={{

                folio : "", 
                titulo : "", 
                boletin : "", 
                // interesados : "", 
                bullets : "", 
                idtipo_boletin : "", 

                url_imagenes : "",
                url_audio : "",
                url_video : "",

                fecha : new Date()


              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form id='my-form' noValidate onSubmit={handleSubmit}>


{
  // JSON.stringify(values)
}


                    <Form.Group
                      controlId="folio"
                      className="mb-3"
                    >
                      <Form.Label>Folio</Form.Label>
                      <Form.Control
                        type="text"
                        name="folio"
                        value={values.folio}
                        onChange={handleChange}
                        isValid={touched.folio && !errors.folio}
                        isInvalid={!!errors.folio}
                      />
                      <Form.Control.Feedback type="invalid">{errors.folio}</Form.Control.Feedback>
                    </Form.Group>


                    <Form.Group
                      controlId="titulo"
                      className="mb-3"
                    >
                      <Form.Label>Título</Form.Label>
                      <Form.Control
                        type="text"
                        name="titulo"
                        value={values.titulo}
                        onChange={handleChange}
                        isValid={touched.titulo && !errors.titulo}
                        isInvalid={!!errors.titulo}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.titulo}</Form.Control.Feedback>
                    </Form.Group>




                    <Form.Group
                      controlId="bullets"
                      className="mb-3"
                    >
                      <Form.Label>Bullets</Form.Label>
                      <Form.Control
                        type="text"
                        name="bullets"
                        as="textarea"
                        rows={5}
                        value={values.bullets}
                        onChange={handleChange}
                        isValid={touched.bullets && !errors.bullets}
                        isInvalid={!!errors.bullets}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.bullets}</Form.Control.Feedback>
                    </Form.Group>



                    <Form.Group
                      controlId="boletin"
                      className="mb-3"
                    >
                      <Form.Label>Boletín</Form.Label>
                      <Form.Control
                        type="text"
                        name="boletin"
                        as="textarea"
                        rows={8}
                        value={values.boletin}
                        onChange={handleChange}
                        isValid={touched.boletin && !errors.boletin}
                        isInvalid={!!errors.boletin}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.boletin}</Form.Control.Feedback>
                    </Form.Group>


                      {
                        // JSON.stringify(tipo_boletin)
                      }




                    <Form.Group
                      controlId="idtipo_boletin"
                      className="mb-3"
                    >

                      <Form.Label>Tipo de Producto</Form.Label>
                      <Form.Control
                        type="hidden"
                        name="idtipo_boletin"
                        value={values.idtipo_boletin}
                        onChange={handleChange}
                        isValid={touched.idtipo_boletin && !errors.idtipo_boletin}
                        isInvalid={!!errors.idtipo_boletin}
                      />
                      <Select options={tipo_boletin} name="idtipo_boletin" className={`  ${ !!errors.idtipo_boletin ? 'is-invalid reactselect-invalid' : ' ' }`} onChange={(opt:any,e:any)=>{ setFieldValue('idtipo_boletin', opt.value ) }} />
                      <Form.Control.Feedback type="invalid">{errors.idtipo_boletin}</Form.Control.Feedback>
                    </Form.Group>




                    <div className="row">
                      <div className="col-6">



                    <Form.Group className="mb-3" >

                      <Form.Label>Fecha</Form.Label>
                      <Form.Control
                        type="hidden"
                        name="fecha"
                        onChange={handleChange}
                        isValid={touched.fecha && !errors.fecha}
                        isInvalid={!!errors.fecha}
                      />
                      <DatePicker inline className={`form-control ${ !!errors.fecha ? 'is-invalid' : '' } ${ !errors.fecha && values.fecha ? 'is-valid' : '' }`} selected={values.fecha} onChange={e => setFieldValue('fecha', e)} dateFormat="yyyy-MM-dd"  />
                      <Form.Control.Feedback type="invalid">{errors.fecha}</Form.Control.Feedback>
                    </Form.Group>



                      </div>
                    </div>


              

                    <Form.Group
                      controlId="url_imagenes"
                      className="mb-3"
                    >
                      <Form.Label>Link de Imágenes</Form.Label>
                      <Form.Control
                        type="text"
                        name="url_imagenes"
                        as="textarea"
                        rows={3}
                        value={values.url_imagenes}
                        onChange={handleChange}
                        isValid={touched.url_imagenes && !errors.url_imagenes}
                        isInvalid={!!errors.url_imagenes}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.url_imagenes}</Form.Control.Feedback>
                    </Form.Group>


                

                    <Form.Group
                      controlId="url_audio"
                      className="mb-3"
                    >
                      <Form.Label>Link de Audio</Form.Label>
                      <Form.Control
                        type="text"
                        name="url_audio"
                        as="textarea"
                        rows={3}
                        value={values.url_audio}
                        onChange={handleChange}
                        isValid={touched.url_audio && !errors.url_audio}
                        isInvalid={!!errors.url_audio}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.url_audio}</Form.Control.Feedback>
                    </Form.Group>




                    <Form.Group
                      controlId="url_video"
                      className="mb-3"
                    >
                      <Form.Label>Link de Vídeo</Form.Label>
                      <Form.Control
                        type="text"
                        name="url_video"
                        as="textarea"
                        rows={3}
                        value={values.url_video}
                        onChange={handleChange}
                        isValid={touched.url_video && !errors.url_video}
                        isInvalid={!!errors.url_video}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.url_video}</Form.Control.Feedback>
                    </Form.Group>






                </Form>
              )}
            </Formik>






        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Crear</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalNuevoBoletin;