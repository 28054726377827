import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import Dialog from '../../services/Dialogs'

import { toast } from 'react-toastify'

import {Button, Modal } from 'react-bootstrap';


const ModalEnviarRectificarBoletin = ( params : any ) => {


  const dialog = new Dialog();

  const { show, handleClose, boletin } = params



  const [ tipoA, setTipoA ] = useState(false)
  const [ tipoB, setTipoB ] = useState(false)
  const [ tipoC, setTipoC ] = useState(false)
  const [ tipoD, setTipoD ] = useState(false)


  const [ descripcionA, setDescripcionA ] = useState("")
  const [ descripcionB, setDescripcionB ] = useState("")
  const [ descripcionC, setDescripcionC ] = useState("")
  const [ descripcionD, setDescripcionD ] = useState("")


  const enviarRectificacion = async () => {
    try{
      if(await dialog.confirm('¿Estás seguro?',{})){
        var COMMENTS : any = []
          if(tipoA) COMMENTS.push({ descripcion : descripcionA, tipo_comentario : 1 })
          if(tipoB) COMMENTS.push({ descripcion : descripcionB, tipo_comentario : 2 })
          if(tipoC) COMMENTS.push({ descripcion : descripcionC, tipo_comentario : 3 })
          if(tipoD) COMMENTS.push({ descripcion : descripcionD, tipo_comentario : 4 })
          // console.log(COMMENTS)
        await RequestService.update('boletin/enviar_rectificacion/'+boletin.uuid,{ comments : COMMENTS })
        toast.warning('El Tramite fue enviado al vocero para su rectificación de información')
        handleClose()
      }
    }
    catch(e){
      toast.error("Error al enviar a rectificación")
    }

  }


  const [ comments, setComments ] = useState({
    resuelto_todo : false,
    comments : []
  })



  const cargarComentarios = async () =>{
      try{
        let response = await RequestService.getEndPoint('boletin/comentarios_atencion/'+boletin.uuid)
        setComments(response.data)
      }
      catch(e){
        toast.error('Error al cargar los comentarios')
      }
  }


  React.useEffect(() => {
    cargarComentarios()
  }, []);



  return (
    <>


      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="nm">ENVIAR PRODUCTO A RECTIFICACIÓN</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <p>Para ayudar al vocero a rectificar la información de la producto indica que tipo de corrección debe de hacer:</p>

      <div className="form-check">
        <input className="form-check-input" type="checkbox" id="flexCheckCheckedA" onClick={(e)=>{setTipoA(!tipoA)}} />
        <label className="form-check-label" htmlFor="flexCheckCheckedA">
          Información en los detalles de la Publicación
        </label>
      </div>
      {
        tipoA && (
            <div className="form-group">
              <label htmlFor="exampleTextareaA" className="form-label mt-2">Detalles del comentario</label>
              <textarea className="form-control" id="exampleTextareaA" value={descripcionA} onChange={(event)=>{setDescripcionA(event.target.value.toUpperCase())}} rows={3}></textarea>
            </div>
          )
      }


      <div className="form-check mt-2">
        <input className="form-check-input" type="checkbox" id="flexCheckCheckedB" onClick={(e)=>{setTipoB(!tipoB)}} />
        <label className="form-check-label" htmlFor="flexCheckCheckedB">
          Información con los bullets
        </label>
      </div>
      {
        tipoB && (
            <div className="form-group">
              <label htmlFor="exampleTextareaB" className="form-label mt-2">Detalles del comentario</label>
              <textarea className="form-control" id="exampleTextareaB" value={descripcionB} onChange={(event)=>{setDescripcionB(event.target.value.toUpperCase())}} rows={3}></textarea>
            </div>
          )
      }


      <div className="form-check mt-2">
        <input className="form-check-input" type="checkbox" id="flexCheckCheckedC" onClick={(e)=>{setTipoC(!tipoC)}} />
        <label className="form-check-label" htmlFor="flexCheckCheckedC">
          Información en los anexos
        </label>
      </div>
      {
        tipoC && (
            <div className="form-group">
              <label htmlFor="exampleTextareaC" className="form-label mt-2">Detalles del comentario</label>
              <textarea className="form-control" value={descripcionC} onChange={(event)=>{setDescripcionC(event.target.value.toUpperCase())}} id="exampleTextareaC" rows={3}></textarea>
            </div>
          )
      }


      <div className="form-check mt-2">
        <input className="form-check-input" type="checkbox" id="flexCheckCheckedD" onClick={(e)=>{setTipoD(!tipoD)}} />
        <label className="form-check-label" htmlFor="flexCheckCheckedD">
          Otros
        </label>
      </div>
      {
        tipoD && (
            <div className="form-group">
              <label htmlFor="exampleTextareaD" className="form-label mt-2">Detalles del comentario</label>
              <textarea className="form-control" value={descripcionD} onChange={(event)=>{setDescripcionD(event.target.value.toUpperCase())}} id="exampleTextareaD" rows={3}></textarea>
            </div>
          )
      }



            {
              comments.comments.length > 0 && (
                  <p className="mt-4">Comentarios anteriores: </p>
              )
            }

            


            {
              comments.comments.map( (comentario:any,i:number) =>

                  <React.Fragment key={comentario.uuid}>

                      <div  className="card" style={{ margin: '5px 0'}}>
                        <div className="card-body">
                          <h6 className="card-subtitle text-muted"><b>{(i+1)}.-</b> {comentario.tipo_comentario} <i style={{color:"green"}} className="fa fa-check-circle"/></h6>
                          <p style={{marginBottom:0}} className="card-text">{comentario.descripcion}</p>
                        </div>
                      </div>

                    {
                      // JSON.stringify(comentario)
                    }
                  </React.Fragment>

                )
            }




        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>{enviarRectificacion()}} variant="primary">Enviar a Rectificación</Button>
        </Modal.Footer>
      </Modal>



    </>
  );
}


export default ModalEnviarRectificarBoletin