import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import { Button, Modal, Form } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { Formik,  } from 'formik';
import * as yup from 'yup';


// import DatePicker, { registerLocale } from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import es from 'date-fns/locale/es';

import Select from 'react-select'




import Dialog from '../../services/Dialogs'



const ModalAgregarDependencia = ( params : any ) => {

  const { show, handleClose } = params

  const dialog = new Dialog();


  const schema = yup.object().shape({
    company_name : yup.string().required('Este campo es obligatorio'),
    address : yup.string().required('Este campo es obligatorio'),
    phone : yup.string().required('Este campo es obligatorio'),
    city : yup.string().required('Este campo es obligatorio'),
    country : yup.string().required('Este campo es obligatorio'),
    state : yup.string().required('Este campo es obligatorio'),
    created_by : yup.string().required('Este campo es obligatorio'),
  });


  const [ enlaces, setEnlaces ] = useState<any>([])
  const cargar = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('enlaces')
      setEnlaces(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }

  React.useEffect(() => {
    cargar()
  }, []);




  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Alta de Dependencia</Modal.Title>
        </Modal.Header>
        <Modal.Body>



            <Formik
              validationSchema={schema}
              onSubmit={async(values:any)=>{
              
                        try{

                              if(await dialog.confirm('¿Estás seguro?',{})){
                                  await RequestService.create('dependencia/alta', values )
                                  toast.success('La Dependencia fue dada de alta')
                                  handleClose()
                              }
              
                        }
                        catch(e){
                          toast.error('Error al dar de alta')
                        }
                        // console.log({ ...values })
                      }}
              initialValues={{

                company_name : "", 
                address : "", 
                phone : "", 
                city : "", 
                country : "", 
                state : "", 
                created_by : "", 


              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form id='my-form' noValidate onSubmit={handleSubmit}>


{
  // JSON.stringify(values)
}


                    <Form.Group
                      controlId="company_name"
                      className="mb-3"
                    >
                      <Form.Label>Dependencia</Form.Label>
                      <Form.Control
                        type="text"
                        name="company_name"
                        value={values.company_name}
                        onChange={handleChange}
                        isValid={touched.company_name && !errors.company_name}
                        isInvalid={!!errors.company_name}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.company_name}</Form.Control.Feedback>
                    </Form.Group>




                    <Form.Group
                      controlId="country"
                      className="mb-3"
                    >
                      <Form.Label>Acrónimo</Form.Label>
                      <Form.Control
                        type="text"
                        name="country"
                        value={values.country}
                        onChange={handleChange}
                        isValid={touched.country && !errors.country}
                        isInvalid={!!errors.country}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.country}</Form.Control.Feedback>
                    </Form.Group>



                    <Form.Group
                      controlId="address"
                      className="mb-3"
                    >
                      <Form.Label>Dirección</Form.Label>
                      <Form.Control
                        type="text"
                        name="address"
                        value={values.address}
                        onChange={handleChange}
                        isValid={touched.address && !errors.address}
                        isInvalid={!!errors.address}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
                    </Form.Group>



                    <Form.Group
                      controlId="city"
                      className="mb-3"
                    >
                      <Form.Label>Ciudad</Form.Label>
                      <Form.Control
                        type="text"
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                        isValid={touched.city && !errors.city}
                        isInvalid={!!errors.city}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                    </Form.Group>




                    <Form.Group
                      controlId="state"
                      className="mb-3"
                    >
                      <Form.Label>Estado</Form.Label>
                      <Form.Control
                        type="text"
                        name="state"
                        value={values.state}
                        onChange={handleChange}
                        isValid={touched.state && !errors.state}
                        isInvalid={!!errors.state}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.state}</Form.Control.Feedback>
                    </Form.Group>




                    <Form.Group
                      controlId="phone"
                      className="mb-3"
                    >
                      <Form.Label>Teléfono</Form.Label>
                      <Form.Control
                        type="text"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        isValid={touched.phone && !errors.phone}
                        isInvalid={!!errors.phone}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                    </Form.Group>



                    <Form.Group
                      controlId="enlace"
                      className="mb-3"
                    >

                      <Form.Label>Enlace</Form.Label>
                      <Form.Control
                        type="hidden"
                        name="created_by"
                        value={values.created_by}
                        onChange={handleChange}
                        isValid={touched.created_by && !errors.created_by}
                        isInvalid={!!errors.created_by}
                      />
                    <Select options={enlaces} name="enlace"  onChange={(opt:any,e:any)=>{ setFieldValue('created_by', opt.value ) }} />
                      <Form.Control.Feedback type="invalid">{errors.created_by}</Form.Control.Feedback>
                    </Form.Group>





                </Form>
              )}
            </Formik>


          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalAgregarDependencia;