import React, { useState } from "react";
import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'


import { Button, Modal, Form  } from 'react-bootstrap';

import Select from 'react-select';

import Pagination from "../../services/Pagination";
import Dialog from '../../services/Dialogs'


import ModalNuevoTicket from './ModalNuevoTicket'
import ModalDetalleTicket from './ModalDetalleTicket'

import ModalEditarTicket from './ModalEditarTicket'

import ModalComentariosTicket from './ModalComentariosTicket'


import Archivos from '../Archivos/Archivos'

import {labelType} from '../Tickets/labelType'


import './tickets.scss'
import '../../table.scss'



const MisTickets = () =>{


  const dialog = new Dialog();

  const user = AuthService.getCurrentUser()


      React.useEffect(() => {
        cargar(0)
        cargarDependencias()
      }, []);



  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( true )

  const [ resultados, setResultados ] = useState<any>([])


  const cargar = async(page:number=1)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*10);
      setCurrentPage(page===0?1:page+1)
      let response : any = await RequestService.getEndPointPost('tickets/'+arreglo_estatus+'/busqueda?where='+text_busqueda+'&skip='+skip, { dependencias : etiquetas_seleccionadas })
      if(response){
        setCounter(response.data.totalCount)
        setResultados(response.data.resultados)
      }
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    setCurrentPage(1)
    cargar(0)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true,true,true,true])


  const todos = () =>{
    setEstatus([true,true,true,true])
  }
  const ninguno = () =>{
    setEstatus([false,false,false,false])
  }



  const [ show_ModalNuevoTicket, setModalNuevoTicket ] = useState<boolean>(false)
  const handleShow_ModalNuevoTicket = () => {
    setModalNuevoTicket(true)
  }
  const handleClose_ModalNuevoTicket = () => {
    cargar(currentPage-1)
    setModalNuevoTicket(false)
  }



  const [ ticket_seleccionado, setTicketSeleccionado ] = useState<any>({})
  const [ show_ModalDetalleTicket, setModalDetalleTicket ] = useState<boolean>(false)
  const handleShow_ModalDetalleTicket = (ticket:any) => {
    setTicketSeleccionado(ticket)
    setModalDetalleTicket(true)
  }
  const handleClose_ModalDetalleTicket = () => {
    setModalDetalleTicket(false)
  }



  const [ show_ModalEditarTicket, setModalEditarTicket ] = useState<boolean>(false)
  const handleShow_ModalEditarTicket = (ticket:any) => {
    setTicketSeleccionado(ticket)
    setModalEditarTicket(true)
  }
  const handleClose_ModalEditarTicket = () => {
    cargar(currentPage-1)
    setModalEditarTicket(false)
  }


  const eliminarTicket =async (idticket:string)=>{
    try{
      if(await dialog.confirm('¿Estás seguro?',{},'No podrás recuperar la información')){
        let res = await RequestService.delete('ticket/'+idticket)
        if(res.data){
          toast.warning('El ticket se elimino correctamente')
          cargar(currentPage-1)
        }
        else{ toast.error('Error al eliminar ticket') }
      }
    }
    catch(e){
      toast('Error al intentar eliminar el ticket')
    }
  }



  const [ show_ModalComentariosTicket, setModalComentariosTicket ] = useState<boolean>(false)
  const handleShow_ModalComentariosTicket = (ticket:any) => {
    setTicketSeleccionado(ticket)
    setModalComentariosTicket(true)
  }
  const handleClose_ModalComentariosTicket = () => {
    setModalComentariosTicket(false)
  }

  const estatusCorrecto = (status:string) =>{
    let s = ''
    if(status==='new') s = 'Nuevo'
    if(status==='closed') s = 'Cerrado'
    if(status==='open') s = 'Abierto'
    if(status==='client_replied') s = 'En Proceso'
    return s
  }



const defaultValue : labelType[] = []
  const [ etiquetas_seleccionadas, setEtiquetasSeleccionada ] = useState<any>([])


  const [ dependencias, setDependencias ] = useState<any>([])
  const cargarDependencias = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('dependencias')
      setDependencias(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar dependencias')
    }
  }





  const [ show_Archivos, setArchivos ] = useState<boolean>(false)
  const handleShow_Archivos = (registro:any) => {
    setTicketSeleccionado(registro)
    setArchivos(true)
  }
  const handleClose_Archivos = () => {
    cargar(currentPage-1)
    setArchivos(false)
  }


  const [ show_ArchivosRespuesta, setArchivosRespuesta ] = useState<boolean>(false)
  const handleShow_ArchivosRespuesta = (registro:any) => {
    setTicketSeleccionado(registro)
    setArchivosRespuesta(true)
  }
  const handleClose_ArchivosRespuesta = () => {
    cargar(currentPage-1)
    setArchivosRespuesta(false)
  }







      return (
          <>
            
            <div className="container-fluid contenedor">
              

              {
                // JSON.stringify(User)
              }

              <h3 className="mt-3 mb-3">Tickets</h3>


        <div className="row">
        
                <div className="">
                  <label className="form-label">Búsqueda</label>
                  <div className="input-group mb-3">
                    <input type="text" className="form-control" onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={(event) =>{ setTextoBusqueda(event.target.value)}} />
                    <button onClick={busqueda} className="btn btn-primary" type="button" ><i className="fa fa-search" /></button>
                  </div>
                </div>


                <div className="row">
                  <div className="col-sm-6">
                    
                            <div className="">
                              <Form.Label>Estatus</Form.Label>

                              <Form.Check
                                inline
                                name="group1"
                                type="checkbox"
                                label="Nuevo"
                                checked={arreglo_estatus[0]}
                                onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}}
                                id={`opciones_1`}
                              />
                              
                              <Form.Check
                                inline
                                name="group1"
                                type="checkbox"
                                label="En Proceso"
                                checked={arreglo_estatus[1]}
                                onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[1] = e.target.checked;  setEstatus(ne);}}
                                id={`opciones_2`}
                              />
                              
                              <Form.Check
                                inline
                                name="group1"
                                type="checkbox"
                                label="Abierto"
                                checked={arreglo_estatus[2]}
                                onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[2] = e.target.checked;  setEstatus(ne);}}
                                id={`opciones_3`}
                              />

                              <Form.Check
                                inline
                                name="group1"
                                type="checkbox"
                                label="Cerrado"
                                checked={arreglo_estatus[3]}
                                onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[3] = e.target.checked;  setEstatus(ne);}}
                                id={`opciones_4`}
                              />
                            
                          </div>

                          <ul className="list-inline">
                            <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>



                              <li className="list-inline-item"><button type="button" onClick={handleShow_ModalNuevoTicket} className="btn btn-link">Nuevo Ticket</button></li>


                    
                            
                          </ul>


                  </div>
                  <div className="col-sm-6">

                        {
                          ['ROLE_ADMIN'].indexOf(user.rol) > -1 && (

                              <Form.Group
                                controlId="ticket_type_id"
                                className="mb-3"  >
                                    <Form.Label>Dependencias</Form.Label>
                                    <Select
                                      isMulti
                                      defaultValue={defaultValue}
                                      onChange={(opt:any,e:any)=>{ setEtiquetasSeleccionada(opt) }}
                                      isClearable={true}
                                      name="colors"
                                      placeholder="Filtro de dependencias"
                                      options={dependencias}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                    />
                            </Form.Group>

                          )
                        }

                    


                  </div>
                </div>








          </div>





              {
                // JSON.stringify(etiquetas_seleccionadas)
              }



                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <table className="display dataTable no-footer mb-3">
                                          <thead>
                                            <tr>
                                              <th scope="col">ID</th>
                                              <th scope="col">Título</th>


{
 ['ROLE_ADMIN','ROLE_DISENO'].indexOf(user.rol) > -1 && (

  <>
                                                    <th scope="col">Dependencia</th>

  </>
  )
}
                                              <th scope="col">Tipo de Ticket</th>

{
 ['ROLE_ADMIN'].indexOf(user.rol) > -1 && (

  <>
                                                    <th scope="col">Asignado A</th>

  </>
  )
}
                                              <th scope="col">Estatus</th>
                                              

                                              <th className="text-center" colSpan={1}></th>
                                              <th className="text-center" colSpan={3}>Opciones</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (cual:any,indice:number) =>
                                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}}>
                                                    <td className="v text-left" style={{"width":"30px"}}>
                                                          <p onClick={()=>{handleShow_ModalDetalleTicket(cual)}} className="nm pointer"><small>#{cual.id}</small></p>
                                                    </td>
                                                    <td className="v text-left"  >
                                                        <p className="nm"><small><b>{cual.title}</b></small></p>
                                                        {
                                                          // JSON.stringify(cual.labels)
                                                        }
                                                        {
                                                          cual.labels.map( (label:any) => <span key={ Math.random().toString(36).substr(2, 9) } style={{marginRight:5, backgroundColor : label.color}} className="badge">{label.title}</span> )
                                                        }
                                                    </td>

                                                        {
                                                          ['ROLE_ADMIN','ROLE_DISENO'].indexOf(user.rol) > -1 && (
                                                              <td className="v text-left" style={{"width":"250px"}}>
                                                                {
                                                                  cual.client_id && (
                                                                    <p className="nm">{cual.client_id.company_name}</p>
                                                                  )
                                                                }
                                                              </td>
                                                          )
                                                        }



                                                    <td className="v text-left" style={{"width":"160px"}}>
                                                      <p className="nm">{cual.tipo_ticket}</p>
                                                    </td>


{
 ['ROLE_ADMIN'].indexOf(user.rol) > -1 && (

  <>
                                                    <td className="v text-left" style={{"width":"160px"}}>
                                                          {
                                                            cual.assigned_to && (
                                                              <p className="nm">{cual.assigned_to.first_name} {cual.assigned_to.last_name}</p>
                                                            )
                                                          }
                                                          {
                                                            !cual.assigned_to && (
                                                              <p className="nm">NO ASIGNADO</p>
                                                            )
                                                          }
                                                    </td>
  </>
  )
}


                                                    <td className="v text-left" style={{"width":"60px"}}>
                                                      <p className="nm">
                                                          <span className={'badge bg-status_'+cual.status}>{estatusCorrecto(cual.status)} { cual.entregado ? <i className="fa fa-check-circle"/> : '' } </span>
                                                      </p>
                                                    </td>

{
                                                    
                                                    <td className="v" style={{width:60}}>


                                                        <button type="button" className="btn btn-sm btn-link position-relative" onClick={(e)=>{handleShow_ModalComentariosTicket(cual)}}>
                                                          <i className="fa fa-comments-o" />
                                                          {
                                                            false && (

                                                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                                            0
                                                            <span className="visually-hidden">unread messages</span>
                                                          </span>

                                                            )
                                                          }
                                                        </button>


                                                    </td>

}





                                                    <td className="np text-center" style={{width:40}}>




{
 ['ROLE_VOCERO'].indexOf(user.rol) > -1 && (

  <>
                                                      {
                                                        ["new"].indexOf(cual.status) > -1 && (
                                                          <button type="button" onClick={(e)=>{handleShow_ModalEditarTicket(cual)}} className="btn btn-sm btn-warning"><i className="fa fa-edit" /></button>
                                                        )
                                                      }
  </>
  )
}




{
 ['ROLE_ADMIN'].indexOf(user.rol) > -1 && (

  <>
                                                      {
                                                        ["new","client_replied","open"].indexOf(cual.status) > -1 && (
                                                          <button type="button" onClick={(e)=>{handleShow_ModalEditarTicket(cual)}} className="btn btn-sm btn-warning"><i className="fa fa-edit" /></button>
                                                        )
                                                      }
  </>
  )
}

                                                    </td>
                                                    <td className="np text-center" style={{width:40}}>


{
 ['ROLE_VOCERO'].indexOf(user.rol) > -1 && (

  <>
                                                      {
                                                        ["new"].indexOf(cual.status) > -1 && (
                                                          <button type="button" onClick={(e)=>{eliminarTicket(cual.id)}} className="btn btn-sm btn-danger"><i className="fa fa-trash" /></button>
                                                        )
                                                      }
  </>
  )
}


                                                      
                                                    </td>
                                                    <td className="np text-center" style={{width:40}}>

{
 ['ROLE_ADMIN','ROLE_DISENO','ROLE_VOCERO'].indexOf(user.rol) > -1 && (

  <>
                                                      {
                                                        ["new","client_replied","open"].indexOf(cual.status) > -1 && (
                                                          <button type="button" onClick={(e)=>{handleShow_Archivos(cual)}} className="btn btn-sm btn-primary"><i className="fa fa-cloud" /></button>
                                                        )
                                                      }
  </>
  )
}


                                                      
                                                    </td>
                                                    <td className="np text-center" style={{width:40}}>

{
 ['ROLE_ADMIN','ROLE_DISENO'].indexOf(user.rol) > -1 && (

  <>
                                                      {
                                                        ["new","client_replied","open"].indexOf(cual.status) > -1 && (
                                                          <button type="button" onClick={(e)=>{handleShow_ArchivosRespuesta(cual)}} className="btn btn-sm btn-success"><i className="fa fa-cloud" /></button>
                                                        )
                                                      }
  </>
  )
}

                                                    
                                                      
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargar}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}




            </div>



  {
    show_ModalNuevoTicket && (
      <ModalNuevoTicket show={show_ModalNuevoTicket} handleClose={handleClose_ModalNuevoTicket}  />
      )

  }


  {
    show_ModalDetalleTicket && (
      <ModalDetalleTicket show={show_ModalDetalleTicket} handleClose={handleClose_ModalDetalleTicket} ticket={ticket_seleccionado}  />
      )

  }




  {
    show_ModalEditarTicket && (
      <ModalEditarTicket show={show_ModalEditarTicket} handleClose={handleClose_ModalEditarTicket} ticket={ticket_seleccionado}  />
      )

  }





  {
    show_ModalComentariosTicket && (
      <ModalComentariosTicket show={show_ModalComentariosTicket} handleClose={handleClose_ModalComentariosTicket} ticket={ticket_seleccionado}  />
      )

  }







  {
    show_Archivos && (
      <Archivos show={show_Archivos} handleClose={handleClose_Archivos} tipo_archivo="tickets" registro={ticket_seleccionado} />
      )

  }






  {
    show_ArchivosRespuesta && (
      <Archivos show={show_ArchivosRespuesta} handleClose={handleClose_ArchivosRespuesta} tipo_archivo="respuesta_tickets" registro={ticket_seleccionado} />
      )

  }








          </>

      );
  

}

export default MisTickets
