import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import { Button, Modal, Form } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { Formik } from 'formik';
import * as yup from 'yup';


import Select from 'react-select';



import Dialog from '../../services/Dialogs'



import {labelType} from './labelType'


const ModalNuevoTicket = ( params : any ) => {

  const { show, handleClose } = params

  const dialog = new Dialog();


const schema = yup.object().shape({
  title : yup.string().required('Este campo es obligatorio'),
  project_id : yup.string(),
  ticket_type_id : yup.string().required('Este campo es obligatorio'),
});




  const [ proyectos, setProyectos ] = useState<any>([])
  const cargarProyectos = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('proyectos')
      setProyectos(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar proyectos')
    }
  }

  // const [ proyectos, setProyectos ] = useState<any>([])
  const cargarEtiquetas = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('todas_las_etiquetas')
      setEtiquetas(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar proyectos')
    }
  }

  React.useEffect(() => {
    cargarProyectos()
    cargarEtiquetas()
  }, []);





const [colourOptions, setEtiquetas ] = useState<labelType[]>([]);


const defaultValue : labelType[] = [
  // { value: 'orange', label: 'Orange', color: '#FF8B00' },
  // { value: 'yellow', label: 'Yellow', color: '#FFC400' },
]

  const [ etiquetas_seleccionadas, setEtiquetasSeleccionada ] = useState<any>([])




  return (
    <>
      <Modal show={show} dialogClassName="modal-lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Añadir un Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>



            <Formik
              validationSchema={schema}
              onSubmit={async(values:any)=>{
              
                        try{

                              if(await dialog.confirm('¿Estás seguro?',{})){
                                  await RequestService.create('ticket/crear', { ...values, labels : etiquetas_seleccionadas.map((label:any)=>label.value).join(',')} )
                                  toast.success('El Ticket se creo correctamente')
                                  handleClose()
                              }
              
                        }
                        catch(e){
                          toast.error('Error al dar de alta')
                        }
                        // console.log({ ...values })
                      }}
              initialValues={{

                title : "", 
                project_id : "",
                ticket_type_id : ""


              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form id='my-form' noValidate onSubmit={handleSubmit}>


{
  // JSON.stringify(values)
}



                    <Form.Group
                      controlId="ticket_type_id"
                      className="mb-3"
                    >

                      <Form.Label>Tipo de Ticket *</Form.Label>
                      <Form.Select aria-label="Default select example" name="ticket_type_id" value={values.ticket_type_id} onChange={handleChange} isValid={touched.ticket_type_id && !errors.ticket_type_id} isInvalid={!!errors.ticket_type_id}>
                        <option value="" disabled={true} >Selecciona una opción</option>
                        <option value="1">Solicitud de imagen</option>
                        <option value="2">Solicitud de Lona</option>
                        <option value="3">Solicitud de Campaña</option>
                        <option value="4">Solicitud de Video</option>
                        <option value="5">Solicitud de Diseño</option>
                        <option value="6">Otro</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">{errors.ticket_type_id}</Form.Control.Feedback>
                    </Form.Group>




                    <Form.Group
                      controlId="title"
                      className="mb-3"
                    >
                      <Form.Label>Detalles de la Solicitud</Form.Label>
                      <Form.Control
                        type="text"
                        name="title"
                        as="textarea"
                        rows={4}
                        value={values.title}
                        onChange={(e:any)=>{setFieldValue('title',e.target.value.toUpperCase())}}
                        isValid={touched.title && !errors.title}
                        isInvalid={!!errors.title}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                    </Form.Group>












   

{
                    // <Form.Group
                    //   controlId="project_id"
                    //   className="mb-3"
                    // >

                    //   <Form.Label>Proyecto</Form.Label>
                    //   <Form.Control
                    //     type="hidden"
                    //     name="project_id"
                    //     value={values.project_id}
                    //     onChange={handleChange}
                    //     isValid={touched.project_id && !errors.project_id}
                    //     isInvalid={!!errors.project_id}
                    //   />
                    // <Select options={proyectos} name="project_id"  onChange={(opt:any,e:any)=>{ setFieldValue('project_id', opt.value ) }} />
                    //   <Form.Control.Feedback type="invalid">{errors.project_id}</Form.Control.Feedback>
                    // </Form.Group>

}

{
  // JSON.stringify(etiquetas_seleccionadas)
}
{
                    <Form.Group
                      controlId="ticket_type_id"
                      className="mb-3"
                    >

                      <Form.Label>Etiquetas</Form.Label>

                            <Select
                              isMulti
                              defaultValue={defaultValue}
                              onChange={(opt:any,e:any)=>{ setEtiquetasSeleccionada(opt) }}
                              isClearable={true}
                              name="colors"
                              options={colourOptions}
                              className="basic-multi-select"
                              classNamePrefix="select"
                            />
                    </Form.Group>

}

                </Form>
              )}
            </Formik>




        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalNuevoTicket;