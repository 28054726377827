import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import Dialog from '../../services/Dialogs'

import { toast } from 'react-toastify'

import {Button, Modal } from 'react-bootstrap';


const ModalRectificarPublicacion = ( params : any ) => {

  const dialog = new Dialog();

  const { show, handleClose, uuid_publicacion } = params



  const [ comments, setComments ] = useState({
    resuelto_todo : false,
    comments : []
  })

  const cargarComentarios = async (uuid_tramite:string) =>{
      try{
        let response = await RequestService.getEndPoint('publicacion/comentarios_atencion/'+uuid_publicacion)
        setComments(response.data)
      }
      catch(e){
        toast.error('Error al cargar los comentarios')
      }
  }


  // const [ uuid_tramite , setEdit_uuid_tramite ] = useState("")
  const handleCloseRectificarModal = async(e:any) =>{ 
    try{
      // setShowRectificarModal(false)
      // await enviarVerificacion(e,uuid_tramite)
      if(await dialog.confirm('¿Estás seguro de enviar a verificación?',{})){
          await RequestService.update('publicacion/enviar_verificacion/'+uuid_publicacion,{})
          toast.success('En espera de verificación ')
          handleClose()
          // cargarMiReporte(fecha)
          // setShowRectificarModal(false)
      }
      
    }
    catch(e){
      toast.error('Error al cambiar de estatus el tramite')
    }
  }


  const comentarioAtendido = async(uuid_comentario:string)=>{
    try{
      if(await dialog.confirm('¿Quieres marcar como atendido?',{})){
        await RequestService.update('publicacion/comentario_atencion/'+uuid_comentario,{})
        await cargarComentarios(uuid_publicacion)
      }
    }
    catch(e){
      toast.error('Error al cambiar el estatus del comentario')
    }
  }




  React.useEffect(() => {
    cargarComentarios(uuid_publicacion)
  }, []);




  return (
    <>



      <Modal show={show} backdrop="static" onHide={handleClose} >
        <Modal.Header closeButton>
          <Modal.Title>Rectificar Datos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          

            <p>Comentarios para la Atención del Trámite</p>


            {
              comments.comments.map( (comentario:any,i:number) =>

                        <div key={ Math.random().toString(36).substr(2, 9) } className="card" style={{ margin: '5px 0'}}>
                          <div className="card-body">
                            <h6 className="card-subtitle text-muted"><b>{(i+1)}.-</b> {comentario.tipo_comentario} {comentario.finalizado&&(<i className="fa fa-check-circle" style={{color:"green"}} />)}</h6>
                            <p style={{marginBottom:0}} className="card-text">{comentario.descripcion}</p>
                            {
                              !comentario.finalizado && (
                                  <button type="button" onClick={()=>{comentarioAtendido(comentario.uuid)}} className="btn btn-link"><small>Marca de atendido</small></button>
                              )
                            }
                            
                          </div>
                        </div>

                )
            }

        </Modal.Body>
        {
          comments.resuelto_todo && (
                  <Modal.Footer>
                    <Button variant="primary" onClick={(event)=>{handleCloseRectificarModal(event)}}>
                      Enviar a Verificación
                    </Button>
                  </Modal.Footer>
            )
        }

      </Modal>





    </>
  );
}


export default ModalRectificarPublicacion