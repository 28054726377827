import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import { Button, Modal, Form  } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import Select from 'react-select';

import { toast } from 'react-toastify'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import {labelType} from '../Tickets/labelType'

import moment from 'moment'

import Dialog from '../../services/Dialogs'

const mensajes_validacion_generico = 'Este campo es obligatorio'

const ModalEditarProyecto = ( params : any ) => {

  const { show, handleClose, proyecto } = params

  const dialog = new Dialog();


  const schema = yup.object().shape({
    title: yup.string().required(mensajes_validacion_generico),
    description: yup.string().required(mensajes_validacion_generico),
    status: yup.string().required(mensajes_validacion_generico),
    // client_id: yup.string().required(),
    price: yup.number().typeError('Debes usar solo números').required(mensajes_validacion_generico),
    start_date: yup.string().nullable(),
    deadline: yup.string().required(mensajes_validacion_generico).nullable(),
    // client_id : yup.string().required('Este campo es obligatorio'),
  });



  const [ dependencias, setDependencias ] = useState<any>([])
  const cargar = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('dependencias')
      setDependencias(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar dependencias')
    }
  }

  const cargarEtiquetas = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('todas_las_etiquetas')
      setEtiquetas(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar proyectos')
    }
  }

  React.useEffect(() => {
    // cargar()
    cargarEtiquetas()
  }, []);



const [colourOptions, setEtiquetas ] = useState<labelType[]>();


// const defaultValue : labelType[] = []
const defaultValue : labelType[] = proyecto.labels.map( (label:any) =>( { value: label.id, label: label.title, color: label.color } ))

  const [ etiquetas_seleccionadas, setEtiquetasSeleccionada ] = useState<any>( proyecto.labels.map( (label:any) =>( { value: label.id, label: label.title, color: label.color } )) )


  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Editar Proyecto</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          {
            // JSON.stringify(proyecto)
          }




            <Formik
              validationSchema={schema}
              onSubmit={async(values:any)=>{
              
                        try{

                              if(await dialog.confirm('¿Estás seguro?',{})){
                                  await RequestService.update('proyecto/actualizar/'+proyecto.id, { ...values, labels : etiquetas_seleccionadas.map((label:any)=>label.value).join(',')} )
                                  toast.warning('Se actualizó la información del Proyecto')
                                  handleClose()
                              }
              
                        }
                        catch(e){
                          toast.error('Error al dar de alta')
                        }
                        // console.log({ ...values })
                      }}
              initialValues={{

                title : proyecto.title,
                description : proyecto.description,
                // client_id : proyecto.client_id,
                status : proyecto.status,
                price : proyecto.price,
                // start_date : new Date(),
                // deadline : new Date(),

                start_date : proyecto.start_date ? new Date(moment(proyecto.start_date).toString()) : null,
                deadline : proyecto.deadline ? new Date(moment(proyecto.deadline).toString()) : null,


              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form id='my-form' noValidate onSubmit={handleSubmit}>


{
  // JSON.stringify(values)
}


                    <Form.Group
                      controlId="title"
                      className="mb-3"
                    >
                      <Form.Label>Nombre del Proyecto</Form.Label>
                      <Form.Control
                        type="text"
                        name="title"
                        value={values.title}
                        onChange={e => setFieldValue('title', e.target.value.toUpperCase())}
                        isValid={!errors.title}
                        isInvalid={!!errors.title}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                    </Form.Group>



                    <Form.Group
                      controlId="description"
                      className="mb-3"
                    >
                      <Form.Label>Detalles del Proyecto</Form.Label>
                      <Form.Control
                        type="text"
                        rows={8}
                        as="textarea"
                        name="description"
                        value={values.description}
                        onChange={e => setFieldValue('description', e.target.value.toUpperCase())}
                        isValid={!errors.description}
                        isInvalid={!!errors.description}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                    </Form.Group>





                    <div className="row">
                      <div className="col-md-6">
                          <div className="mb-2">
                            <label className="form-label">Fecha del Proyecto</label>
                            <DatePicker className="form-control" selected={values.start_date} onChange={e => setFieldValue('start_date', e)} dateFormat="yyyy-MM-dd"  />
                          </div>
                      </div>
                      <div className="col-md-6">
                          <div className="mb-2">

                            <Form.Group
                              controlId="enlace"
                              className="mb-3"
                            >

                              <Form.Label>Fecha Límite</Form.Label>
                              <Form.Control
                                type="hidden"
                                name="deadline"
                                
                                onChange={handleChange}
                                isValid={touched.deadline && !errors.deadline}
                                isInvalid={!!errors.deadline}
                              />
                              <DatePicker className={`form-control ${ !!errors.deadline ? 'is-invalid' : '' } ${ !errors.deadline ? 'is-valid' : '' }`} selected={values.deadline} onChange={e => setFieldValue('deadline', e)} dateFormat="yyyy-MM-dd"  />
                              <Form.Control.Feedback type="invalid">{errors.deadline}</Form.Control.Feedback>
                            </Form.Group>
                          </div>
                      </div>
                    </div>




                    <Form.Group
                      controlId="price"
                      className="mb-3"
                    >
                      <Form.Label>Precio</Form.Label>
                      <Form.Control
                        type="text"
                        name="price"
                        value={values.price}
                        onChange={handleChange}
                        isValid={!errors.price}
                        isInvalid={!!errors.price}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.price}</Form.Control.Feedback>
                    </Form.Group>




                    <div key={`inline-radio`} className="mb-3">
                    
                        <Form.Group
                          controlId="price"
                          className="mb-3"
                        >
                          <Form.Label>Estatus</Form.Label>

                            <Form.Check
                              inline
                              label="Abierto"
                              value="open"
                              checked={values.status=='open'}
                              name="status"
                            onChange={handleChange}
                              type={'radio'}
                              id={`open`}
                            isValid={touched.status && !errors.status}
                            isInvalid={!!errors.status}
                            />
                            <Form.Check
                              inline
                              label="Completado"
                              value="completed"
                              checked={values.status=='completed'}
                              name="status"
                            onChange={handleChange}
                              type={'radio'}
                              id={`completed`}
                            isValid={touched.status && !errors.status}
                            isInvalid={!!errors.status}
                            />
                            <Form.Check
                              inline
                              label="En Espera"
                              value="hold"
                              checked={values.status=='hold'}
                              name="status"
                            onChange={handleChange}
                              type={'radio'}
                              id={`hold`}
                            isValid={touched.status && !errors.status}
                            isInvalid={!!errors.status}
                            />
                            <Form.Check
                              inline
                              label="Cancelado"
                              value="canceled"
                              checked={values.status=='canceled'}
                              name="status"
                            onChange={handleChange}
                              type={'radio'}
                              id={`canceled`}
                            isValid={touched.status && !errors.status}
                            isInvalid={!!errors.status}
                            />
                          <Form.Control.Feedback type="invalid">{errors.status}</Form.Control.Feedback>
                        </Form.Group>

                    </div>




                    <Form.Group
                      controlId="ticket_type_id"
                      className="mb-3"
                    >

                      <Form.Label>Etiquetas</Form.Label>

                            <Select
                              isMulti
                              defaultValue={defaultValue}
                              onChange={(opt:any,e:any)=>{ setEtiquetasSeleccionada(opt) }}
                              isClearable={true}
                              name="colors"
                              options={colourOptions}
                              className="basic-multi-select"
                              classNamePrefix="select"
                            />
                    </Form.Group>



                </Form>
              )}
            </Formik>




        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalEditarProyecto;