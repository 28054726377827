import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import moment from 'moment'


import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';


import { obtenerDiaDeLaSemana } from '../../services/funcionesPublicas'


import Dialog from '../../services/Dialogs'

import { SERVER_ } from  '../../config';


const ModalEvidenciasCargar = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, tarea } = params


const dialog = new Dialog();


  const [arreglo_fotos, setArregloFotos] = useState<any>([]);
  const [enviando, setEnviando] = useState(false);


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const [ reporte_actividades, setReporte ] = useState<any>([])
  const cargarReporte_evidencia_visual = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`reporte_evidencias_visuales/${tarea.uuid}`)
      setReporte(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }


      React.useEffect(() => {
        cargarReporte_evidencia_visual()
      }, []);



  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
          comentarios : yup.string().required(mensajes_validacion_generico),
          archivo : yup.string().required(mensajes_validacion_generico),
  });



  const enviarFormulario = async (registro:any,event:any,resetForm:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.create( `tareas/agrega_evidancia/${tarea.uuid}` , { ...registro  })

          if(res_){
            toast.success('La evidencia de la tarea fue agregada. Puedes agregar otra')
            setTimeout(()=>{
        cargarReporte_evidencia_visual()
              resetForm()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }



const eliminarTarea = async (event:any,uuid_tarea:string) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: `<p>La tarea será eliminada permanentemente</p>`
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.delete( `mi_tarea/eliminar/${uuid_tarea}`)

        if(res_){
          toast.warning('La tarea fue eliminada')
          setTimeout(()=>{
            cargarReporte_evidencia_visual()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){

          if (e.response?.status) {
            switch (e.response.status) {
              default:
                toast.error(e.response?.data)
            }
          }
          else{
            toast.error("Problemas al tratar de eliminar")
          }
          setSeEnvio(false)

        }
        
      }
     
}





  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Cargar Evidencia</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(tarea)
          }

          <small>Actividad para el día </small>
          <h4 className=" ">
            {
              moment(tarea.fecha_limite).format('YYYY-MM-DD')
            }
          </h4>
          <p className=" ">
            {
              obtenerDiaDeLaSemana(moment(tarea.fecha_limite).format('YYYY-MMM-DD'))
            }
          </p>



          <small>Tarea o Actividad</small>
          <h6>{tarea.detalle_tarea}</h6>
 
          {
            // JSON.stringify(reporte_actividades)
          }

          {
            reporte_actividades.length > 0 && (
                <React.Fragment>
                  <small>Lista de Evidencia de Actividad o Tarea Realizada</small>
                  <ol className="nm">
                    {
                      reporte_actividades.map((reporte:any,index_reporte:number)=><li key={index_reporte}><p className="nm"><small>{reporte.comentarios}</small> <i onClick={(e:any)=>{eliminarTarea(e,reporte.uuid)}} className="fa fa-trash"/></p></li>)
                    }
                  </ol>
                </React.Fragment>
              )
          }





          <div className="card" style={{marginTop:20}}>
            <div className="card-body">


                  <Formik
                    validationSchema={schema}
                    onSubmit={(values, { resetForm })=>{enviarFormulario(values, {}, resetForm)}}
                    initialValues={{
                          comentarios : "",
                          archivo : "",
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      setFieldValue,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                      resetForm
                    }) => (
                      <Form id='my-form' noValidate onSubmit={handleSubmit}>

                        <h4>Agregar Evidencia de Tarea Realizada</h4>


                          <Form.Group className="mb-3" controlId="comentarios">
                            <Form.Label>Comentarios de la tarea</Form.Label>
                            <Form.Control
                              as="textarea"
                              required
                              name="comentarios"
                              value={values.comentarios}
                              onChange={(e)=>{ setFieldValue('comentarios', (e.target.value || "").toUpperCase() ) }}
                              isInvalid={!!errors.comentarios}
                            />
                            <Form.Control.Feedback type="invalid"> {<>{errors.comentarios}</>} </Form.Control.Feedback>
                          </Form.Group>


                          <Form.Group className="mb-3" controlId="archivo">
                            <Form.Label>Link de la evidencia</Form.Label>
                            <Form.Control 
                              required
                              name="archivo"
                              value={values.archivo}
                              onChange={(e)=>{ setFieldValue('archivo', e.target.value ) }}
                              isInvalid={!!errors.archivo}
                            />
                            <Form.Control.Feedback type="invalid"> {<>{errors.archivo}</>} </Form.Control.Feedback>
                          </Form.Group>


                          <div className="col-12">
                            <button type="submit" className="btn btn-success">Agregar Tarea</button>
                            {' '}
                            <button type="button" className="btn btn-danger" onClick={() => resetForm()}>
                              <i className="fa fa-trash"/>
                            </button>
                          </div>


                      </Form>
                    )}
                  </Formik>


              </div>
            </div>




        </Modal.Body> 
      </Modal>
    </>
  );
}


export default ModalEvidenciasCargar;