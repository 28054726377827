import React from 'react';

import RequestService from "../../services/request.service";


import {Button, Modal, Form, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import { toast } from 'react-toastify'


const ModalArchivarPublicacion = ( params : any ) => {


  const { show, handleClose, uuid_publicacion } = params

  const schema = yup.object().shape({
    url_publicacion: yup.string().required()
  });


  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Nueva Publicación</Modal.Title>
        </Modal.Header>
          {
            // JSON.stringify(estatus)
          }
          {
            // JSON.stringify(redes_sociales)
          }

    <Formik
      validationSchema={schema}
      onSubmit={async(data)=>{
        // console.log('?')
        // console.log(data)

          let res = await RequestService.update('publicacion/archivar/'+uuid_publicacion, { ...data })
          if(!res.data) toast.error('error al archivar')
          if(res.data){
            toast.success('bien por archivar')
            handleClose()
          }

      }}
      initialValues={{
        url_publicacion: '',
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        setFieldValue,
        touched,
        isValid,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>


        <Modal.Body>

        {
          // JSON.stringify(user)
        }




            <Form.Group as={Col} className="mt-2" controlId="url_publicacion">
              <Form.Label>URL de la Publicación</Form.Label>
              <Form.Control as="textarea" name="url_publicacion" value={values.url_publicacion} rows={6} onChange={e =>{ setFieldValue('url_publicacion', e.target.value.toUpperCase() );}} isInvalid={!!errors.url_publicacion} isValid={touched.url_publicacion && !errors.url_publicacion} />
              <Form.Control.Feedback type="valid">
                :)
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Debes la URL de la publicación
              </Form.Control.Feedback>
            </Form.Group>








        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" disabled={Object.keys(errors).length > 0} type="submit">Archivar</Button>
        </Modal.Footer>


        </Form>
      )}
    </Formik>




      </Modal>
    </>
  );
}


export default ModalArchivarPublicacion