import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'



import Pagination from "../../services/Pagination";
import { Table, Form, Button } from 'react-bootstrap'




import moment from 'moment'
import 'moment/locale/es'


import ModalResetPasswordUsuario from './ModalResetPasswordUsuario'
import ModalEditarUsuario from './ModalEditarUsuario'
import ModalNuevoUsuario from './ModalNuevoUsuario'


import './usuarios.scss'


const Usuarios = () =>{


  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})


  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*10);
      let response : any = await RequestService.getEndPoint('lista_usuarios/'+arreglo_estatus+'/busqueda?where='+text_busqueda+'&skip='+skip)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargarMiReporte(0)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true,false,true,true,false])


  const todos = () =>{
    setEstatus([true,true,true,true,true])
  }
  const ninguno = () =>{
    setEstatus([false,false,false,false,false])
  }





  const [ asesor_editar, setAsesorEditar ] = useState<any>({})
  const [ show_ModalResetPasswordUsuario, setModalResetPasswordUsuario ] = useState<boolean>(false)
  const handleShow_ModalResetPasswordUsuario = (usuario:any) => {
     setAsesorEditar(usuario)
    setModalResetPasswordUsuario(true)
  }
  const handleClose_ModalResetPasswordUsuario = () => {
    setModalResetPasswordUsuario(false)
  }




  const [ show_ModalEditarUsuario, setModalEditarUsuario ] = useState<boolean>(false)
  const handleShow_ModalEditarUsuario = (usuario:any) => {
     setAsesorEditar(usuario)
    setModalEditarUsuario(true)
  }
  const handleClose_ModalEditarUsuario = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalEditarUsuario(false)
  }


  const [ show_ModalNuevoUsuario, setModalNuevoUsuario ] = useState<boolean>(false)
  const handleShow_ModalNuevoUsuario = () => {
    setModalNuevoUsuario(true)
  }
  const handleClose_ModalNuevoUsuario = () => {
    setModalNuevoUsuario(false)
  }


      return (
          <>



            <div className="container-fluid contenedor">
                  


                  <h3 className="mt-3 mb-3">Usuarios</h3>

                  <hr/>




        <div className="row">
        
            <div className="">
              <label className="form-label">Búsqueda</label>
              <div className="input-group mb-3">
                <input type="text" className="form-control" onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                <button onClick={busqueda} className="btn btn-default" type="button" ><i className="fa fa-search" /></button>
              </div>
            </div>

            <div>

              <Form.Check inline name="group1" type="checkbox" label="ACTIVOS" checked={arreglo_estatus[0]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}} id={`opciones_1`} />
              <Form.Check inline name="group1" type="checkbox" label="INACTIVOS" checked={arreglo_estatus[1]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[1] = e.target.checked;  setEstatus(ne);}} id={`opciones_2`} />


              <Form.Check inline name="group1" type="checkbox" label="ADMINISTRACIÓN" checked={arreglo_estatus[2]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[2] = e.target.checked;  setEstatus(ne);}} id={`opciones_3`} />
              <Form.Check inline name="group1" type="checkbox" label="VOCEROS" checked={arreglo_estatus[3]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[3] = e.target.checked;  setEstatus(ne);}} id={`opciones_4`} />



              <Form.Check inline name="group1" type="checkbox" label="NOTIFICADOS" checked={arreglo_estatus[4]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[4] = e.target.checked;  setEstatus(ne);}} id={`opciones_5`} />




            </div>
            

          <ul className="list-inline">
            <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
            <li className="list-inline-item"><small className="pointer" onClick={()=>{handleShow_ModalNuevoUsuario()}}><i className="fa fa-user" /> Nuevo Usuario</small></li>
          </ul>

        </div>



                  <div className="row">
                    <div className="col-12">




                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col">Acceso</th>
                                              <th scope="col">Usuario</th>
                                              <th scope="col">Admin</th>
                                              <th scope="col">Enlace</th>
                                              <th scope="col">Dependencia</th>
                                              <th scope="col"> </th>
                                              <th scope="col">Celular</th>
                                              <th scope="col">Username</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (cual:any,indice:number) =>
                                                  <tr className={`${cual.status?'':'table-danger'}`} key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}}>
                                                    <td className="v text-center" style={{"width":"60px"}}>
                                                            
                                                      { (cual.status) && ( <span> SI</span> ) }
                                                      { (!cual.status) && ( <span style={{color:'black'}}> NO</span> ) }

                                                    </td>
                                                    <td className="v text-left"  >
                                                        <p className="nm"><small><b>{cual.first_name}</b></small></p>
                                                        <small>{cual.last_name}</small>
                                                    </td>
                                                    <td className="v text-center" style={{"width":"50px"}}>
                                                        <small>{cual.is_admin?<i className="fa fa-check-circle"/>:''}</small>
                                                    </td>
                                                    <td className="v text-center" style={{"width":"50px"}}>
                                                        <small>{cual.is_primary_contact?<i className="fa fa-link"/>:''}</small>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"350px"}}>
                                                        <p className="nm"><small><b>{cual.country}</b></small></p>
                                                        <small>{cual.company_name}</small>
                                                    </td>
                                                    <td className={`v text-left ${cual.enable_web_notification?'wasap':''}`} style={{"width":"20px"}}>
                                                        {
                                                          cual.enable_web_notification && ( <i className="fa fa-whatsapp" /> )
                                                        }
                                                    </td>
                                                    <td className="v text-left" style={{"width":"200px"}}>
                                                        <small>{cual.phone}</small>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"200px"}}>
                                                        <small>{cual.email}</small>
                                                    </td>
                                                      <td className="v text-right" style={{"width":"40px"}}>
                                                            <Button  variant="warning" onClick={()=>{handleShow_ModalResetPasswordUsuario(cual)}} size="sm"><i className="fa fa-key"/></Button>
                                                      </td>
                                                      <td className="v text-right" style={{"width":"40px"}}>
                                                            <Button  variant="warning" onClick={()=>{handleShow_ModalEditarUsuario(cual)}} size="sm"><i className="fa fa-edit"/></Button>
                                                      </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}




                    </div>
                  </div>



            </div>



  
  {
    show_ModalResetPasswordUsuario && (
      <ModalResetPasswordUsuario show={show_ModalResetPasswordUsuario} handleClose={handleClose_ModalResetPasswordUsuario} usuario={asesor_editar} />
      )

  }            


  {
    show_ModalEditarUsuario && (
      <ModalEditarUsuario show={show_ModalEditarUsuario} handleClose={handleClose_ModalEditarUsuario}  usuario={asesor_editar} />
      )

  }



  {
    show_ModalNuevoUsuario && (
      <ModalNuevoUsuario show={show_ModalNuevoUsuario} handleClose={handleClose_ModalNuevoUsuario}  />
      )

  }



          </>

      );
  

}

export default Usuarios
