// import React, { useState } from 'react';

// import RequestService from "../../services/request.service";

// import { Button, Modal, Form, Tab, Tabs  } from 'react-bootstrap';
// import { Formik } from 'formik';
// import * as yup from 'yup';

// import Select from 'react-select';

// import { toast } from 'react-toastify'

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";


// import {labelType} from '../Tickets/labelType'


// import Dialog from '../../services/Dialogs'

import React, { useRef, useEffect, useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal, Tabs, Tab } from 'react-bootstrap';

import { toast } from 'react-toastify'



import Dialog from '../../services/Dialogs'




const Archivos = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, tipo_archivo, registro } = params

  const dialog = new Dialog();


  const fileInputRef = useRef<any>(null);


  const getBase64 = (file : any) => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL : any = "";
      // Make new FileReader
      let reader = new FileReader();
      // Convert the file to base64 text
      reader.readAsDataURL(file);
      // on reader load somthing...
      reader.onload = () => resolve(reader.result)
      // console.log(fileInfo);
    });
  };

  const [ archivos, setArchivos ] = useState<any>([])

  const handleFileInputChange = async (e : any) => {
    // setFieldValue('archivo', e)
    // console.log(e)
    let archivos_ = []
    let files = e.target.files;
    console.log(files)
    for(let file of files){
      let base = await getBase64(file)
      archivos_.push({base64:base, name : file.name, type : file.type, descripcion : "", subiendo : false, subido : true })
    }
    console.log(archivos_)
    setArchivos(archivos_)
    // getBase64(file)
    //   .then((result:any) => {
    //     file["base64"] = result;
    //     // console.log("File Is", file);
    //     setbase64URL(result)
    //     setFieldValue('archivo',result)
    //     console.log(result)
    //     console.log('ah=')
    //   })
    //   .catch(err => {
    //     console.log(err);
    //     toast.warning('No pudimos codificar tu archivo')
    //   });
  };


  const [ cargando_, setCargando ] = useState<boolean>(false)

  const subirArchivos = async()=>{
    let archivos_ = [...archivos]
    // for(let archivo of archivos_) archivo.subiendo = true;
    setCargando(true)

    for(let archivo of archivos_){
      let registro_ = {
        archivo : archivo.base64,
        descripcion : archivo.descripcion
      }
      // setArchivos([])
        await RequestService.create(`archivos/subir/${registro.id}/${tipo_archivo}`, registro_  )
        toast.success('El archivo se subio correctamente')
       // archivo.subiendo = false
       // setArchivos(archivos_)
    }

    setCargando(false)
    setArchivos([])
        cargar()

  }


const [key, setKey] = useState<any>('archivos');


  const [ archivos_cargados, setArchivosCargados ] = useState<any>([])

  const [ enviando_archivo, setEnviando ] = useState<boolean>(false)
  const cargar = async()=>{
    try{
      let response : any = await RequestService.getEndPoint(`archivos/lista/${registro.id}/${tipo_archivo}`)
      setArchivosCargados(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar los archivos')
    }
  }



  const eliminarArchivo =async (uuid_archivo:string)=>{
    try{
      if(await dialog.confirm('¿Estás seguro?',{},'Este cambio será definitivo')){
        let res = await RequestService.delete('archivo/'+uuid_archivo)
        if(res.data){
          toast.warning('El archivo se elimino correctamente')
          cargar()
        }
        else{ toast.error('Error al eliminar archivo') }
      }
    }
    catch(e){
      toast('Error al intentar eliminar el archivo')
    }
  }


      React.useEffect(() => {
        cargar()
      }, []);


const copiarAlPortapapeles = (texto:string)=> {
  // Crea un elemento de textarea temporal
  var elementoTemporal = document.createElement("textarea");
  elementoTemporal.value = texto;

  // Agrega el elemento al DOM
  document.body.appendChild(elementoTemporal);

  // Selecciona el contenido del textarea
  elementoTemporal.select();
  elementoTemporal.setSelectionRange(0, 99999); // Para dispositivos móviles

  // Copia el texto al portapapeles
  document.execCommand("copy");

  // Elimina el elemento temporal del DOM
  document.body.removeChild(elementoTemporal);
  toast.warning('El URL del archivo se copio al porta papeles')
}



  return (
    <>
      <Modal show={show} size={'lg'} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Agregar Archivos</Modal.Title>
        </Modal.Header>
        <Modal.Body>



    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k:any) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="home" title="Subir Archivos">



              {
                archivos.length === 0 && (

                    <div className="mb-3">
                      <label htmlFor="formFile" className="form-label">Selecciona los archivos</label>
                      <input className="form-control" type="file" id="formFile" multiple
                      accept="image/jpeg,image/png,image/gif,video/x-msvideo,video/mp4,video/mpeg,video/webm,application/zip,application/vnd.rar,application/x-tar,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation"
                      onChange={(e)=>{handleFileInputChange(e)}} />
                    </div>

                  )
              }

                          {
                            cargando_ && (
                                <>
                                  <p>Subiendo archivos, espere <i className="fa fa-spinner fa-spin"/></p>
                                </>
                              )
                          }

                          {
                            !cargando_ && (


                        <React.Fragment >


                  {
                    archivos.map((archivo:any,index:number) =>

                        <React.Fragment key={ index }>





                                    <div className="row" >
                                      <div className="col-3">
                                          <p className="text-center">
                                      {
                                        // JSON.stringify(archivo.type)
                                      }
                                          {
                                            ["image/jpeg","image/png","image/gif"].indexOf(archivo.type) > -1 && (

                                                <>
                                                  <img src={archivo.base64} width="80%" style={{paddingTop:20}} />
                                                </>

                                              )
                                          }

                                          {
                                            ["video/x-msvideo","video/mp4","video/mpeg","video/webm"].indexOf(archivo.type) > -1 && (

                                                <>
                                                  <img src={`images/video.png`} width="20%" style={{paddingTop:50}} />
                                                </>

                                              )
                                          }

                                          {
                                            ["application/zip","application/vnd.rar","application/x-tar"].indexOf(archivo.type) > -1 && (

                                                <>
                                                  <img src={`images/zip.png`} width="20%" style={{paddingTop:50}} />
                                                </>

                                              )
                                          }

                                          {
                                            ["application/vnd.ms-excel","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"].indexOf(archivo.type) > -1 && (

                                                <>
                                                  <img src={`images/excel.png`} width="20%" style={{paddingTop:50}} />
                                                </>

                                              )
                                          }
                                          {
                                            ["application/msword","application/vnd.openxmlformats-officedocument.wordprocessingml.document"].indexOf(archivo.type) > -1 && (

                                                <>
                                                  <img src={`images/word.png`} width="20%" style={{paddingTop:50}} />
                                                </>

                                              )
                                          }

                                          {
                                            ["application/vnd.ms-powerpoint","application/vnd.openxmlformats-officedocument.presentationml.presentation"].indexOf(archivo.type) > -1 && (

                                                <>
                                                  <img src={`images/ppt.png`} width="20%" style={{paddingTop:50}} />
                                                </>

                                              )
                                          }

                                          
                                          {
                                            ["application/pdf"].indexOf(archivo.type) > -1 && (

                                                <>
                                                  <img src={`images/pdf.png`} width="20%" style={{paddingTop:50}} />
                                                </>

                                              )
                                          }

                                          {
                                            [
                                              "image/jpeg","image/png","image/gif",
                                              "video/x-msvideo","video/mp4","video/mpeg","video/webm",
                                              "application/zip","application/vnd.rar","application/x-tar",
                                              "application/msword","application/vnd.openxmlformats-officedocument.wordprocessingml.document","application/pdf","application/vnd.ms-excel","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                              "application/vnd.ms-powerpoint","application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                            ].indexOf(archivo.type) === -1 && (

                                                <>
                                                  <img src={`images/file.png`} width="20%" style={{paddingTop:50}} />
                                                </>

                                              )
                                          }

                                          </p>

                                      </div>
                                      <div className="col">

                                            <div className="mb-3-">
                                              <label className="form-label"><small>Detalles del Archivo</small></label>
                                              <textarea className="form-control" rows={3} value={archivo.descripcion} onChange={(e:any)=>{ let a = [...archivos]; a[index].descripcion = e.target.value; setArchivos(a) }} ></textarea>
                                              <p className="nm"><small>{archivo.name}</small></p>
                                            </div>

                                      </div>
                                    </div>




                          <hr/>



                        </React.Fragment>

                      )
                  }      



              {
                archivos.length > 0 && (

                    <div className="mb-3">
                      <button type="button" onClick={(e:any)=>{setArchivos([])}} className="btn btn-primary btn-lg"><i className="fa fa-trash"/></button> {' '}
                      <button type="button" onClick={subirArchivos} className="btn btn-secondary btn-lg">Subir Archivos</button>
                    </div>

                  )
              }

                        </React.Fragment>


                              )
                          }




          {
            // archivos.length
          }


      </Tab>
      <Tab eventKey="archivos" title="Archivos">
        

                <h6>Lista de Archivos</h6>

                {
                  archivos_cargados.length === 0 && (
                      <React.Fragment>
                          <div className="alert alert-primary" role="alert">
                            Este registro no tiene archivos
                          </div>
                      </React.Fragment>
                    )
                }




                {
                  archivos_cargados.length > 0 && (
                      <React.Fragment>



                              <table className="display dataTable no-footer mb-3">
                                <thead>
                                  <tr>
                                    <th scope="col">Archivo</th>
                                    <th scope="col">Descripción</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    archivos_cargados.map( (archivo:any) =>

                                        <tr key={ Math.random().toString(36).substr(2, 9) }>
                                          <td className="text-center v" style={{width:160}}>

                                          {
                                            // <img src={`https://docs.google.com/uc?id=${archivo.gdrive_id}&export=download`} style={{width:100}} />
                                          }
                                           { ['image/jpeg','image/jpg','image/png','image/png'].indexOf(archivo.file_type) > -1 && (<a target="_blank" href={`https://docs.google.com/uc?id=${archivo.gdrive_id}&export=download`} > <i className="fa fa-file" /> </a>) }
                                           { ['image/jpeg','image/jpg','image/png','image/png'].indexOf(archivo.file_type) === -1 && (<a target="_blank" href={`https://docs.google.com/uc?id=${archivo.gdrive_id}&export=download`} > <i className="fa fa-file" /></a>) }




                                            {
                                              // JSON.stringify(archivo)
                                            }
                                            {
                                              // `https://docs.google.com/  uc?id=${archivo.gdrive_id}&export=download`
                                            }
                                          </td>
                                          <td>
                                            <p  className="nm"><small>{archivo.descripcion||'Sin Descripción'}</small></p>
                                          </td>
                                          <td style={{width:60}}>
                                            <button type="button" onClick={(e:any)=>{copiarAlPortapapeles(`https://docs.google.com/uc?id=${archivo.gdrive_id}&export=download`)}} className="btn btn-sm btn-link"><i className="fa fa-link" /></button>
                                          </td>
                                          <td style={{width:60}}>
                                            <button type="button" onClick={(e)=>{eliminarArchivo(archivo.uuid)}} className="btn btn-sm btn-danger"><i className="fa fa-trash" /></button>
                                          </td>
                                        </tr>

                                    )
                                  }

                                </tbody>
                              </table>




                      </React.Fragment>
                    )
                }




      </Tab>
    </Tabs>




        </Modal.Body>

      </Modal>
    </>
  );
}


export default Archivos;