import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'


import {Button, Modal, Form, Col, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import { toast } from 'react-toastify'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import './date_format.scss'
import './mis_publicaciones.scss'





import Dialog from '../../services/Dialogs'

import moment from 'moment'

const ModalNuevoRegistroPublicacion = ( params : any ) => {

  const user = AuthService.getCurrentUser()


  const dialog = new Dialog();


  const { show, handleClose, tipo  } = params

const [ envio_server, setSeEnvio ] = useState<boolean>(false)



  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          url : yup.string().required(mensajes_validacion_generico),
          descripcion : yup.string().required(mensajes_validacion_generico),
          fecha_publicacion : yup.string().required(mensajes_validacion_generico),

            me_gusta : yup.number(),
            me_encanta : yup.number(),
            me_importa : yup.number(),
            me_divierte : yup.number(),
            me_asombra : yup.number(),
            me_entristece : yup.number(),
            me_enoja : yup.number(),
            retweet : yup.number(),
            compartidos : yup.number(),
            comentarios : yup.number(),
            estadisticas : yup.number(),


  });

const enviarFormulario = async (registro:any,event:any) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ''
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.create( `registrar_publicacion` , { ...registro, tipo : tipo })

        if(res_){
          toast.success('La publicación se agrego correctamente')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}




  return (
    <>
      <Modal show={show} dialogClassName="modal-lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Registro de Publicación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }

          <div className={`alert alert-primary ${tipo}`} role="alert">
            <i className={`fa fa-${tipo}`} /> PUBLICACIÓN {tipo.toUpperCase()}
          </div>


    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

            descripcion : "",
            url : "",
            fecha_publicacion : new Date(),


            me_gusta : 0, 
            me_encanta : 0, 
            me_importa : 0, 
            me_divierte : 0, 
            me_asombra : 0, 
            me_entristece : 0, 
            me_enoja : 0, 
            retweet : 0, 
            compartidos : 0, 
            comentarios : 0, 
            estadisticas : 0, 


      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>




            <Form.Group   className="mb-3" controlId="url">
              <Form.Label>Descripción o Detalle de la publicación</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="descripcion"
                value={values.descripcion}
                onChange={(e)=>{ setFieldValue('descripcion', (e.target.value).toUpperCase() ) }}
                isInvalid={!!errors.descripcion}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>



            <Form.Group   className="mb-3" controlId="url">
              <Form.Label>URL Link de la Publicación</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="url"
                value={values.url}
                onChange={(e)=>{ setFieldValue('url', (e.target.value) ) }}
                isInvalid={!!errors.url}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>




            <div className="row">
              <div className="col-sm-4">
                  <div className="mb-3">
                    <Form.Label>Fecha</Form.Label>
                    <DatePicker inline selected={values.fecha_publicacion} className="form-control"  dateFormat="yyyy-MM-dd" maxDate={new Date()} onChange={(date) => setFieldValue('fecha_publicacion', date ) } />
                  </div>
              </div>
            </div>


 

                               { tipo === 'facebook' &&
                                 (

                                      <div className="card text-white mb-3 bg-facebook nm">
                                        <div className="card-header"> <i className="fa fa-facebook-official" /> Facebook</div>
                                        <div className="card-body">


                                              <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio1">👍</label>
                                                      <input className="form-control form-control-sm" value={values.me_gusta} onChange={(e:any)=>{setFieldValue('me_gusta', e.target.value * 1 )}} type="number" id="reaccio1" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio2">❤️</label>
                                                      <input className="form-control form-control-sm" value={values.me_encanta} onChange={(e:any)=>{setFieldValue('me_encanta', e.target.value * 1 )}} type="number" id="reaccio2" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio3">💕</label>
                                                      <input className="form-control form-control-sm" value={values.me_importa} onChange={(e:any)=>{setFieldValue('me_importa', e.target.value * 1 )}} type="number" id="reaccio3" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio4">😂</label>
                                                      <input className="form-control form-control-sm" value={values.me_divierte} onChange={(e:any)=>{setFieldValue('me_divierte', e.target.value * 1 )}} type="number" id="reaccio4" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio5">😮</label>
                                                      <input className="form-control form-control-sm" value={values.me_asombra} onChange={(e:any)=>{setFieldValue('me_asombra', e.target.value * 1 )}} type="number" id="reaccio5" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio6">😢</label>
                                                      <input className="form-control form-control-sm" value={values.me_entristece} onChange={(e:any)=>{setFieldValue('me_entristece', e.target.value * 1 )}} type="number" id="reaccio6" />
                                                    </div>
                                                </div>
                                              </div>


                                              <div className="row">
                                                <div className="col col-md-2">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio6">😡</label>
                                                      <input className="form-control form-control-sm" value={values.me_enoja} onChange={(e:any)=>{setFieldValue('me_enoja', e.target.value * 1 )}} type="number" id="reaccio6" />
                                                    </div>
                                                </div>
                                                <div className="col col-md-2">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio7">🗨️</label>
                                                      <input className="form-control form-control-sm" value={values.comentarios} onChange={(e:any)=>{setFieldValue('comentarios', e.target.value * 1 )}} type="number" id="reaccio7" />
                                                    </div>
                                                </div>
                                                <div className="col col-md-2">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio8">🔃</label>
                                                      <input className="form-control form-control-sm" value={values.compartidos} onChange={(e:any)=>{setFieldValue('compartidos', e.target.value * 1 )}} type="number" id="reaccio8" />
                                                    </div>
                                                </div>
                                              </div>
 


                                        </div>
                                      </div>


                                   )
                               }


                               { tipo === 'instagram' &&
                                 (


                                      <div className="card text-white mb-3 bg-instagram nm">
                                        <div className="card-header"> <i className="fa fa-instragram" /> Instagram</div>
                                        <div className="card-body">


                                              <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio1">👍</label>
                                                      <input className="form-control form-control-sm" value={values.me_gusta} onChange={(e:any)=>{setFieldValue('me_gusta', e.target.value * 1 )}} type="number" id="reaccio1" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio7">🗨️</label>
                                                      <input className="form-control form-control-sm" value={values.comentarios} onChange={(e:any)=>{setFieldValue('comentarios', e.target.value * 1 )}} type="number" id="reaccio7" />
                                                    </div>
                                                </div>
                                              </div>

                                        </div>
                                      </div>


                                   )
                               }



                               { tipo === 'twitter' &&
                                 (

                                      <div className="card text-white mb-3 bg-twitter nm">
                                        <div className="card-header"> <i className="fa fa-twitter" /> Twitter</div>
                                        <div className="card-body">

                                              <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio7">🗨️</label>
                                                      <input className="form-control form-control-sm" value={values.comentarios} onChange={(e:any)=>{setFieldValue('comentarios', e.target.value * 1 )}} type="number" id="reaccio7" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio8">🔃</label>
                                                      <input className="form-control form-control-sm" value={values.retweet} onChange={(e:any)=>{setFieldValue('retweet', e.target.value * 1 )}} type="number" id="reaccio8" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio1">❤️</label>
                                                      <input className="form-control form-control-sm" value={values.me_gusta} onChange={(e:any)=>{setFieldValue('me_gusta', e.target.value * 1 )}} type="number" id="reaccio1" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio1">📊</label>
                                                      <input className="form-control form-control-sm" value={values.estadisticas} onChange={(e:any)=>{setFieldValue('estadisticas', e.target.value * 1 )}} type="number" id="reaccio1" />
                                                    </div>
                                                </div>
                                              </div>

                                        </div>
                                      </div>



                                   )
                               }


        </Form>
      )}
    </Formik>







        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalNuevoRegistroPublicacion;