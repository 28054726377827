import React, { useState } from "react";
import AuthService from "../features/auth/auth.service";

import './Profile.scss';



import { Button, Modal } from 'react-bootstrap'

import PasswordChecklist from "react-password-checklist"


import Dialog from '../services/Dialogs'

import { toast } from 'react-toastify'


import RequestService from "../services/request.service";



// import  Navegacion  from '../widgets/Navegacion'

// import { Navigate } from 'react-router-dom';

// export default class Profile extends Component {
const Profile = () => {


    const [password, setPassword] = useState("")
    const [passwordAgain, setPasswordAgain] = useState("")

    const dialog = new Dialog();


    const VERSION_INSTALADA = localStorage.getItem("version_app");
    const [ VERSION_ACTUAL, serVersionActual ] = useState<string>('')

      const cargarVersionActual = async () =>{
        try{
            let res = await RequestService.getEndPoint('version_app')
            serVersionActual(res.data)

        }
        catch(e){
          toast.error('Problemas cargando la versión actual')
          toast.error(JSON.stringify(e))
        }
      }

      React.useEffect(() => {
        cargarVersionActual()
      }, []);

  const labelPrivilegio = (rol:string)=>{
    let e = ''

    if(rol==='ROLE_ADMIN') e = 'ADMINISTRADOR'
    if(rol==='ROLE_VOCERO') e = 'VOCERO'
    if(rol==='ROLE_DISENO') e = 'DISEÑO'

      return e

  }



  const handleLogout = async () => {
    if(await dialog.confirm('¿Estás seguro que quieres salir?',{}) ){
        AuthService.logout();
        window.location.reload();
    }
  }

    // const FormateaNumero = (n:string) => n.substr(0,3) + '-' + n.substr(3,3) + '-' + n.substr(6,4) 

    const currentUser  = AuthService.getCurrentUser();
    // const accessToken = AuthService.getToken()
    const [show, setShow] = useState(false);
    const [send, setSend] = useState(false);

  const handleClose = () => setShow(false);
  const cambiarContrasenaModal = () =>{ 
      setPassword("")
      setPasswordAgain("")
      setShow(true);
  }
  
  const cambiarContrasena = async () =>{ 
      
      try{
          if(await dialog.confirm('¿Estás seguro que quieres cambiar tu contraseña?',{}) ){
              setShow(false);
              await RequestService.update('cambiar_password/'+currentUser.uuid,{ psw : password })
              toast.warning('Debes volver a entrar al sistema')
              setTimeout(()=>{
                  AuthService.logout();
                  window.location.reload();
              },1500)
          }
      }
      catch(e){
          toast.error('Error al cambiar la contraseña')
      }
  }



  const deleteCache = async ()=>{
    
          if(await dialog.confirm('¿Estás seguro?',{}) ){
                  localStorage.clear();
                  window.location.reload();
          }
  }
  
      return (

        <React.Fragment>
          



            <div className="container-fuid contenedor">
              <div className="row">
                  <div className="col-lg-4 col-xl-4">

                    <div className="widget-container">
                      
                      <div className="card-box text-center">
                      {
                          // <img src="https://bootdey.com/img/Content/avatar/avatar7.png" className="rounded-circle avatar-xl img-thumbnail" alt="profile-image" />
                      }

                             <div className="userMain">
                               <div className="userBlock">


                                    <div className="text-left ms-3 me-3 mt-3 mb-3">

                                        <h4>Información de Usuario</h4>

                                        {
                                          // JSON.stringify(currentUser)
                                        }
                                        <p className="text-muted mb-2 font-13"><strong>Dependencia : </strong> <span className="ml-2 "><b>{currentUser.dependencia.country}</b> {currentUser.dependencia.company_name}</span></p>

                                        <p className="text-muted mb-2 font-13"><strong>Nombre : </strong> <span className="ml-2">{currentUser.first_name} {currentUser.last_name}</span></p>
                                        <p className="text-muted mb-2 font-13"><strong>Celular : </strong><span className="ml-2">{(currentUser.phone)}</span></p>
                                        <p className="text-muted mb-2 font-13"><strong>Usuario : </strong> <span className="ml-2 ">{currentUser.email}</span></p>


                <p className="text-muted mb-2 font-13"><strong>Privilegios : </strong> <span className="ml-2 ">{labelPrivilegio(currentUser.rol)}</span></p>


                <p className="text-muted mb-2 font-13"><strong>Versión Instalada : </strong> <span className="ml-2 ">{VERSION_INSTALADA||`No podemos verificar la versión instalada `}</span></p>
                <p className="text-muted mb-2 font-13"><strong>Versión Actual : </strong> <span className="ml-2 ">{VERSION_ACTUAL}</span></p>





                                    </div>


                               </div>
                             </div>


                                            <p className="nm mt-4"><small>Opciones</small></p>
                                            <ul className="list-inline">
                                            {
                                              <li className="list-inline-item" key={ Math.random().toString(36).substr(2, 9) }>
                                                <button onClick={()=>{cambiarContrasenaModal()}} className="btn btn-link">Cambiar Contraseña</button>
                                              </li>
                                            }

                                                <li className="list-inline-item" key={ Math.random().toString(36).substr(2, 9) }>
                                                  <button onClick={()=>{handleLogout()}} className="btn btn-link">Salir</button>
                                                </li>


                                                  <li className="list-inline-item" key={ Math.random().toString(36).substr(2, 9) }>
                                                    <button onClick={()=>{deleteCache()}} className="btn btn-link">Borrar Caché</button>
                                                  </li>


                                            </ul>

                                    
                      </div> 


                    </div>               

                  </div> 

                  <div className="col-lg-8 col-xl-8">
                      <div className="card-box-">
               
                          
                              



                      </div> 

                  </div> 
              </div>
            </div>




          <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Cambiar de Contraseña</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                


                  <form>

                      <div className="form-group mb-3">
                          <label className="form-label">Password:</label>
                          <input className="form-control" type="password" onChange={e => setPassword(e.target.value)} />
                      </div>
                      <div className="form-group mb-3">
                          <label className="form-label">Repetir Password:</label>
                          <input className="form-control" type="password" onChange={e => setPasswordAgain(e.target.value)} />
                      </div>


                      <PasswordChecklist
                          rules={["minLength","specialChar","number","capital","match"]}
                          messages={{
                              minLength: "La contraseña tiene más de 8 carácteres.",
                              specialChar: "La contraseña debe contener carácteres especiales.",
                              number: "La contraseña debe tener un número.",
                              capital: "La contraseña debe tener una letra mayúscula.",
                              match: "Las contraseñas coinciden.",
                          }}
                          minLength={5}
                          value={password}
                          valueAgain={passwordAgain}
                          onChange={(isValid) => {
                              setSend(!isValid)
                          }}
                      />
                  </form>

    {
      // password
    }

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" disabled={send} onClick={cambiarContrasena}>
                Guardar Cambios
              </Button>
            </Modal.Footer>
          </Modal>






        </React.Fragment>




      );
    
}

export default Profile
