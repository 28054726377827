import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'


import Pagination from "../../services/Pagination";

import {SERVER_} from '../../config'



import '../Publicaciones/mis_publicaciones.scss'

const Redes = () =>{

const numberWithCommas = (x:string) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");


  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( true )

  const skull = [{
    informe : null
  }]

  const [ reporte, setResultados ] = useState<any>([])








  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*10);
      let response : any = await RequestService.getEndPoint('dependencias/reporte_redes/busqueda?where='+text_busqueda+'&skip='+skip)
      if(response){
        setCounter(response.data.totalCount)
        setResultados(response.data.resultados)
      }
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargarMiReporte(0)
    setCurrentPage(1)
  }



      React.useEffect(() => {
        cargarMiReporte(0)
      }, []);





      return (
          <>
            <div className="container-fluid contenedor">
                  


                  <h3 className="mt-3 mb-3">Informe de Redes Sociales</h3>

                  <hr/>




                <div className="row">
                
                    <div className="">
                      <label className="form-label">Búsqueda</label>
                      <div className="input-group mb-3">
                        <input type="text" className="form-control" onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={(event)=>{ setTextoBusqueda(event.target.value)}} />
                        <button onClick={busqueda} className="btn btn-primary" type="button" ><i className="fa fa-search" /></button>
                      </div>
                    </div>

                    <ul className="list-inline">
                      <li className="list-inline-item"><a href={SERVER_+'reporte_red/excel'} target="_blank" className="btn btn-link">Descargar</a></li>
                    </ul>


                </div>






                          {  (!cargando_) && (

                              <>

                                   {
                                     reporte.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <table className="display dataTable no-footer mb-3">
                                          <thead>
                                            <tr>
                                              <th scope="col">Dependencia</th>
                                              <th scope="col">Acrónimo</th>

                                              <th scope="col" className="text-center facebook"><i className="fa fa-facebook"/></th>
                                              <th style={{fontSize:9}} className="text-center facebook" scope="col">Alcance</th>
                                              <th style={{fontSize:9}} className="text-center facebook" scope="col">Seguidores</th>
                                              <th style={{fontSize:9}} className="text-center facebook" scope="col">Me Gusta</th>
                                              <th style={{fontSize:9}} className="text-center facebook" scope="col">Interacciones</th>
                                              <th scope="col" className="text-center twitter"><i className="fa fa-twitter"/></th>
                                              <th style={{fontSize:9}} className="text-center twitter" scope="col">Alcance</th>
                                              <th style={{fontSize:9}} className="text-center twitter" scope="col">Seguidores</th>
                                              <th style={{fontSize:9}} className="text-center twitter" scope="col">Interacciones</th>
                                              <th scope="col" className="text-center instagram"><i className="fa fa-instagram"/></th>
                                              <th style={{fontSize:9}} className="text-center instagram" scope="col">Alcance</th>
                                              <th style={{fontSize:9}} className="text-center instagram" scope="col">Seguidores</th>
                                              <th style={{fontSize:9}} className="text-center instagram" scope="col">Interacciones</th>

{
// fb_link, fb_alcance, fb_seguidores, fb_me_gusta, fb_interacciones, tw_link, tw_alcance, tw_seguidores, tw_interacciones, ig_link, ig_alcance, ig_seguidores, ig_interacciones
}






                                              <th></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              reporte.map( (cual:any,indice:number) =>
                                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}}>
                                                    <td className="v text-left"  >
                                                        <p className="nm pointer" onClick={(e)=>{console.log(cual)}}><b>{cual.company_name}</b></p>
                                                    </td>
                                                    <td className="v" style={{"width":"160px"}}>
                                                      <p className="nm">{cual.country.toUpperCase()}</p>
                                                    </td>

                                                    {
                                                      !cual.informe && (
                                                          <td className="v text-center" colSpan={13}>
                                                            Sin Reporte de Redes
                                                            {
                                                              // JSON.stringify(cual.informe)
                                                            }
                                                          </td>
                                                        )
                                                    }



                                                    {
                                                      cual.informe && (
                                                          <>
                                                    <td style={{"width":"60px"}}>
                                                      <a target="_blank" href={cual.informe.fb_link}><i className="fa fa-link"/></a>
                                                    </td>
                                                    <td style={{"width":"60px"}}>
                                                      {numberWithCommas(cual.informe.fb_alcance)}
                                                    </td>
                                                    <td style={{"width":"60px"}}>
                                                      {numberWithCommas(cual.informe.fb_seguidores)}
                                                    </td>
                                                    <td style={{"width":"60px"}}>
                                                      {numberWithCommas(cual.informe.fb_me_gusta)}
                                                    </td>
                                                    <td style={{"width":"60px"}}>
                                                      {numberWithCommas(cual.informe.fb_interacciones)}
                                                    </td>
                                                    <td style={{"width":"60px"}}>
                                                      <a target="_blank" href={cual.informe.tw_link}><i className="fa fa-link"/></a>
                                                    </td>
                                                    <td style={{"width":"60px"}}>
                                                      {numberWithCommas(cual.informe.tw_alcance)}
                                                    </td>
                                                    <td style={{"width":"60px"}}>
                                                      {numberWithCommas(cual.informe.tw_seguidores)}
                                                    </td>
                                                    <td style={{"width":"60px"}}>
                                                      {numberWithCommas(cual.informe.tw_interacciones)}
                                                    </td>
                                                    <td style={{"width":"60px"}}>
                                                      <a target="_blank" href={cual.informe.ig_link}><i className="fa fa-link"/></a>
                                                    </td>
                                                    <td style={{"width":"60px"}}>
                                                      {numberWithCommas(cual.informe.ig_alcance)}
                                                    </td>
                                                    <td style={{"width":"60px"}}>
                                                      {numberWithCommas(cual.informe.ig_seguidores)}
                                                    </td>
                                                    <td style={{"width":"60px"}}>
                                                      {numberWithCommas(cual.informe.ig_interacciones)}
                                                    </td>
                                                          </>
                                                        )
                                                    }

                                                    





                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}


                  


            </div>

          </>

      );
  

}

export default Redes
