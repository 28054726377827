import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'


import ModalEvidenciasCargar from './ModalEvidenciasCargar'

import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'


import { obtenerDiaDeLaSemana } from '../../services/funcionesPublicas'


import Dialog from '../../services/Dialogs'

const MisTareasSemanales = () =>{

const dialog = new Dialog();


  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ tareas, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*10);
      let response : any = await RequestService.getEndPoint(`mis_tareas_semanales/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e:any){

      if (e.response?.status) {
        switch (e.response.status) {
          case 404:
            toast.error("Debes escribir algo")
            break;
          default:
            toast.error(e.response?.data)
        }
      }
      else{
        toast.error("Problemas con la busqueda")
      }
      setCargando(false)

    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true])


  const todos = () =>{
    setEstatus([true])
  }
  const ninguno = () =>{
    setEstatus([false])
  }


      React.useEffect(() => {
        busqueda()
      }, []);







 
 


  const [ show_ModalEvidenciasCargar, setModalEvidenciasCargar ] = useState<boolean>(false)
  const [ tarea_seleccionada, setTareaSeleccionada ] = useState<any>(null)
  const handleShow_ModalEvidenciasCargar = (tarea:any) => {
    setTareaSeleccionada(tarea)
    setModalEvidenciasCargar(true)
  }
  const handleClose_ModalEvidenciasCargar = () => {
        busqueda()
    setModalEvidenciasCargar(false)
  }

 


      return (
          <>
            <div className="container-fluid contenedor">

              <h3 className="mt-3 mb-3">Tareas Semanales</h3>

              <hr/>




              <div className="row">
              
                  <div className="mb-2">
                      <label className="form-label">Búsqueda</label>
                      <div className="hstack gap-3">
                        <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                        <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                        <div className="vr"></div>
                        <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                      </div>
                  </div>
                  
              </div>




                          {  (!cargando_) && (

                              <>

                                   {
                                     tareas.length > 0 && (

                                       <>


                                       <h5 className="mt-3">Tareas </h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col">Fecha Límite</th>
                                              <th scope="col">Tareas</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              tareas.map( (tarea:any,indice:number) =>
                                                  <tr key={indice} style={{fontSize:12}}>
                                                    <td className=" text-left" style={{"width":"260px"}}>
                                                        <h4 className="mt-4">
                                                          {
                                                            moment(tarea.fecha_limite).format('YYYY-MM-DD')
                                                          }
                                                        </h4>
                                                        <p className="nm">
                                                          {
                                                            obtenerDiaDeLaSemana(moment(tarea.fecha_limite).format('YYYY-MMM-DD'))
                                                          }
                                                        </p>
                                                    </td>
                                                    <td className="v text-left" >
                                                          <table className="table table-borderless">
                                                            <thead>
                                                              <tr>
                                                                <th scope="col"></th>
                                                                <th scope="col">Tarea</th>
                                                                <th scope="col"></th>
                                                                <th scope="col"></th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                  tarea.tareas.map((tarea:any,indice_tarea:number)=>
                                                                      <React.Fragment key={indice_tarea}>
                                                                          <tr>
                                                                            <td style={{"width":"60px"}}>{indice_tarea+1}.-</td>
                                                                            <td className="v">
                                                                              <p className="nm">{tarea.detalle_tarea}</p>
                                                                            </td>
                                                                            <td className="v" style={{"width":"40px"}}>
                                                                              { tarea.evidencias_tarea.length > 0 && (<>✅</>) }
                                                                            </td>
                                                                            <td className="v" style={{"width":"40px"}}>
                                                                              <button className="btn btn-info btn-sm" onClick={(e:any)=>{handleShow_ModalEvidenciasCargar(tarea)}}><i className="fa fa-book"/></button>
                                                                            </td>
                                                                          </tr> 
                                                                      </React.Fragment>
                                                                    )
                                                                }

                                                            </tbody>
                                                          </table>

                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}





            </div>



  {
    show_ModalEvidenciasCargar && (
      <ModalEvidenciasCargar show={show_ModalEvidenciasCargar} handleClose={handleClose_ModalEvidenciasCargar} tarea={tarea_seleccionada} />
      )

  }
 



          </>

      );
  

}

export default MisTareasSemanales