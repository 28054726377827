import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal, Form, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import { toast } from 'react-toastify'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from 'moment'

import Dialog from '../../services/Dialogs'


const ModalEditarPublicacion = ( params : any ) => {

  const { show, handleClose, publicacion } = params

  const dialog = new Dialog();



  const mensajes_validacion_generico = "Este campo es obligatorio"




const schema = yup.object().shape({
  publicacion: yup.string().required(),
  descripcion: yup.string().required(),
  // idred_social: yup.string().required(),
  fb_post : yup.boolean(), 
  fb_video : yup.boolean(), 
  fb_historia : yup.boolean(), 
  tw_post : yup.boolean(), 
  tw_video : yup.boolean(), 
  ig_post : yup.boolean(), 
  ig_historia : yup.boolean(), 
  ig_reel : yup.boolean(), 

  idtipo_publicacion: yup.string().required(),
  fecha_inicio: yup.string().required(),
  // fecha_fin: yup.string()
});




  // const [startDate, setStartDate] = useState<any>(new Date());

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Editar Información</Modal.Title>
        </Modal.Header>
          {
            // JSON.stringify(estatus)
          }
          {
            // JSON.stringify(redes_sociales)
          }

    <Formik
      validationSchema={schema}
      onSubmit={async(data)=>{
        // console.log('?')
        // console.log(data)
          if(await dialog.confirm('¿Estás seguro?',{})){

            let res = await RequestService.update('actualizar_publicacion/'+publicacion.uuid, { ...data })
            if(!res.data) toast.error('error al actualizar')
            if(res.data){
              toast.warning('El registro de publicación se actualizó')
              handleClose()
            }
            
          }


      }}
      initialValues={{
        publicacion: publicacion.publicacion,
        descripcion: publicacion.descripcion,
        // idred_social : publicacion.idred_social.uuid,
        fb_post : publicacion.fb_post,
        fb_video : publicacion.fb_video,
        fb_historia : publicacion.fb_historia,
        tw_post : publicacion.tw_post,
        tw_video : publicacion.tw_video,
        ig_post : publicacion.ig_post,
        ig_historia : publicacion.ig_historia,
        ig_reel : publicacion.ig_reel,

        idtipo_publicacion : publicacion.idtipo_publicacion.uuid,

        fecha_inicio : publicacion.fecha_inicio ? new Date(moment(publicacion.fecha_inicio).toString()) : null,
        // fecha_fin : publicacion.fecha_fin ? new Date(moment(publicacion.fecha_fin).toString()) : null,

        // idusuario : user._id
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        setFieldValue,
        touched,
        isValid,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>


        <Modal.Body>

        {
          // JSON.stringify(publicacion)
        }



                    <Form.Group
                      controlId="title"
                      className="mb-3"
                    >
                      <Form.Label>Publicación</Form.Label>
                      <Form.Control
                        type="text"
                        name="publicacion"
                        value={values.publicacion}
                        onChange={(e:any)=>{setFieldValue('publicacion',e.target.value.toUpperCase())}}
                        isValid={touched.publicacion && !errors.publicacion}
                        isInvalid={!!errors.publicacion}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.publicacion}</Form.Control.Feedback>
                    </Form.Group>


            <Form.Group as={Col}   controlId="idtipo_publicacion">
              <Form.Label>Categoría de la Publicación</Form.Label>
                  <Form.Select name="idtipo_publicacion" value={values.idtipo_publicacion} onChange={handleChange} isValid={touched.idtipo_publicacion && !errors.idtipo_publicacion} isInvalid={!!errors.idtipo_publicacion} >
                    <option disabled={true} value="">Selecciona una categoría</option>
                    <option value="b8698aa4-bd1d-4663-bf24-7ef33f99062b">CAMPAÑA</option>
                    <option value="cb27dfa8-0ecc-4c3f-be9f-cf72cf33df46">PUBLICACIÓN</option>
                  </Form.Select>
              <Form.Control.Feedback type="invalid">
                Debes seleccionar un categoría de públicación
              </Form.Control.Feedback>
            </Form.Group>



            <div className="mt-3">
              <Form.Label>Tipo de Publicación</Form.Label>
                <Form.Group className="mb-3">
                  <Form.Check
                    name="fb_post"
                    checked={values.fb_post}
                    label="FACEBOOK POST"
                    onChange={handleChange}
                    id="fb_post"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check
                    name="fb_video"
                    checked={values.fb_video}
                    label="FACEBOOK VIDEO"
                    onChange={handleChange}
                    id="fb_video"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check
                    name="fb_historia"
                    checked={values.fb_historia}
                    label="FACEBOOK HISTORY"
                    onChange={handleChange}
                    id="fb_historia"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check
                    name="tw_post"
                    checked={values.tw_post}
                    label="TWITTER POST"
                    onChange={handleChange}
                    id="tw_post"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check
                    name="tw_video"
                    checked={values.tw_video}
                    label="TWITTER VIDEO"
                    onChange={handleChange}
                    id="tw_video"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check
                    name="ig_post"
                    checked={values.ig_post}
                    label="INSTAGRAM POST"
                    onChange={handleChange}
                    id="ig_post"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check
                    name="ig_historia"
                    checked={values.ig_historia}
                    label="INSTAGRAM HISTORY"
                    onChange={handleChange}
                    id="ig_historia"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check
                    name="ig_reel"
                    checked={values.ig_reel}
                    label="INSTAGRAM REEL"
                    onChange={handleChange}
                    id="ig_reel"
                  />
                </Form.Group>

            </div>




            <div className="row">
              <div className="col-md-6">
                  <div className="mt-2">
                    <label className="form-label">Fecha Publicación</label>
                    <DatePicker className="form-control" selected={values.fecha_inicio} showTimeSelect onChange={e => setFieldValue('fecha_inicio', e)} dateFormat="yyyy-MM-dd HH:mm:ss"  />
                      {
                        !values.fecha_inicio && (
                          <div className="invalid-feedback" style={{display:'inline'}}>
                          Debes seleccionar una fecha
                        </div>
                        )
                      }
                  </div>
              </div>
              <div className="col-md-6">
              {
                // values.idtipo_publicacion === "b8698aa4-bd1d-4663-bf24-7ef33f99062b" && (
                //   <div className="mt-2">
                //     <label className="form-label">Fecha de Conclusión</label>
                //     <DatePicker className="form-control" selected={values.fecha_fin} showTimeSelect onChange={e => setFieldValue('fecha_fin', e)} dateFormat="yyyy-MM-dd HH:mm:ss" minDate={values.fecha_inicio} />
                //       {
                //         !values.fecha_fin && (
                //           <div className="invalid-feedback" style={{display:'inline'}}>
                //           Debes seleccionar una fecha
                //         </div>
                //         )
                //       }
                //       {
                //         values.fecha_fin && (
                //           <div className="valid-feedback" style={{display:'inline'}}>
                //           :)
                //         </div>
                //         )
                //       }
                //   </div>
                // )
              }

              </div>
            </div>





            <Form.Group as={Col} className="mt-2" controlId="descripcion">
              <Form.Label>Detalles de la publicación</Form.Label>
              <Form.Control as="textarea" name="descripcion" value={values.descripcion} rows={6} onChange={e =>{ setFieldValue('descripcion', e.target.value.toUpperCase() );}} isInvalid={!!errors.descripcion} isValid={touched.descripcion && !errors.descripcion} />
          <Form.Control.Feedback type="invalid">
            Debes de agregar una descripción
          </Form.Control.Feedback>
            </Form.Group>









        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" type="submit">Actualizar</Button>
        </Modal.Footer>


        </Form>
      )}
    </Formik>




      </Modal>
    </>
  );
}


export default ModalEditarPublicacion