import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import es from 'date-fns/locale/es';
import { Dropdown, Form } from 'react-bootstrap'

import Select from 'react-select';

import moment from 'moment'
import 'moment/locale/es'


import { SERVER_ } from  '../../config';
import AuthService from '../../features/auth/auth.service'


import Dialog from '../../services/Dialogs'

import {labelType} from '../Tickets/labelType'



import ModalNuevoRegistroPublicacion from './ModalNuevoRegistroPublicacion'
import ModalEditarRegistroPublicacion from './ModalEditarRegistroPublicacion'


import Archivos from '../Archivos/Archivos'



const Publicaciones = () =>{


  const dialog = new Dialog();

  const User : any = AuthService.getCurrentUser()

  React.useEffect(() => {
    actualizarFecha(startDate,endDate)
    cargarDependencias()
  }, []);

  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const onChange = (dates:any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };


  const [startDate_selected, setStartDateSelected] = useState<any>(null);
  const [endDate_selected, setEndDateSelected] = useState<any>(null);

  const [publicaciones, setPublicaciones] = useState<[]>([]);



  const actualizarFecha = async (startDate:any, endDate:any) => {
    try{
      var sD = moment(startDate).format('YYYY-MM-DD')
      var eD = moment(endDate).format('YYYY-MM-DD')
      setStartDateSelected(sD);
      setEndDateSelected(eD);
      let res = await RequestService.getEndPointPost('publicaciones/'+arreglo_estatus+'/'+sD+'/'+eD, { dependencias : etiquetas_seleccionadas })
      setPublicaciones(res.data)
      
    }
    catch(e){
      toast.error('Error')
    }

  }



  const [ arreglo_estatus, setEstatus ] = useState<any>([true,true,true])


const defaultValue : labelType[] = []
  const [ etiquetas_seleccionadas, setEtiquetasSeleccionada ] = useState<any>([])


  const [ dependencias, setDependencias ] = useState<any>([])
  const cargarDependencias = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('dependencias')
      setDependencias(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar dependencias')
    }
  }



  const todos = () =>{
    setEstatus([true,true,true])
  }
  const ninguno = () =>{
    setEstatus([false,false,false])
  }




    const [ modal_detalle_publicacion, setModalDetallePublicacion ] = useState<boolean>(false)
    const [ uuid_evento_seleccionado, setUUIDSeleccionado ] = useState<string>("")

    const handleShowModalDetalle = (uuid_publicacion:string ) => {
      setUUIDSeleccionado(uuid_publicacion)
      setModalDetallePublicacion(true)
    }
    const handleCloseModalDetallePublicacion = () => {
      setModalDetallePublicacion(false)
    }




  const [ tipo, setTipo ] = useState<string>('')
  const [ show_ModalNuevoRegistroPublicacion, setModalNuevoRegistroPublicacion ] = useState<boolean>(false)
  const handleShow_ModalNuevoRegistroPublicacion = (tipo:string) => {
    setTipo(tipo)
    setModalNuevoRegistroPublicacion(true)
  }
  const handleClose_ModalNuevoRegistroPublicacion = () => {
    setModalNuevoRegistroPublicacion(false)
  }


  const [ publicacion_seleccionada, setPublicacionSeleccionada ] = useState<any>({})
  const [ show_ModalEditarRegistroPublicacion, setModalEditarRegistroPublicacion ] = useState<boolean>(false)
  const handleShow_ModalEditarRegistroPublicacion = (publicacion:any) => {
    setPublicacionSeleccionada(publicacion)
    setModalEditarRegistroPublicacion(true)
  }
  const handleClose_ModalEditarRegistroPublicacion = () => {
    setModalEditarRegistroPublicacion(false)
  }






  const eliminarPublicacion =async (idproyecto:string)=>{
    try{
      if(await dialog.confirm('¿Estás seguro que deseas eliminar esta publicación?',{})){
        let res = await RequestService.delete('mi_publicacion/'+idproyecto)
        if(res.data){
          toast.warning('La publicación se elimino correctamente')
          actualizarFecha(startDate,endDate)
        }
        else{ toast.error('Error al eliminar la publicación') }
      }
    }
    catch(e){
      toast('Error al intentar eliminar la publicación')
    }
  }




  const [ show_Archivos, setArchivos ] = useState<boolean>(false)
  const handleShow_Archivos = (registro:any) => {
    setPublicacionSeleccionada(registro)
    setArchivos(true)
  }
  const handleClose_Archivos = () => {
    setArchivos(false)
  }





      return (
          <>


              <div className="container-fluid contenedor">
                  


                  <h3 className="mt-3 mb-3">Publicaciones</h3>


                  

                  <div className="row">
                    <div className="col-md-2">

                        <div className="text-center">
                          

                          <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            inline
                          />

                          <button disabled={!endDate} onClick={()=>{actualizarFecha(startDate,endDate)}} className="btn btn-xs btn-default">Actualizar la lista <i className="fa fa-refresh"/></button>



                          <ul className="list-group list-group-flush mt-4">
                            <li className="list-group-item list-group-item-action" onClick={(e:any)=>{handleShow_ModalNuevoRegistroPublicacion('facebook')}}><button type="button" className="btn btn-link">Post Facebook</button></li>
                            <li className="list-group-item list-group-item-action" onClick={(e:any)=>{handleShow_ModalNuevoRegistroPublicacion('twitter')}}><button type="button" className="btn btn-link">Post Twitter</button></li>
                            <li className="list-group-item list-group-item-action" onClick={(e:any)=>{handleShow_ModalNuevoRegistroPublicacion('instagram')}}><button type="button" className="btn btn-link">Post Instragram</button></li>
                          </ul>





                        </div>






                    </div>
                    <div className="col-md-10">

      {
                                              User.role_id === 0 && (

                                                  <Form.Group controlId="ticket_type_id" className="mb-3"  >
                                                    <Form.Label>Dependencias</Form.Label>

                                                          <Select
                                                            isMulti
                                                            defaultValue={defaultValue}
                                                            onChange={(opt:any,e:any)=>{ setEtiquetasSeleccionada(opt) }}
                                                            isClearable={true}
                                                            name="colors"
                                                            options={dependencias}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                          />
                                                  </Form.Group>

                                              )
                                            }





                                  {
                                    startDate_selected === endDate_selected && (
                                        <h4>PUBLICACIONES DEL DÍA {moment(startDate_selected).format('DD [DE] MMMM [DEL] YYYY').toUpperCase()}</h4>
                                      )
                                  }
                              

                                  {
                                    startDate_selected !== endDate_selected && (
                                        <h4>PUBLICACIONES ENTRE EL DÍA {moment(startDate_selected).format('DD [DE] MMMM [DEL] YYYY').toUpperCase()} Y EL DÍA {moment(endDate_selected).format('DD [DE] MMMM [DEL] YYYY').toUpperCase()}</h4>
                                      )
                                  }





{


                                    <div className="row">
                                      <div className="col-6">
                                        

                                      <div key={`inline-checkbox`} className="">
                                        <Form.Label>Estatus</Form.Label>


                                        <Form.Check inline name="group1" type="checkbox" label="FACEBOOK" checked={arreglo_estatus[0]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}} id={`opciones_1`} />
                                        <Form.Check inline name="group1" type="checkbox" label="TWITTER" checked={arreglo_estatus[1]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[1] = e.target.checked;  setEstatus(ne);}} id={`opciones_2`} />
                                        <Form.Check inline name="group1" type="checkbox" label="INSTAGRAM" checked={arreglo_estatus[2]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[2] = e.target.checked;  setEstatus(ne);}} id={`opciones_3`} />


                                      </div>

                                    <ul className="list-inline">
                                      <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
                                    </ul>

                                      </div>
                                      <div className="col-6">

                                      

                                      </div>
                                    </div>

}



                          {
                            publicaciones.length === 0 && (

                                <React.Fragment>
                                    
                                    <div className="alert alert-primary d-flex align-items-center" role="alert">
                                        <i className="fa fa-warning" />  No hay publicaciones para esa fecha
                                    </div>

                                </React.Fragment>

                              )
                          }
                          {
                            publicaciones.length > 0 && (

                                <React.Fragment>

                      
                                      <table className="table mb-3">
                                        <thead>
                                          <tr>
                                            <th>Publicación</th>
                                            <th>Fecha</th>
                                            {
                                              User.rol === "ROLE_ADMIN" && (
                                                  <th  >Usuario</th>
                                              )
                                            }
                                            {
                                              User.rol === "ROLE_ADMIN" && (
                                                  <th  >Dependencia</th>
                                              )
                                            }
                                            <th  ><i className="fa fa-link"/></th>

                                            <th>👍</th>
                                            <th>❤️</th>
                                            <th>💕</th>
                                            <th>😂</th>
                                            <th>😮</th>
                                            <th>😢</th>
                                            <th>😡</th>
                                            <th>🗨️</th>
                                            <th>🔃</th>
                                            <th>📊</th>

                                            <th></th>
                                            <th></th>
                                            <th></th>

                                          </tr>
                                        </thead>
                                        <tbody>
                                        {
                                          publicaciones.map( (publicacion:any) =>

                                              <tr key={ Math.random().toString(36).substr(2, 9) }>
                                                <td className="v" style={{width : '200px'}}>
                                                  <p onClick={()=>{handleShowModalDetalle(publicacion.uuid_publicacion)}} className="nm pointer   link-primary" ><small>{publicacion.descripcion}</small></p>
                                                </td>

                                                <td className="v" style={{width : '120px'}}>
                                                  <p className="nm"><small>{moment(publicacion.fecha_publicacion).format('YYYY-MM-DD')}</small></p>
                                                </td>
                                                
                                                {
                                                  User.rol === "ROLE_ADMIN" && (
                                                    <td className="v" >
                                                      <p className="nm" style={{fontSize:12}}><small>{publicacion.idusuario.first_name} {publicacion.idusuario.last_name} </small></p> 
                                                    </td>
                                                  )
                                                }
                                                
                                                {
                                                  User.rol === "ROLE_ADMIN" && (
                                                    <td className="v" style={{width : '250px'}}>
                                                      <p className="nm" style={{fontSize:12}}><small><b>{publicacion.country}</b> {publicacion.company_name} </small></p> 
                                                    </td>
                                                  )
                                                }
                                                
                                                <td className="v" style={{width : '40px'}}>
                                                  <a target="_blank" href={publicacion.url}>
                                                  { publicacion.idred_social === 1 && ( <i className="fa fa-facebook" /> ) }
                                                  { publicacion.idred_social === 4 && ( <i className="fa fa-twitter" /> ) }
                                                  { publicacion.idred_social === 6 && ( <i className="fa fa-instagram" /> ) }
                                                  </a>
                                                </td>
                                                <td className="v" style={{width : '40px'}}>
                                                  { publicacion.idred_social === 1 && ( <>{ publicacion.reacciones.me_gusta }</> ) }
                                                </td>
                                                <td className="v" style={{width : '40px'}}>
                                                  { publicacion.idred_social === 1 && ( <>{publicacion.reacciones.me_encanta}</> ) }
                                                  { publicacion.idred_social === 4 && ( <>{publicacion.reacciones.me_gusta}</> ) }
                                                  { publicacion.idred_social === 6 && ( <>{publicacion.reacciones.me_gusta}</> ) }
                                                </td>
                                                <td className="v" style={{width : '40px'}}>
                                                  { publicacion.idred_social === 1 && ( <>{publicacion.reacciones.me_importa}</> ) }
                                                </td>
                                                <td className="v" style={{width : '40px'}}>
                                                  { publicacion.idred_social === 1 && ( <>{publicacion.reacciones.me_divierte}</> ) }
                                                </td>
                                                <td className="v" style={{width : '40px'}}>
                                                  { publicacion.idred_social === 1 && ( <>{publicacion.reacciones.me_asombra}</> ) }
                                                </td>
                                                <td className="v" style={{width : '40px'}}>
                                                  { publicacion.idred_social === 1 && ( <>{publicacion.reacciones.me_entristece}</> ) }
                                                </td>
                                                <td className="v" style={{width : '40px'}}>
                                                  { publicacion.idred_social === 1 && ( <>{publicacion.reacciones.me_enoja}</> ) }
                                                </td>
                                                <td className="v" style={{width : '40px'}}>
                                                  { publicacion.idred_social === 1 && ( <>{publicacion.reacciones.comentarios}</> ) }
                                                  { publicacion.idred_social === 4 && ( <>{publicacion.reacciones.comentarios}</> ) }
                                                  { publicacion.idred_social === 6 && ( <>{publicacion.reacciones.comentarios}</> ) }
                                                </td>
                                                <td className="v" style={{width : '40px'}}>
                                                  { publicacion.idred_social === 1 && ( <>{publicacion.reacciones.compartidos}</> ) }
                                                  { publicacion.idred_social === 4 && ( <>{publicacion.reacciones.retweet}</> ) }
                                                </td>
                                                <td className="v" style={{width : '40px'}}>
                                                  { publicacion.idred_social === 4 && ( <>{publicacion.reacciones.estadisticas}</> ) }
                                                </td>




                                                <td style={{width : '40px'}}>
                                                  <button onClick={(e:any)=>{handleShow_ModalEditarRegistroPublicacion(publicacion)}} type="button" className="btn btn-sm btn-warning"><i className="fa fa-edit"/></button>
                                                </td>

                                                <td style={{width : '40px'}}>
                                                  <button onClick={(e:any)=>{eliminarPublicacion(publicacion.uuid)}} type="button" className="btn btn-sm btn-danger"><i className="fa fa-trash"/></button>
                                                </td>
                                                    <td className="v" style={{width:40}}>
                                                      <button type="button" onClick={(e)=>{handleShow_Archivos(publicacion)}} className="btn btn-sm btn-primary"><i className="fa fa-cloud" /></button>
                                                    </td>
                                              </tr>

                                            )
                                        }

                                        </tbody>
                                      </table>


                                </React.Fragment>

                              )
                          }























                    </div>
                  </div>




              </div>
              
              






  {
    show_ModalNuevoRegistroPublicacion && (
      <ModalNuevoRegistroPublicacion show={show_ModalNuevoRegistroPublicacion} handleClose={handleClose_ModalNuevoRegistroPublicacion} tipo={tipo} />
      )

  }



  {
    show_ModalEditarRegistroPublicacion && (
      <ModalEditarRegistroPublicacion show={show_ModalEditarRegistroPublicacion} handleClose={handleClose_ModalEditarRegistroPublicacion} publicacion={publicacion_seleccionada} />
      )

  }




  {
    show_Archivos && (
      <Archivos show={show_Archivos} handleClose={handleClose_Archivos} tipo_archivo="publicaciones" registro={publicacion_seleccionada} />
      )

  }




          </>

      );
  

}

export default Publicaciones
