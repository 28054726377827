import React, { useState } from 'react'

import { Modal, Tab, Tabs } from 'react-bootstrap'

import RequestService from "../../services/request.service";

import { SERVER_ } from  '../../config';

import { toast } from 'react-toastify'

import Dialog from '../../services/Dialogs'


const ModalAnexos = (props:any) => {


  const dialog = new Dialog();


	const { uuid_publicacion, handleClose, show } = props

  const [ anexos_tramite_seleccionado, setAnexosSeleccionados ] = useState<any>({
    anexos_digitales : [],
    anexos_por_subir : []
  })

  // const [showModalAnexos, setModalAnexos ] = useState(false)

	const cargarAnexos = async ()=>{
    try{
      // console.log(tramite)
      // setTramiteSeleccion(tramite)
      let req = await RequestService.getEndPoint('publicacion/anexos_digitales/'+uuid_publicacion)
      // console.log(req.data)
      setAnexosSeleccionados(req.data)
      // setModalAnexos(true)
    }
    catch(e){
      // setModalAnexos(false)
      toast.error('Error al cargar los anexos')
    }
	}

  React.useEffect(() => {
    cargarAnexos()
  }, []);

// const [ anexo_visualizar, setAnexoVisualizar ] = useState<string>('')
// const [ anexo_para_subir, setAnexoSubir ] = useState<string>('')
const [ anexo_archivo_subir, setArchivoSubir ] = useState<any>('')

// const setAnexoVer = (anexo:string)=>{
//   setAnexoVisualizar(anexo)
// }
const [key, setKey] = useState<any>('home');


  const eliminarAnexo = async (uuid:string) =>{
    try{
        if (await dialog.confirm( '¿Estas seguro?', {} )) {
        await RequestService.delete('eliminar-anexo-publicacion/'+uuid)
        // cargarMiReporte(fecha)
        let req = await RequestService.getEndPoint('publicacion/anexos_digitales/'+uuid_publicacion)
        // setAnexoVisualizar('')
        // console.log(req.data)
        setAnexosSeleccionados(req.data)

        toast.warning('Borraste un anexo. Espero que sepas lo que haces')
      }

    }
    catch(e){
      // setEnviando(false)
      toast.error('No pudimos agregar el anexo digital. Comunicate con el administrador.')
    }  
  }


  const handleFileRead = async (event:any) => {
    const file = event.target.files[0]
    const base64 = await convertBase64(file)
    setArchivoSubir(base64)
  }


  const convertBase64 = (file:any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }



  const agregarNuevoAnexo = async () =>{
    try{
        if(await dialog.confirm('¿Estás seguro?',{})){
        await  RequestService.update('publicacion/subir_anexo/'+uuid_publicacion, { documento : anexo_archivo_subir})

        // setAnexoVisualizar('')
        // setAnexoSubir('')
        setArchivoSubir('')
          let req2 = await RequestService.getEndPoint('publicacion/anexos_digitales/'+uuid_publicacion)
          // console.log(req.data)
          setAnexosSeleccionados(req2.data)
          toast.success('Tu anexo se subio correctamente')
      }
    }
    catch(e){toast.error('Error')}
  }


	return (



      <Modal show={show} backdrop="static" size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Anexos Digitales del Trámite
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          


    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="home" title="Anexos">


        {
          anexos_tramite_seleccionado.anexos_digitales.length > 0 && (
            <React.Fragment>
              <div className="row">
                {
                  anexos_tramite_seleccionado.anexos_digitales.map((anexo:any) =>


                        <div className="col-md-6" key={ Math.random().toString(36).substr(2, 9) }>

                                  { (["image/jpg","image/jpeg","image/png"].indexOf(anexo.tipo_archivo) >= 0) && ( 
                                    <>
                                      <img className="card-img-top" src={ SERVER_ + 'archivo/preview/' + anexo.uuid } width="100%" alt="" />
                                    </>
                                    )}


                                  { anexo.tipo_archivo === "video/mp4" && ( 
                                    <>
                                          <video controls style={{"width": "100%"}}>
                                              <source type={'video/'+anexo.tipo_archivo} src={ SERVER_ + 'archivo/preview/'+anexo.uuid} />
                                          </video>
                                    </>
                                    )}


                          <p onClick={()=>{eliminarAnexo(anexo.uuid)}} className="nm pointer">
                            <i className="fa fa-trash"/> Eliminar
                          </p>
                          {
                            // JSON.stringify(anexo.tipo_archivo)
                          }
                        </div>
                  )
                }
              </div>
            </React.Fragment>
          )
        }



            {
              // JSON.stringify(anexos_tramite_seleccionado.anexos_digitales)
            }
      </Tab>
      <Tab eventKey="profile" title="Carga de Anexos">

          <h5>Cargar un Anexo de la Publicación</h5>

<div className="mt-4 mb-4">
  <label htmlFor="formFileLg" className="form-label">Archivo para subir</label>
  <input className="form-control form-control-lg" id="formFileLg" type="file" accept="image/jpeg,image/jpg,image/png,video/mp4" onChange={(event)=>{ handleFileRead(event); }} />
</div>

{
  // JSON.stringify(anexo_archivo_subir)

  anexo_archivo_subir !== '' && (

    <object data={anexo_archivo_subir} type="application/pdf" width="100%" height="900">
      <p>It appears you don't have a PDF plugin for this browser.
      No biggie... you can <a href="http://www.example.com/the.pdf">click here to
      download the PDF file.</a></p>
    </object>
)

}


<div className="d-grid gap-2 mt-2">
  <button className="btn btn-primary" onClick={(e)=>{agregarNuevoAnexo()}} disabled={anexo_archivo_subir===''} type="button">AGREGAR ANEXO</button>
</div>





      </Tab>
    </Tabs>



        </Modal.Body>
      </Modal>




		)

}

export default ModalAnexos