import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

// import moment from 'moment'
// import 'moment/locale/es'


// import { Table } from 'react-bootstrap'

import { SERVER_ } from '../../config'

import Pagination from "../../services/Pagination";


import ModalAgregarDependencia from './ModalAgregarDependencia'
import ModalEditarDependencia from './ModalEditarDependencia'
import ModalDetallesDependencia from './ModalDetallesDependencia'

import ModalDetalles from './ModalDetalles'


import ModalCambiarLogo from './ModalCambiarLogo'

const Dependencias = () =>{


  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( true )

  const skull = { results: [] }

  const [ reporte, setResultados ] = useState<any>({...skull})


  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*10);
      let response : any = await RequestService.getEndPoint('dependencias/busqueda?where='+text_busqueda+'&skip='+skip)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargarMiReporte(0)
    setCurrentPage(1)
  }




  const [ show_ModalAgregarDependencia, setModalAgregarDependencia ] = useState<boolean>(false)
  const handleShow_ModalAgregarDependencia = () => {
    setModalAgregarDependencia(true)
  }
  const handleClose_ModalAgregarDependencia = () => {
    setModalAgregarDependencia(false)
  }





  const [ dependencia_seleccionada, setDependenciaSeleccionada ] = useState<any>({})
  const [ show_ModalEditarDependencia, setModalEditarDependencia ] = useState<boolean>(false)
  const editarDependencia = (dependencia:any) => {
    setDependenciaSeleccionada(dependencia)
    setModalEditarDependencia(true)
  }
  const handleClose_ModalEditarDependencia = () => {
    cargarMiReporte(currentPage-1)
    setModalEditarDependencia(false)
  }




  const [ show_ModalDetallesDependencia, setModalDetallesDependencia ] = useState<boolean>(false)
  const detallesDependencia = (dependencia:any) => {
    setDependenciaSeleccionada(dependencia)
    setModalDetallesDependencia(true)
  }
  const handleClose_ModalDetallesDependencia = () => {
    setModalDetallesDependencia(false)
  }


      React.useEffect(() => {
        cargarMiReporte(0)
      }, []);




  const [ show_ModalDetalles, setModalDetalles ] = useState<boolean>(false)
  const handleShow_ModalDetalles = () => {
    setModalDetalles(true)
  }
  const handleClose_ModalDetalles = () => {
    setModalDetalles(false)
  }



  const [ show_ModalCambiarLogo, setModalCambiarLogo ] = useState<boolean>(false)
  const handleShow_ModalCambiarLogo = (dependencia:any) => {
    setDependenciaSeleccionada(dependencia)
    setModalCambiarLogo(true)
  }
  const handleClose_ModalCambiarLogo = () => {
    setModalCambiarLogo(false)
  }


      return (
          <>

                          
            <div className="container-fluid contenedor">
              

              {
                // JSON.stringify(User)
              }

              <h3 className="mt-3 mb-3">Dependencias</h3>



                <div className="row">
                
                    <div className="">
                      <label className="form-label">Búsqueda</label>
                      <div className="input-group mb-3">
                        <input type="text" className="form-control" onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={(event)=>{ setTextoBusqueda(event.target.value)}} />
                        <button onClick={busqueda} className="btn btn-primary" type="button" ><i className="fa fa-search" /></button>
                      </div>
                    </div>

                    <ul className="list-inline">
                      <li className="list-inline-item"><button type="button" onClick={handleShow_ModalAgregarDependencia} className="btn btn-link">Crear Dependencia</button></li>
                    </ul>

                </div>







                          {  (!cargando_) && (

                              <>

                                   {
                                     reporte.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <table className="display dataTable no-footer mb-3">
                                          <thead>
                                            <tr>
                                              <th scope="col"></th>
                                              <th scope="col">Dependencia</th>
                                              <th scope="col">Acrónimo</th>
                                              <th scope="col">Contacto Primario</th>
                                              <th scope="col">Grupo de Cliente</th>


                                              <th scope="col">Usuarios</th>







                                              <th></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              reporte.map( (cual:any,indice:number) =>
                                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}}>
                                                    <td className="v text-center" style={{"width":"60px"}}>
                                                          <p className="nm">{cual.id}</p>
                                                    </td>
                                                    <td className="v text-center"  >
                                                        {
                                                          cual.logo && (

                                                              <>
                                                                <img src={`${SERVER_}dependencia/logo/${cual.id}`} onClick={(e:any)=>{handleShow_ModalCambiarLogo(cual)}} style={{width:50}} />
                                                              </>
                                                            )
                                                        }

                                                        {
                                                          !cual.logo && (

                                                              <>
                                                                <i className="fa fa-image pointer" onClick={(e:any)=>{handleShow_ModalCambiarLogo(cual)}} />
                                                              </>
                                                            )
                                                        }
                                                    </td>
                                                    <td className="v text-left"  >
                                                        <h5 className="nm pointer" onClick={(e)=>{detallesDependencia(cual)}}>{cual.company_name}</h5>
                                                    </td>
                                                    <td className="v" style={{"width":"160px"}}>
                                                      <p className="nm">{cual.country.toUpperCase()}</p>
                                                    </td>
                                                    <td className="v" style={{"width":"160px"}}>
                                                        <p className="nm">{(cual.contacto.first_name+' '+cual.contacto.last_name).toUpperCase()}</p>
                                                      {
                                                        // JSON.stringify(cual.contacto)
                                                      }
                                                    </td>
                                                    <td style={{"width":"160px"}}></td>


                                                    <td style={{"width":"60px"}}>
                                                      {cual.usuarios}
                                                    </td>




                                                    <td className="text-center v" style={{"width":"50px"}}>
                                                      <button type="button" onClick={(e)=>{editarDependencia(cual)}} className="btn btn-sm btn-primary"><i className="fa fa-edit" /></button>
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}



                </div>
              



  {
    show_ModalAgregarDependencia && (
      <ModalAgregarDependencia show={show_ModalAgregarDependencia} handleClose={handleClose_ModalAgregarDependencia}  />
      )

  }


  {
    show_ModalEditarDependencia && (
      <ModalEditarDependencia show={show_ModalEditarDependencia} handleClose={handleClose_ModalEditarDependencia} dependencia={dependencia_seleccionada} />
      )

  }


  {
    show_ModalDetallesDependencia && (
      <ModalDetalles show={show_ModalDetallesDependencia} handleClose={handleClose_ModalDetallesDependencia} dependencia={dependencia_seleccionada}  />
      )

  }




  {
    show_ModalCambiarLogo && (
      <ModalCambiarLogo show={show_ModalCambiarLogo} handleClose={handleClose_ModalCambiarLogo} dependencia={dependencia_seleccionada} />
      )

  }


          </>

      );
  

}

export default Dependencias
