import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import { Button, Modal, Form  } from 'react-bootstrap';

import Select from 'react-select';


import moment from 'moment'
import 'moment/locale/es'


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



import {labelType} from '../Tickets/labelType'

const Reportes = () =>{

      // const [ registro, setRegistro ] = useState<any>([])

      // const cargar = async () =>{
      //   try{
      //       let res = await RequestService.getEndPoint('api_route')
      //       setRegistro(res.data)

      //   }
      //   catch(e){
      //     toast.error('Problemas cargando')
      //     toast.error(JSON.stringify(e))
      //   }
      // }


      const defaultValue : labelType[] = []
      const [ etiquetas_seleccionadas, setEtiquetasSeleccionada ] = useState<any>([])
      // const [ productos_seleccionados, setProductosSeleccionados ] = useState<any>([])




      // const [allSessionsCount,setCounter] = useState(0);
      // const sessionsPerPage = 10
      // const [currentPage, setCurrentPage] = useState(1);

      // const [ text_busqueda, setTextoBusqueda ] = useState('')

      // const [ cargando_, setCargando ] = useState( true )

      // const [ resultados, setResultados ] = useState<any>([{ project_id : { title : "" } }])


      // const cargar = async(page:number=1)=>{
      //   try{
      //     setCargando(true)
      //     // let skip = 0
      //     // if(page===0) skip = 0; else skip = (page*10);
      //     // setCurrentPage(page===0?1:page+1)
      //     // let response : any = await RequestService.getEndPointPost('boletines/'+arreglo_estatus+'/busqueda?where='+text_busqueda+'&skip='+skip, { dependencias : etiquetas_seleccionadas, productos : productos_seleccionados })
      //     // setCounter(response.data.totalCount)
      //     // setResultados(response.data.resultados)
      //     setCargando(false)
      //   }
      //   catch(e){
      //     setCargando(false)
      //     toast.error('Problemas al intentar cargar tus resultados')
      //   }
      // }


      // const busqueda = async () =>{
      //   setCurrentPage(1)
      //   cargar(0)
      // }





  const [ dependencias, setDependencias ] = useState<any>([])
  const cargarDependencias = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('dependencias')
      setDependencias(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar dependencias')
    }
  }



  // const [ productos, setProductos ] = useState<any>([])
  // const cargarProductos = async()=>{
  //   try{
      
  //     let response : any = await RequestService.getEndPoint('tipo_boletin')
  //     setProductos(response.data)
  //   }
  //   catch(e){
  //     toast.error('Problemas al intentar cargar productos')
  //   }
  // }

  const [ sin_registro, setSinRegistro ] = useState<boolean>(true)

  const [ data_reporte, setDataReporte ] = useState<any>({ lista_dependencias : [] })

      const cargar = async (startDate:any, endDate:any, d_a:boolean) => {
        try{
            var sD = moment(startDate).format('YYYY-MM-DD')
            var eD = moment(endDate).format('YYYY-MM-DD')
            setStartDateSelected(sD);
            setEndDateSelected(eD);
            let res = await RequestService.getEndPointPost(`reporte/acumulados/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}`, { order_by : order_by, desc_asc : d_a, dependencias : etiquetas_seleccionadas, sin_registro : sin_registro, reporte_excel : false })
            setDataReporte(res.data)

        }
        catch(e){
          toast.error('Problemas cargando')
          toast.error(JSON.stringify(e))
        }
      }




      React.useEffect(() => {
        cargarDependencias()
        // cargarProductos()
      }, []);


  const [startDate_selected, setStartDateSelected] = useState<any>(null);
  const [endDate_selected, setEndDateSelected] = useState<any>(null);

  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const onChange = (dates:any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };



    const [ generando_reporte, setGenerandoReporte ] = useState<boolean>(false)


  const abrirReportePDF = async () => {

    try{

        
        setGenerandoReporte(true)
        // let etiquetas_ = tags_etiquetas.map(etiqueta => etiqueta.uuid )
        // window.open(SERVER_+'reporte/temas_analisis/'+moment(startDate).format('YYYY-MM-DD')+'/'+moment(endDate).format('YYYY-MM-DD')+'/'+tendencia+'/'+etiquetas_.join(','))
        // let etiquetas_ = tags_etiquetas.map(etiqueta => etiqueta.uuid )
        // let medios_ = tags.map(medio => medio.uuid )
            let res = await RequestService.getEndPointPostExcel(`reporte/excel/acumulados/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}`, { dependencias : etiquetas_seleccionadas, sin_registro : sin_registro, reporte_excel : true })
        // console.log(res)
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Reporte Acumulado `+moment().format('YYYY_MM_DD_HH_mm')+`.xlsx`);
        document.body.appendChild(link);
        link.click();
        setGenerandoReporte(false)
      }
      catch(e){
        setGenerandoReporte(false)
      }

  }

  const [ order_by, setOrderBy ] = useState<string>('total_eventos')
  const [ desc_asc, setDescAsc ] = useState<boolean>(false)

  const ordenarColumnas = (order:string, d_a:boolean ) =>{
    setDescAsc(d_a)
    setOrderBy(order)
    cargar(startDate,endDate,d_a)

  }


      return (
          <>


            <div className="container-fluid contenedor">
                  


                  <h3 className="mt-3 mb-3">Analíticos</h3>

                  <hr/>


                  <div className="row">
                    <div className="col-2">



                    
                        <Form.Group
                          controlId="ticket_type_id"
                          className="mb-3"  >
                              <Form.Label>Dependencias</Form.Label>

                          <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            inline
                          />


                        </Form.Group>

                    
                          <Form.Group
                            controlId="ticket_type_id"
                            className="mb-3"  >
                                <Form.Label>Dependencias</Form.Label>
                                <Select
                                  isMulti
                                  defaultValue={defaultValue}
                                  onChange={(opt:any,e:any)=>{ setEtiquetasSeleccionada(opt) }}
                                  isClearable={true}
                                  name="colors"
                                  options={dependencias}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                />
                        </Form.Group>


                        <button type="button" disabled={!endDate} onClick={()=>{cargar(startDate,endDate,desc_asc)}} className="btn mt-3 mb-3 btn-primary">Actualizar Reporte</button>



                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" checked={sin_registro} onChange={(e)=>{ setSinRegistro(e.target.checked) }} />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                          No incluir las dependencias <b>sin algún</b> registro
                        </label>
                      </div>


                    </div>
                    <div className="col-10">
                      




                          {
                            startDate_selected === endDate_selected && (
                                <h4>Publicaciones para el día {startDate_selected}</h4>
                              )
                          }
                      

                          {
                            startDate_selected !== endDate_selected && (
                                <h4>Publicaciones entre el día {startDate_selected} y el {endDate_selected}</h4>
                              )
                          }





                          {
                            data_reporte.lista_dependencias.length === 0 && (
                              <React.Fragment>

                                    <div className="alert alert-danger" role="alert">
                                       <i className="fa fa-warning" /> Ninguna dependencia tiene información para este rango de fechas
                                    </div>

                              </React.Fragment>
                            )
                          }


                          {
                            data_reporte.lista_dependencias.length > 0 && (
                              <React.Fragment>


                                  <ul className="list-inline">

                                    <li className="list-inline-item">
                                      <button type="button" onClick={abrirReportePDF} className="btn btn-link">Reporte Excel { generando_reporte && ( <i className="fa fa-spin fa-spinner" /> ) }</button>
                                    </li>
                                  </ul>

                                    <table className="display dataTable no-footer mb-3">
                                      <thead>
                                        <tr>
                                          <th scope="col">Dependencia</th>
                                          <th scope="col"></th>
                                          <th className="pointer" onClick={(e:any)=>{ ordenarColumnas('total_eventos', !desc_asc ) }} scope="col">Eventos { (desc_asc && order_by === 'total_eventos' ) && (<i className="fa fa-angle-up" />) } { (!desc_asc && order_by === 'total_eventos' ) && (<i className="fa fa-angle-down" />) } </th>
                                          <th className="pointer" onClick={(e:any)=>{ ordenarColumnas('total_proyectos', !desc_asc ) }} scope="col">Proyectos { (desc_asc && order_by === 'total_proyectos' ) && (<i className="fa fa-angle-up" />) } { (!desc_asc && order_by === 'total_proyectos' ) && (<i className="fa fa-angle-down" />) } </th>
                                          <th className="pointer" onClick={(e:any)=>{ ordenarColumnas('total_boletines', !desc_asc ) }} scope="col">Boletines { (desc_asc && order_by === 'total_boletines' ) && (<i className="fa fa-angle-up" />) } { (!desc_asc && order_by === 'total_boletines' ) && (<i className="fa fa-angle-down" />) } </th>
                                          <th className="pointer" onClick={(e:any)=>{ ordenarColumnas('total_publicaciones', !desc_asc ) }} scope="col">Publicaciones { (desc_asc && order_by === 'total_publicaciones' ) && (<i className="fa fa-angle-up" />) } { (!desc_asc && order_by === 'total_publicaciones' ) && (<i className="fa fa-angle-down" />) } </th>
                                          <th className="pointer" onClick={(e:any)=>{ ordenarColumnas('total_tickets', !desc_asc ) }} scope="col">Tickets { (desc_asc && order_by === 'total_tickets' ) && (<i className="fa fa-angle-up" />) } { (!desc_asc && order_by === 'total_tickets' ) && (<i className="fa fa-angle-down" />) } </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          data_reporte.lista_dependencias.map((dependencia:any)=>
                                              <tr key={ Math.random().toString(36).substr(2, 9) } >

                                                <td >
                                                  <p className="nm">{dependencia.company_name}</p>
                                                  </td>
                                                <td style={{width:120}}>
                                                    <b><small>{dependencia.country}</small></b>
                                                  </td>
                                                <td style={{width:100}}>
                                                  {dependencia.total_eventos}
                                                  </td>
                                                <td style={{width:100}}>
                                                  {dependencia.total_proyectos}
                                                  </td>
                                                <td style={{width:100}}>
                                                  {dependencia.total_boletines}
                                                  </td>
                                                <td style={{width:140}}>
                                                  {dependencia.total_publicaciones}
                                                  </td>
                                                <td style={{width:100}}>
                                                  {dependencia.total_tickets}
                                                </td>
                                              </tr>
                                          )
                                        }

                                      </tbody>
                                    </table>


                                  {
                                    // JSON.stringify(data_reporte)
                                  }

                              </React.Fragment>
                            )
                          }




                    </div>
                  </div>


                  


            </div>





          </>

      );
  

}

export default Reportes
