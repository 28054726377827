import React, { useState } from 'react';

import RequestService from "../../services/request.service";



import moment from 'moment'
import 'moment/locale/es'


import { Modal, Tabs, Tab, Button } from 'react-bootstrap';

import { toast } from 'react-toastify'


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ModalResetPasswordUsuario from '../Usuarios/ModalResetPasswordUsuario'
import ModalEditarUsuario from '../Usuarios/ModalEditarUsuario'


const ModalDetalles = ( params : any ) => {

  const formateoFecha = (fecha:any) =>{ return moment(fecha).format('DD [DE] MMMM [DE] YYYY').toUpperCase() }

  const { show, handleClose, dependencia } = params


  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const onChange = (dates:any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };



  const [startDate_selected, setStartDateSelected] = useState<any>(null);
  const [endDate_selected, setEndDateSelected] = useState<any>(null);


  React.useEffect(() => {
    cargarUsuariosDependencia()
    actualizarFecha(startDate,endDate)
  }, []);


  const [ acumulados, setAcumulados ] = useState<any>({})
  const actualizarFecha = async (startDate:any, endDate:any) => {
    try{
      var sD = moment(startDate).format('YYYY-MM-DD')
      var eD = moment(endDate).format('YYYY-MM-DD')
      setStartDateSelected(sD);
      setEndDateSelected(eD);
      let res = await RequestService.getEndPoint('dependencia/acumulados/'+dependencia.id+'/'+sD+'/'+eD)
      setAcumulados(res.data)
      
    }
    catch(e){
      toast.error('Error')
    }

  }


  const [ usuarios_dependencia, setUsuariosDependencias ] = useState<any>([])
  const cargarUsuariosDependencia = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('dependencia/usuarios/'+dependencia.id)
      setUsuariosDependencias(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar dependencias')
    }
  }





  const [ asesor_editar, setAsesorEditar ] = useState<any>({})
  const [ show_ModalResetPasswordUsuario, setModalResetPasswordUsuario ] = useState<boolean>(false)
  const handleShow_ModalResetPasswordUsuario = (usuario:any) => {
     setAsesorEditar(usuario)
    setModalResetPasswordUsuario(true)
  }
  const handleClose_ModalResetPasswordUsuario = () => {
    setModalResetPasswordUsuario(false)
  }

  const [ show_ModalEditarUsuario, setModalEditarUsuario ] = useState<boolean>(false)
  const handleShow_ModalEditarUsuario = (usuario:any) => {
     setAsesorEditar(usuario)
    setModalEditarUsuario(true)
  }
  const handleClose_ModalEditarUsuario = () => {
    cargarUsuariosDependencia()
    setModalEditarUsuario(false)
  }




  return (
    <>
      <Modal show={show} onHide={handleClose} dialogClassName="modal-xl" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>DETALLES DE LA DEPENDENCIA</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="row">
            <div className="col-sm-3">
              {
                // JSON.stringify(dependencia)
              }
                <dl>
                  <dt>Dependencia</dt>
                  <dd><h4 className="nm">{dependencia.company_name}</h4></dd>
                  <dt>Enlace</dt>
                  <dd><h4 className="nm">{dependencia.first_name} {dependencia.last_name}</h4></dd>
                  <dt>Dirección</dt>
                  <dd><h6 className="nm">{dependencia.address||'No proporcionado'}</h6></dd>
                  <dt>Ciudad</dt>
                  <dd><h6 className="nm">{dependencia.city||'No proporcionado'}</h6></dd>
                  <dt>Estado</dt>
                  <dd><h6 className="nm">{dependencia.state||'No proporcionado'}</h6></dd>
                </dl>

            </div>
            <div className="col-sm-9">
              



                  <Tabs
                    defaultActiveKey="proyectos"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey="proyectos" title="Reporte">
                     <React.Fragment>



                                

                                <div className="row">
                                  <div className="col-md-4">


                                      <div className="text-center">
                                        

                                        <DatePicker
                                          selected={startDate}
                                          onChange={onChange}
                                          startDate={startDate}
                                          endDate={endDate}
                                          selectsRange
                                          inline
                                        />

                                        <button disabled={!endDate} onClick={()=>{actualizarFecha(startDate,endDate)}} className="btn btn-xs btn-default"><i className="fa fa-refresh"/></button>

                                      </div>


                                  </div>
                                  <div className="col-md-8">



                                        {
                                          startDate_selected === endDate_selected && (
                                              <h5>Reporte para el día {startDate_selected}</h5>
                                            )
                                        }
                                    

                                        {
                                          startDate_selected !== endDate_selected && (
                                              <h5>Reporte entre el día {startDate_selected} y el {endDate_selected}</h5>
                                            )
                                        }

                                        <hr/>

                                          {
                                            // JSON.stringify(acumulados)
                                          }

                                          <small>Proyectos</small>
                                          <h5>{acumulados.acumulado_proyectos}</h5>

                                          <small>Eventos</small>
                                          <h5>{acumulados.acumulado_eventos}</h5>

                                          <small>Tickets</small>
                                          <h5>{acumulados.acumulado_tickets}</h5>

                                          <small>Boletines</small>
                                          <h5>{acumulados.acumulado_boletines}</h5>

                                  </div>
                                </div>






                     </React.Fragment>
                    </Tab>
                    <Tab eventKey="tickets" title="Usuarios">
                     <React.Fragment>
                        {
                          // JSON.stringify(usuarios_dependencia)
                        }

                        {
                          usuarios_dependencia.length > 0 && (


                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col"></th>
                                <th scope="col">Usuario</th>
                                <th scope="col">Admin</th>
                              </tr>
                            </thead>
                            <tbody>
                            {
                              usuarios_dependencia.map( (usuario:any, index : number) =>

                                        <tr>
                                          <th scope="row">
                                            {
                                              // JSON.stringify(usuario)
                                            }
                                            {(index+1)}
                                          </th>
                                          <td>
                                            {usuario.first_name} {usuario.last_name}
                                          </td>
                                          <td>
                                            {usuario.email}
                                          </td>
                                          <td>
                                            {usuario.phone}
                                          </td>
                                          <td className="v text-center" style={{"width":"60px"}}>
                                            { usuario.is_admin && ( <i className="fa fa-check-circle" /> ) }
                                          </td>
                                            <td className="v text-right" style={{"width":"40px"}}>
                                                  <Button  variant="warning" onClick={()=>{handleShow_ModalResetPasswordUsuario(usuario)}} size="sm"><i className="fa fa-key"/></Button>
                                            </td>
                                            <td className="v text-right" style={{"width":"40px"}}>
                                                  <Button  variant="warning" onClick={()=>{handleShow_ModalEditarUsuario(usuario)}} size="sm"><i className="fa fa-edit"/></Button>
                                            </td>

                                        </tr>


                                )
                            }

                            </tbody>
                          </table>



                            )
                        }


                     </React.Fragment>
                    </Tab>
                  </Tabs>



            </div>
          </div>

        </Modal.Body>
      </Modal>


  
  {
    show_ModalResetPasswordUsuario && (
      <ModalResetPasswordUsuario show={show_ModalResetPasswordUsuario} handleClose={handleClose_ModalResetPasswordUsuario} usuario={asesor_editar} />
      )

  }            


  {
    show_ModalEditarUsuario && (
      <ModalEditarUsuario show={show_ModalEditarUsuario} handleClose={handleClose_ModalEditarUsuario}  usuario={asesor_editar} />
      )

  }




    </>
  );
}


export default ModalDetalles;