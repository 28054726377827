import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal, Tabs, Tab } from 'react-bootstrap';

import { toast } from 'react-toastify'

import moment from 'moment'


import { obtenerDiaDeLaSemana } from '../../services/funcionesPublicas'

const ModalReporteTareas = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, tarea } = params





  const [ reportadas, setReportadas ] = useState<any>([])
  const [ envio_server, setSeEnvio ] = useState<boolean>(false)

  const cargarReportadas = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`tareas/reporte/${moment(tarea.fecha_limite).format('YYYY-MM-DD')}`)
      setReportadas(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }

  React.useEffect(() => {
    cargarReportadas()
  }, []);




const formatearReportes = (reporte:any) => {
  const resultado : any = [];

  // Agrupar los reportes por dependencia y usuario
  const agrupado : any = {};
  reporte.forEach((item:any) => {
    const dependenciaId = item.dependencia.id;
    const usuarioId = item.usuario.uuid_usuario;

    if (!agrupado[dependenciaId]) {
      agrupado[dependenciaId] = {
        dependencia: {
          id: dependenciaId,
          company_name: item.dependencia.company_name,
          country: item.dependencia.country,
        },
        cantidad_reportes: 0,
        usuarios_reportaron: {},
      };
    }

    if (!agrupado[dependenciaId].usuarios_reportaron[usuarioId]) {
      agrupado[dependenciaId].usuarios_reportaron[usuarioId] = {
        uuid_usuario: usuarioId,
        first_name: item.usuario.first_name,
        last_name: item.usuario.last_name,
        cantidad_reportes: 0,
      };
    }

    agrupado[dependenciaId].cantidad_reportes += 1;
    agrupado[dependenciaId].usuarios_reportaron[usuarioId].cantidad_reportes += 1;
  });

  // Convertir el objeto agrupado en un arreglo
  for (const dependenciaId in agrupado) {
    const dependenciaItem = agrupado[dependenciaId];
    dependenciaItem.usuarios_reportaron = Object.values(
      dependenciaItem.usuarios_reportaron
    );
    resultado.push(dependenciaItem);
  }

  // Ordenar el arreglo por dependencia id
  resultado.sort((a:any, b:any) => a.dependencia.id - b.dependencia.id);

  return resultado;
}



const formatearReportesPorUsuario = (reporte:any) => {
  const usuariosMap :any = {};

  reporte.forEach((item:any) => {
    const usuarioId = item.usuario.uuid_usuario;

    if (!usuariosMap[usuarioId]) {
      usuariosMap[usuarioId] = {
        uuid_usuario: usuarioId,
        first_name: item.usuario.first_name,
        last_name: item.usuario.last_name,
        reportes: [],
      };
    }

    usuariosMap[usuarioId].reportes.push({
      archivo: item.archivo,
      comentarios: item.comentarios,
      calificacion: item.calificacion,
      uuid_evidencia: item.uuid_evidencia,
      fecha_creacion: item.fecha_creacion,
    });
  });

  const usuariosArray = Object.values(usuariosMap);

  return usuariosArray;
}
 





  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Reporte de Tareas</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="row">
            <div className="col-sm-6">
              <small>Fecha del Reporte</small>
              <h4 className=" ">
                {
                  moment(tarea.fecha_limite).format('YYYY-MM-DD')
                }
              </h4>

            </div>
            <div className="col-sm-6">
              <small>Día</small>
              <p className=" ">
                {
                  obtenerDiaDeLaSemana(moment(tarea.fecha_limite).format('YYYY-MMM-DD'))
                }

              </p>
            </div>
          </div>

          {
            reportadas.map( (reporte:any, indice_reporte:number)=>

                <div key={indice_reporte}>
                  <h5>{indice_reporte+1}.- {reporte.detalle_tarea}</h5>

                  {
                    reporte.usuarios_reportaron.length === 0 && (
                        <div className="alert alert-warning" >
                          Ningún Vocero a reportado actividad
                        </div>
                      )
                  }
                  {
                    reporte.usuarios_reportaron.length > 0 && (
                        <div className="alert alert-info" >


                                  <Tabs
                                    defaultActiveKey="home"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                  >
                                    <Tab eventKey="home" title="Dependencias">
                                      

                                                        {
                                                          // JSON.stringify(formatearReportes(reporte.usuarios_reportaron))
                                                        }

                                          <table className="table" style={{fontSize:12}}>
                                            <thead>
                                              <tr>
                                                <th scope="col">Dependencia</th>
                                                <th scope="col">Voceros</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {
                                                (formatearReportes(reporte.usuarios_reportaron)).map((dependencia:any)=>
                                                  <tr>
                                                    <td className="v text-left" style={{width:250}}>
                                                      <p className="nm">{dependencia.dependencia.company_name}</p>
                                                      <p className="nm"><b><small>{dependencia.dependencia.country}</small></b></p>
                                                    </td>
                                                    <td className="v text-left">
                                                      {
                                                        // JSON.stringify(dependencia)
                                                      }


                                                        <table className="table table-sm table-borderless nm">
                                                          <thead>
                                                            <tr>
                                                              <th scope="col"> </th>
                                                              <th scope="col">Vocero</th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            {
                                                              dependencia.usuarios_reportaron.map((usuario:any,usuario_indice:number)=>
                                                                  <tr key={usuario_indice}>
                                                                    <td style={{width:50}}>
                                                                      <small>{usuario_indice+1}</small>
                                                                    </td>
                                                                    <td>
                                                                      <p className="nm"><b>{usuario.first_name}</b> {usuario.last_name}</p>
                                                                    </td>
                                                                  </tr> 
                                                               )
                                                            }

                                                          </tbody>
                                                        </table>





                                                    </td>
                                                  </tr> 
                                                  )
                                              }
                                            </tbody>
                                          </table>


                                    </Tab>
                                    <Tab eventKey="profile" title="Voceros">
                                      {
                                        // JSON.stringify(formatearReportesPorUsuario(reporte.usuarios_reportaron))
                                      }


                                          <table className="table table-sm" style={{fontSize:12}}>
                                            <thead>
                                              <tr>
                                                <th scope="col">Vocero</th>
                                                <th scope="col">Reportes</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {
                                                (formatearReportesPorUsuario(reporte.usuarios_reportaron)).map((vocero:any)=>
                                                  <tr>
                                                    <td className="v text-left" style={{width:200}}>
                                                      <p className="nm">{vocero.first_name}</p>
                                                      <p className="nm"><b><small>{vocero.last_name}</small></b></p>
                                                    </td>
                                                    <td className="v text-left">
                                                      {
                                                        // JSON.stringify(vocero.reportes)
                                                      }


                                                        <table className="table table-sm table-borderless nm">
                                                          <thead>
                                                            <tr>
                                                              <th scope="col"> </th>
                                                              <th scope="col">Vocero</th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            {
                                                              vocero.reportes.map((reporte:any,indice_reporte:number)=>
                                                                  <tr key={indice_reporte}>
                                                                    <td style={{width:50}}>
                                                                      <small>{indice_reporte+1}</small>
                                                                    </td>
                                                                    <td>
                                                                      <p className="nm">{reporte.comentarios}</p>
                                                                    </td>
                                                                  </tr> 
                                                               )
                                                            }

                                                          </tbody>
                                                        </table>



                                                    </td>
                                                  </tr> 
                                                  )
                                              }
                                            </tbody>
                                          </table>

                                    </Tab> 
                                  </Tabs>




                        </div>
                      )
                  }
                </div>

             )
          }





          {
            // JSON.stringify(reportadas)
          }

        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalReporteTareas;