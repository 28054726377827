import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import { Button, Modal, Form, Row, Col } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { Formik,  } from 'formik';
import * as yup from 'yup';

import Select from 'react-select'


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import {labelType} from '../Tickets/labelType'


import Dialog from '../../services/Dialogs'


import { esFechaFinMayor } from '../../services/funcionesPublicas'


import './eventos.scss'


const ModalNuevoEvento = ( params : any ) => {

  const { show, handleClose } = params



  const dialog = new Dialog();


  const schema = yup.object().shape({
    title : yup.string().required('Este campo es obligatorio'),
    description : yup.string().required('Este campo es obligatorio'),
    location : yup.string().required('Este campo es obligatorio'),
    color : yup.string().required('Este campo es obligatorio'),
    publicacion : yup.string().required('Este campo es obligatorio'),
    contexto : yup.string().required('Este campo es obligatorio'),
    start_date : yup.string().required('La fecha es obligatoria').nullable(),
    end_date : yup.string().nullable()
  });



  const cargarEtiquetas = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('todas_las_etiquetas')
      setEtiquetas(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar proyectos')
    }
  }


  const cargarDependencias = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('todas_las_dependencias')
      setDependencias(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar proyectos')
    }
  }



  React.useEffect(() => {
    cargarEtiquetas()
    cargarDependencias()
  }, []);



  const [ etiquetas_seleccionadas, setEtiquetasSeleccionada ] = useState<any>([])
  const [ etiquetasOptions, setEtiquetas ] = useState<labelType[]>([]);



  const [ dependencias_seleccionadas, setDependenciasSeleccionada ] = useState<any>([])
  const [ dependenciasOptions, setDependencias ] = useState<labelType[]>([]);



  return (
    <>
      <Modal show={show} dialogClassName="modal-md" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Añadir Evento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }


            <Formik
              validationSchema={schema}
              onSubmit={async(values:any)=>{
              
                        try{

                              if(await dialog.confirm('¿Estás seguro?',{})){

                                  if(!esFechaFinMayor(`${values.start_date}`,`${values.end_date}`)){
                                    toast.error('La fecha de Inicio debe ser menor a la fecha de fin')
                                    return
                                  }

                                  await RequestService.create('evento/alta', { ...values, labels : etiquetas_seleccionadas.map((label:any)=>label.value).join(',') , dependencias : dependencias_seleccionadas.map((label:any)=>label.value).join(',')  } )
                                  toast.success('El evento fue dado de alta')
                                  handleClose()
                              }
              
                        }
                        catch(e:any){
                          // console.log(e.response.data)
                          toast.error(e.response.data)
                        }
                        // console.log({ ...values })
                      }}
              initialValues={{

                title : "",
                description : "",
                location : "",
                color : "#83c340",
                publicacion : "",
                contexto : "",

                start_date : null,
                end_date : null,

              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form id='my-form-nuevo-evento' noValidate onSubmit={handleSubmit}>


{
  // JSON.stringify(values)
}

                    <Form.Group
                      controlId="title"
                      className="mb-3"
                    >
                      <Form.Label>Evento</Form.Label>
                      <Form.Control
                        type="text"
                        name="title"
                        value={values.title}
                        onChange={(e:any)=>{setFieldValue('title',e.target.value.toUpperCase())}}
                        isValid={touched.title && !errors.title}
                        isInvalid={!!errors.title}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                    </Form.Group>



                    <Form.Group
                      controlId="description"
                      className="mb-3"
                    >
                      <Form.Label>Detalles del Evento</Form.Label>
                      <Form.Control
                        type="text"
                        as="textarea"
                        rows={4}
                        name="description"
                        value={values.description}
                        onChange={(e:any)=>{setFieldValue('description',e.target.value.toUpperCase())}}
                        isValid={touched.description && !errors.description}
                        isInvalid={!!errors.description}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                    </Form.Group>




                    <Row>
                    <Form.Group
                      as={Col}
                      md='6'

                      controlId="start_date"
                      className="mb-3"
                    >

                      <Form.Label>Fecha y Hora de Inicio</Form.Label>
                      <Form.Control
                        type="hidden"
                        name="start_date"
                        onChange={handleChange}
                        isValid={touched.start_date && !errors.start_date}
                        isInvalid={!!errors.start_date}
                      />
                      <DatePicker className="form-control" selected={values.start_date}   showTimeSelect onChange={e => setFieldValue('start_date', e)} dateFormat="yyyy-MM-dd hh:mm:ss a"  />
                      <Form.Control.Feedback type="invalid">{errors.start_date}</Form.Control.Feedback>
                    </Form.Group>




                    <Form.Group
                      as={Col}
                      md='6'
                      controlId="end_date"
                      className="mb-3"
                    >

                      <Form.Label>Fecha y Hora de Fin</Form.Label>
                      <Form.Control
                        type="hidden"
                        name="end_date"
                        onChange={handleChange}
                        isValid={touched.end_date && !errors.end_date}
                        isInvalid={!!errors.end_date}
                      />
                      <div className="ulala">
                        <DatePicker className="form-control" selected={values.end_date}   showTimeSelect onChange={e => setFieldValue('end_date', e)} minDate={values.start_date} dateFormat="yyyy-MM-dd hh:mm:ss a"  />
                      </div>
                      <Form.Control.Feedback type="invalid">{errors.end_date}</Form.Control.Feedback>
                    </Form.Group>


                    </Row>





                    <Form.Group
                      controlId="location"
                      className="mb-3"
                    >
                      <Form.Label>Dirección</Form.Label>
                      <Form.Control
                        type="text"
                        name="location"
                        value={values.location}
                        onChange={(e:any)=>{setFieldValue('location',e.target.value.toUpperCase())}}
                        isValid={touched.location && !errors.location}
                        isInvalid={!!errors.location}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.location}</Form.Control.Feedback>
                    </Form.Group>





                    <Form.Group
                      controlId="publicacion"
                      className="mb-3"
                    >
                      <Form.Label>Nivel de Acceso al Evento</Form.Label>
                        <Form.Select name="publicacion" value={values.publicacion} onChange={handleChange} isValid={touched.publicacion && !errors.publicacion} isInvalid={!!errors.publicacion}>
                          <option value="" disabled>Selecciona un nivel de acceso</option>
                          <option value="Público">Público</option>
                          <option value="Privado">Privado</option>
                          <option value="Sin confirmar">Sin confirmar</option>
                          <option value="Confidencial">Confidencial</option>
                        </Form.Select>
                    </Form.Group>




                    <Form.Group
                      controlId="contexto"
                      className="mb-3"
                    >
                      <Form.Label>Contexto</Form.Label>
                      <Form.Control
                        type="text"
                        as="textarea"
                        rows={4}
                        name="contexto"
                        value={values.contexto}
                        onChange={(e:any)=>{setFieldValue('contexto',e.target.value.toUpperCase())}}
                        isValid={touched.contexto && !errors.contexto}
                        isInvalid={!!errors.contexto}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.contexto}</Form.Control.Feedback>
                    </Form.Group>








                    <Form.Group
                      controlId="ticket_type_id"
                      className="mb-3"
                    >

                      <Form.Label>Etiquetas</Form.Label>

                            <Select
                              isMulti
                              onChange={(opt:any,e:any)=>{ setEtiquetasSeleccionada(opt) }}
                              isClearable={true}
                              name="colors"
                              options={etiquetasOptions}
                              className="basic-multi-select"
                              classNamePrefix="select"
                            />
                    </Form.Group>





                    <Form.Group
                      controlId="ticket_type_id"
                      className="mb-3"
                    >

                      <Form.Label>Dependencias Relacionadas</Form.Label>

                            <Select
                              isMulti
                              onChange={(opt:any,e:any)=>{ setDependenciasSeleccionada(opt) }}
                              isClearable={true}
                              name="colors"
                              options={dependenciasOptions}
                              className="basic-multi-select"
                              classNamePrefix="select"
                            />
                    </Form.Group>


                    <div className="color-palet">
                            <Form.Label>Color</Form.Label>
                        <span style={{background:"#83c340"}} className={'color-tag clickable mr15 ' + ( values.color === '#83c340' ? 'active' : '' ) } onClick={(e:any)=>{ setFieldValue('color', '#83c340') }}></span>
                        <span style={{background:"#29c2c2"}} className={'color-tag clickable mr15 ' + ( values.color === '#29c2c2' ? 'active' : '' ) } onClick={(e:any)=>{ setFieldValue('color', '#29c2c2') }}></span>
                        <span style={{background:"#2d9cdb"}} className={'color-tag clickable mr15 ' + ( values.color === '#2d9cdb' ? 'active' : '' ) } onClick={(e:any)=>{ setFieldValue('color', '#2d9cdb') }}></span>
                        <span style={{background:"#aab7b7"}} className={'color-tag clickable mr15 ' + ( values.color === '#aab7b7' ? 'active' : '' ) } onClick={(e:any)=>{ setFieldValue('color', '#aab7b7') }}></span>
                        <span style={{background:"#f1c40f"}} className={'color-tag clickable mr15 ' + ( values.color === '#f1c40f' ? 'active' : '' ) } onClick={(e:any)=>{ setFieldValue('color', '#f1c40f') }}></span>
                        <span style={{background:"#e18a00"}} className={'color-tag clickable mr15 ' + ( values.color === '#e18a00' ? 'active' : '' ) } onClick={(e:any)=>{ setFieldValue('color', '#e18a00') }}></span>
                        <span style={{background:"#e74c3c"}} className={'color-tag clickable mr15 ' + ( values.color === '#e74c3c' ? 'active' : '' ) } onClick={(e:any)=>{ setFieldValue('color', '#e74c3c') }}></span>
                        <span style={{background:"#d43480"}} className={'color-tag clickable mr15 ' + ( values.color === '#d43480' ? 'active' : '' ) } onClick={(e:any)=>{ setFieldValue('color', '#d43480') }}></span>
                        <span style={{background:"#ad159e"}} className={'color-tag clickable mr15 ' + ( values.color === '#ad159e' ? 'active' : '' ) } onClick={(e:any)=>{ setFieldValue('color', '#ad159e') }}></span>
                        <span style={{background:"#37b4e1"}} className={'color-tag clickable mr15 ' + ( values.color === '#37b4e1' ? 'active' : '' ) } onClick={(e:any)=>{ setFieldValue('color', '#37b4e1') }}></span>
                        <span style={{background:"#34495e"}} className={'color-tag clickable mr15 ' + ( values.color === '#34495e' ? 'active' : '' ) } onClick={(e:any)=>{ setFieldValue('color', '#34495e') }}></span>
                        <span style={{background:"#dbadff"}} className={'color-tag clickable mr15 ' + ( values.color === '#dbadff' ? 'active' : '' ) } onClick={(e:any)=>{ setFieldValue('color', '#dbadff') }}></span> 
                        <input id="color" type="hidden" name="color" value="#83c340" />
                    </div>




                </Form>
              )}
            </Formik>


          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form-nuevo-evento' type="submit">Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalNuevoEvento;