import React, { useState } from 'react';

import RequestService from "../../services/request.service";
import { Modal } from 'react-bootstrap';


import { toast } from 'react-toastify'

import { SERVER_ } from  '../../config';


import moment from 'moment'
import 'moment/locale/es'


const ModalDetallesPublicacion = ( params : any ) => {

  const formateoFecha = (fecha:any) => moment(fecha).format('DD [DE] MMMM [DE] YYYY').toUpperCase()
  const diasDiferencia = (inicio:string,fin:string)=> moment(fin).diff(moment(inicio), 'days')
  
  const { show, handleClose, uuid_evento } = params

  const cargarFicha = async () =>{
    try{
      // console.log("publicacion/ficha/"+uuid_evento)
        let res = await RequestService.getEndPoint("publicacion/ficha/"+uuid_evento)
        if(res.data) setPublicacionDAata(res.data)

    }
    catch(e){
      toast.error('Problemas cargando las etiquetas')
    }
  }


  React.useEffect(() => {
    cargarFicha()
  }, []);


  const [ real_publicacion, setPublicacionDAata ] = useState<any>({
    descripcion: "",
  "idtipo_publicacion": {
    "uuid": "",
    "tipo_publicacion": ""
  },
  // "idred_social": {
  //   "uuid": "",
  //   "red": ""
  // },
  "idestatus": {
    "uuid": "",
    "estatus": ""
  },
  "idusuario": {
    "first_name": "",
    "last_name": "",
    "email": "",
  },
  "anexos": []
})



  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Detalle de la Publicación</Modal.Title>
        </Modal.Header>
         


        <Modal.Body>
          {
            // JSON.stringify(real_publicacion)
          }

          <dl>
            <dt>Categoría de la Publicación</dt>
            <dd>{real_publicacion.idtipo_publicacion.tipo_publicacion}</dd>



            <dt>Estatus</dt>
            <dd>{real_publicacion.idestatus.estatus}</dd>

            <dt>Publicación</dt>
            <dd>{real_publicacion.publicacion}</dd>

            <dt>Detalles de la publicación</dt>
            <dd>{real_publicacion.descripcion}</dd>


            <dt>Fecha de Publicación</dt>
            <dd>{formateoFecha(real_publicacion.fecha_inicio)}</dd>

            {
              (real_publicacion.fb_post || real_publicacion.fb_video || real_publicacion.fb_historia) && (
                  <>
                    <dt>Facebook</dt>
                    <dd>
                      { real_publicacion.fb_post && (<p className="nm">POST</p>) }
                      { real_publicacion.fb_video && (<p className="nm">VIDEO POST</p>) }
                      { real_publicacion.fb_historia && (<p className="nm">HISTORY</p>) }
                    </dd>
                  </>
                )
            }

            {
              (real_publicacion.tw_post || real_publicacion.tw_video) && (
                  <>
                    <dt>Twitter</dt>
                    <dd>
                      { real_publicacion.tw_post && (<p className="nm">POST</p>) }
                      { real_publicacion.tw_video && (<p className="nm">VIDEO POST</p>) }
                    </dd>
                  </>
                )
            }

            {
              (real_publicacion.ig_post || real_publicacion.ig_historia || real_publicacion.ig_reel) && (
                  <>
                    <dt>Instagram</dt>
                    <dd>
                      { real_publicacion.ig_post && (<p className="nm">POST</p>) }
                      { real_publicacion.ig_historia && (<p className="nm">HISTORY</p>) }
                      { real_publicacion.ig_reel && (<p className="nm">REEL</p>) }
                    </dd>
                  </>
                )
            }

            {
              real_publicacion.idtipo_publicacion.uuid === "b8698aa4-bd1d-4663-bf24-7ef33f99062b" && (
                <React.Fragment>
                {
                                    // <dt>Fecha de Conclusión</dt>
                                    // <dd>{formateoFecha(real_publicacion.fecha_fin)}</dd>
                                  }
                    {
                      diasDiferencia(real_publicacion.fecha_inicio,real_publicacion.fecha_fin) > 0 && (
                          <React.Fragment>
                              <dt>Días de la campaña</dt>
                              <dd>{diasDiferencia(real_publicacion.fecha_inicio,real_publicacion.fecha_fin)}</dd>
                          </React.Fragment>
                      )
                    }

                </React.Fragment>
              )
            }

          </dl>


{
  // JSON.stringify(real_publicacion.anexos)
}

        {
          real_publicacion.anexos.length > 0 && (
            <React.Fragment>
              <div className="row">
                {
                  real_publicacion.anexos.map((anexo:any) =>
                        <div className="col-md-6" key={ Math.random().toString(36).substr(2, 9) }>
                          {
                            // JSON.stringify(anexo)
                          }
                          <img src={ SERVER_ + 'archivo/preview/' + anexo.uuid } style={{width: "100%"}} className="mb-1" alt="" />
                        </div>
                  )
                }
              </div>
            </React.Fragment>
          )
        }



        </Modal.Body>


      </Modal>
    </>
  );
}


export default ModalDetallesPublicacion