import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import AuthService from '../../features/auth/auth.service'


import { esFechaFinMayor } from '../../services/funcionesPublicas'




const ModalDetalleEvento = ( params : any ) => {

  const { show, handleClose, evento } = params

  const User = AuthService.getCurrentUser()

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Detalles del Evento</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div style={{width:'100$',height:10,background:evento.color}}></div>

          {
            User.rol === "ROLE_ADMIN" && (
                <>
                  <p className="nm"><small>Dependencia</small></p>
                  <h6>{evento.dependencia.country} - {evento.dependencia.company_name}</h6>
                </>
            )
          }


          <p className="nm"><small>Evento</small></p>
          <h6>{evento.title}</h6>

          <p className="nm"><small>Detalles del Evento</small></p>
          <h6>{evento.description}</h6>


          <div className="row">
            <div className="col-sm-6">
              <p className="nm"><small> Fecha y Hora de Inicio </small></p>
              <h6>{evento.start_date} {evento.start_time}</h6>
            </div>
            <div className="col-sm-6">
              <p className="nm"><small>Fecha y Hora de Fin</small></p>
              <h6>{evento.end_date} {evento.end_time}</h6>
            </div>
          </div>



          {
            !esFechaFinMayor(`${evento.start_date} ${evento.start_time}`,`${evento.end_date} ${evento.end_time}`) && (
                <div className="alert alert-danger" role="alert">
                  La fecha de Fin del Evento es mayor que la fecha de Inicio
                </div>
              )
          }




          <p className="nm"><small>Dirección</small></p>
          <h6>{evento.location}</h6>
          <p className="nm"><small>Nivel de Acceso al Evento</small></p>
          <h6>{evento.publicacion}</h6>
          <p className="nm"><small>Contexto</small></p>
          <h6>{evento.contexto}</h6>

          {
            evento.labels.length > 0 && (
              <>
                <p className="nm"><small>Etiquetas</small></p>
                      {
                        evento.labels.map( (label:any) => <span key={ Math.random().toString(36).substr(2, 9) } style={{marginRight:5, backgroundColor : label.color}} className="badge"><small>{label.title}</small></span> )
                      }
              </>
            )
          }

          {
            evento.dependencias.length > 0 && (
              <>
                <p className="nm"><small>Dependencias Relacionadas</small></p>
                <ul>
                  { evento.dependencias.map( (dependencia:any) => <li><small><b>{dependencia.iddependencia.country}</b> {dependencia.iddependencia.company_name}</small></li> ) }
                </ul>
              </>
            )
          }




          {
            // JSON.stringify(evento.color)
          }
          {
            // JSON.stringify(evento.dependencias)
          }


        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalDetalleEvento;