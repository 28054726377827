import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import { Button, Modal } from 'react-bootstrap'

import { toast } from 'react-toastify'

import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import moment from 'moment'


import Dialog from '../../services/Dialogs'

const ModalEditarUsuario = ( params : any ) => {


      const [ dependencias, setDependencias ] = useState<any>([])

      const cargar = async () =>{
        try{
            let res = await RequestService.getEndPoint('dependencias')
            setDependencias(res.data)

        }
        catch(e){
          toast.error('Problemas cargando')
          toast.error(JSON.stringify(e))
        }
      }

      React.useEffect(() => {
        cargar()
      }, []);







  const { show, handleClose, usuario } = params

  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          // campo1 : yup.string().required(mensajes_validacion_generico),
          // campo2 : yup.string().nullable(),
          // campo_fecha : yup.string().required(mensajes_validacion_generico),

          first_name : yup.string().required(mensajes_validacion_generico),
          last_name : yup.string().required(mensajes_validacion_generico),
          // is_admin, 
          // email, 
          // status, 
          phone : yup.string().required(mensajes_validacion_generico),
          // address, 
          status : yup.boolean(),
          enable_web_notification : yup.boolean(),
          is_primary_contact : yup.boolean(),

          client_id : yup.number().required(mensajes_validacion_generico),
          // company_name, 
          // country, 

  });



const [ envio_server, setSeEnvio ] = useState<boolean>(false)


const enviarFormulario = async (registro:any,event:any) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ''
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `usuario/actualizar_datos/${usuario.uuid}` , { ...registro })

        if(res_){
          toast.success('Los datos del usuario se cambiaron correctamente')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}


  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Editar Información de Usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }




    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

            first_name : usuario.first_name,
            last_name : usuario.last_name,
            phone : usuario.phone,
            status : usuario.status,
            enable_web_notification : usuario.enable_web_notification,
            is_primary_contact : usuario.is_primary_contact,
            client_id : usuario.client_id || '',

      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>


 {
  // JSON.stringify(values)
 }

            <Form.Group   className="mb-3" controlId="first_name">
              <Form.Label>Nombre(s)</Form.Label>
              <Form.Control
                required
                name="first_name"
                value={values.first_name}
                onChange={(e)=>{ setFieldValue('first_name', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.first_name}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>



            <Form.Group   className="mb-3" controlId="last_name">
              <Form.Label>Apellidos</Form.Label>
              <Form.Control
                required
                name="last_name"
                value={values.last_name}
                onChange={(e)=>{ setFieldValue('last_name', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.last_name}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>



            <Form.Group   className="mb-3" controlId="phone">
              <Form.Label>Celular</Form.Label>
              <Form.Control
                required
                name="phone"
                value={values.phone}
                onChange={(e)=>{ setFieldValue('phone', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.phone}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>





            <div className="row">
              <div className="col-sm-5">
                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Acceso al Sistema</label>
                      <div className="form-check">
                        <input className="form-check-input" id="defaultCheck1" type="checkbox" checked={values.status} onChange={(e:any)=>{setFieldValue('status', e.target.checked )}} />
                        <label className="form-check-label" htmlFor="defaultCheck1">
                          Puede acceder
                        </label>
                      </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label"></label>
                      <div className="form-check">
                        <input className="form-check-input" id="is_primary_contact" type="checkbox" checked={values.is_primary_contact} onChange={(e:any)=>{setFieldValue('is_primary_contact', e.target.checked )}} />
                        <label className="form-check-label" htmlFor="is_primary_contact">
                          Marcar como enlace
                        </label>
                      </div>
                  </div>


              </div>
              <div className="col-sm-7">
                  <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Notificaciones</label>
                      <div className="form-check">
                        <input className="form-check-input" id="defaultCheck2" type="checkbox" checked={values.enable_web_notification} onChange={(e:any)=>{setFieldValue('enable_web_notification', e.target.checked )}} />
                        <label className="form-check-label" htmlFor="defaultCheck2">
                          <i className="fa fa-whatsapp" /> Notificar Boletines
                        </label>
                      </div>
                  </div>
              </div>
            </div>



                    <Form.Group
                      controlId="client_id"
                      className="mb-3"
                    >
                      <Form.Label>Dependecia</Form.Label>
                        <Form.Select name="client_id" value={values.client_id} onChange={handleChange}   >
                          <option value="" disabled>Selecciona un nivel de acceso</option>
                          {
                            dependencias.map( (dependencia:any)=> <option key={ Math.random().toString(36).substr(2, 9) } value={dependencia.value}>{dependencia.country} - {dependencia.label}</option> )
                          }
                        </Form.Select>
                    </Form.Group>

 


        </Form>
      )}
    </Formik>



        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalEditarUsuario;