import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import { Button, Modal, Form  } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import Select from 'react-select';

import { toast } from 'react-toastify'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import {labelType} from '../Tickets/labelType'


import Dialog from '../../services/Dialogs'



const ModalNuevaTarea = ( params : any ) => {


  const { show, handleClose, idproyecto } = params

  const dialog = new Dialog();


  const schema = yup.object().shape({
    title: yup.string().required(),
    description: yup.string().required(),
    status_id: yup.string().required('Este campo es obligatorio'),
    start_date: yup.string(),
    deadline: yup.string(),
    assigned_to: yup.string(),
    project_id: yup.number().required(),
    points: yup.number().required(),
    // client_id : yup.string().required('Este campo es obligatorio'),
  });



  const cargarUsuarios = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('usuarios')
      setUsuarios(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar proyectos')
    }
  }


  const cargarEtiquetas = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('todas_las_etiquetas')
      setEtiquetas(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar proyectos')
    }
  }

  React.useEffect(() => {
    cargarUsuarios()
    cargarEtiquetas()
  }, []);



const [colourOptions, setEtiquetas ] = useState<labelType[]>([]);
const [usuariosOptions, setUsuarios ] = useState<labelType[]>([]);


const defaultValue : labelType[] = []

  const [ etiquetas_seleccionadas, setEtiquetasSeleccionada ] = useState<any>([])


  const [ collaborators, setColaboradores ] = useState<any>([])


  const start_date : any = ''
  const deadline : any = ''





  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Añadir Tarea</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            JSON.stringify(idproyecto)
          }







            <Formik
              validationSchema={schema}
              onSubmit={async(values:any)=>{
              
                        try{

                              if(await dialog.confirm('¿Estás seguro?',{})){
                                  await RequestService.create('proyecto/tareas/crear', { ...values, labels : etiquetas_seleccionadas.map((label:any)=>label.value).join(','), collaborators : collaborators.map((label:any)=>label.value).join(',')  } )
                                  toast.success('El Proyecto se creo correctamente')
                                  handleClose()
                              }
              
                        }
                        catch(e){
                          toast.error('Error al dar de alta')
                        }
                        // console.log({ ...values })
                      }}
              initialValues={{

                title : "",
                description : "",
                status_id : "",
                start_date : null,
                deadline : null,
                assigned_to : "",
                project_id : idproyecto,
                points : "",

              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form id='my-form' noValidate onSubmit={handleSubmit}>


{
  // JSON.stringify(values)
}


                    <Form.Group
                      controlId="title"
                      className="mb-3"
                    >
                      <Form.Label>Tarea</Form.Label>
                      <Form.Control
                        type="text"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        isValid={touched.title && !errors.title}
                        isInvalid={!!errors.title}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                    </Form.Group>



                    <Form.Group
                      controlId="description"
                      className="mb-3"
                    >
                      <Form.Label>Detalles de la tarea</Form.Label>
                      <Form.Control
                        type="text"
                        as="textarea"
                        rows={8}
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        isValid={touched.description && !errors.description}
                        isInvalid={!!errors.description}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                    </Form.Group>



                      <div className="row">
                        <div className="col-md-6">
                            <div className="mb-2">
                              <label className="form-label">Fecha de la tarea</label>
                              <DatePicker className="form-control" selected={values.start_date} onChange={e => setFieldValue('start_date', e)} dateFormat="yyyy-MM-dd"  />
                            </div>
                        </div>
                        <div className="col-md-6">

                              <Form.Group
                                controlId="enlace"
                                className="mb-3"
                              >

                                <Form.Label>Fecha Límite</Form.Label>

                                <DatePicker className="form-control" selected={values.deadline} onChange={e => setFieldValue('deadline', e)} dateFormat="yyyy-MM-dd"  />
                                <Form.Control.Feedback type="invalid">{errors.deadline}</Form.Control.Feedback>
                              </Form.Group>
                        </div>
                      </div>


                              <Form.Group
                                controlId="enlace"
                                className="mb-3"
                              >

                                <Form.Label>Puntos</Form.Label>
                                  <Form.Select aria-label="Valor" name="points" value={values.points} onChange={handleChange} isValid={touched.points && !errors.points} isInvalid={!!errors.points} >
                                    <option>Selecciona una opción</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                  </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.points}</Form.Control.Feedback>
                              </Form.Group>


                    <div key={`inline-radio`} className="mb-3">
                    
                        <Form.Group
                          controlId="status_id"
                          className="mb-3"
                        >
                          <Form.Label>Estatus</Form.Label>

                            <Form.Check
                              inline
                              label="Por Hacer"
                              value="1"
                              name="status_id"
                            onChange={handleChange}
                              type={'radio'}
                              id={`open`}
                            isValid={touched.status_id && !errors.status_id}
                            isInvalid={!!errors.status_id}
                            />
                            <Form.Check
                              inline
                              label="En Progreso"
                              value="2"
                              name="status_id"
                            onChange={handleChange}
                              type={'radio'}
                              id={`completed`}
                            isValid={touched.status_id && !errors.status_id}
                            isInvalid={!!errors.status_id}
                            />
                            <Form.Check
                              inline
                              label="Realizado"
                              value="3"
                              name="status_id"
                            onChange={handleChange}
                              type={'radio'}
                              id={`hold`}
                            isValid={touched.status_id && !errors.status_id}
                            isInvalid={!!errors.status_id}
                            />
                          <Form.Control.Feedback type="invalid">{errors.status_id}</Form.Control.Feedback>
                        </Form.Group>

                    </div>


{
  // JSON.stringify(etiquetas_seleccionadas)
}


                    <Form.Group
                      controlId="assigned_to"
                      className="mb-3"
                    >

                      <Form.Label>Asignado a</Form.Label>
                      <Form.Control
                        type="hidden"
                        name="assigned_to"
                        value={values.assigned_to}
                        onChange={handleChange}
                        isValid={touched.assigned_to && !errors.assigned_to}
                        isInvalid={!!errors.assigned_to}
                      />
                    <Select options={usuariosOptions} name="assigned_to"  onChange={(opt:any,e:any)=>{ setFieldValue('assigned_to', opt.value ) }} />
                      <Form.Control.Feedback type="invalid">{errors.assigned_to}</Form.Control.Feedback>
                    </Form.Group>



                    <Form.Group
                      controlId="ticket_type_id"
                      className="mb-3"
                    >

                      <Form.Label>collaborators</Form.Label>

                            <Select
                              isMulti
                              defaultValue={defaultValue}
                              onChange={(opt:any,e:any)=>{ setColaboradores(opt) }}
                              isClearable={true}
                              name="collaborators"
                              options={usuariosOptions}
                              className="basic-multi-select"
                              classNamePrefix="select"
                            />
                    </Form.Group>


                    <Form.Group
                      controlId="ticket_type_id"
                      className="mb-3"
                    >

                      <Form.Label>Etiquetas</Form.Label>

                            <Select
                              isMulti
                              defaultValue={defaultValue}
                              onChange={(opt:any,e:any)=>{ setEtiquetasSeleccionada(opt) }}
                              isClearable={true}
                              name="colors"
                              options={colourOptions}
                              className="basic-multi-select"
                              classNamePrefix="select"
                            />
                    </Form.Group>



                </Form>
              )}
            </Formik>











        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Agregar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalNuevaTarea;