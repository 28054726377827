import React, { useState } from "react";
import RequestService from "../../services/request.service";


import AuthService from '../../features/auth/auth.service'

import NuevoReporte from './NuevoReporte'

import ModalActualizarReporte from './ModalActualizarReporte'






import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'

const ReportarRedes = () =>{

  const user = AuthService.getCurrentUser()
      const [ registro, setRegistro ] = useState<any>({
        dependencia : {},
        reporte : {
          uuid_reporte : ""
        }
      })

      const cargar = async () =>{
        try{
            let res = await RequestService.getEndPoint('dependencia/reporte_red')
            setRegistro(res.data)

        }
        catch(e){
          toast.error('Problemas cargando')
          toast.error(JSON.stringify(e))
        }
      }

      React.useEffect(() => {
        cargar()
      }, []);




  const [ show_NuevoReporte, setNuevoReporte ] = useState<boolean>(false)
  const [ dependencia_seleccionada, setDependenciaSeleccionada ] = useState<any>({})
  const handleShow_NuevoReporte = (dependencia:any) => {
    setDependenciaSeleccionada(dependencia)
    setNuevoReporte(true)
  }
  const handleClose_NuevoReporte = () => {
    cargar()
    setNuevoReporte(false)
  }




  const [ show_ModalActualizarReporte, setModalActualizarReporte ] = useState<boolean>(false)
  const handleShow_ModalActualizarReporte = (dependencia:any) => {
    setDependenciaSeleccionada(dependencia)
    setModalActualizarReporte(true)
  }
  const handleClose_ModalActualizarReporte = () => {
    cargar()
    setModalActualizarReporte(false)
  }

      return (
          <>

            <div className="container-fluid contenedor">
                  


                  <h3 className="mt-3 mb-3">Informe de Redes Sociales</h3>

                  <hr/>


                  <div className="row">
                    <div className="col-4">

                      <h6>{registro.dependencia.company_name}</h6>
                      <p className="nm">{registro.dependencia.country}</p>


                      {
                        !registro.reporte && (
                              <ul className="list-inline">
                                <li className="list-inline-item"><button onClick={(e:any)=>{handleShow_NuevoReporte(registro.dependencia)}} type="button" className="btn btn-link">Reportar</button></li>
                              </ul>
                          )
                      }

                      {
                        registro.reporte && (
                              <ul className="list-inline">
                                <li className="list-inline-item"><button onClick={(e:any)=>{handleShow_ModalActualizarReporte(registro.dependencia)}} type="button" className="btn btn-link">Reportar Incrementos</button></li>
                              </ul>
                          )
                      }





                    </div>
                    <div className="col-6">
                      

                  {
                    // JSON.stringify(registro.reporte)
                  }


                      {
                        !registro.reporte && (
                            <>
                              <i className="fa fa-warning"/> Aún no reportas el alcance de tus redes
                            </>
                          )
                      }



                      {
                        registro.reporte && (
                            <>
                                <div className="facebook mb-4" style={{padding : '15px 10px'}}>
                                  <h6 className="nm"><i className="fa fa-facebook"/> Facebook</h6>
                                </div>

                                  <div className="row mb-4">
                                    <div className="col-3">
                                      <p className="nm">Alcance</p>
                                      <h2>{registro.reporte.fb_alcance}</h2>
                                    </div>
                                    <div className="col-3">
                                      <p className="nm">Seguidores</p>
                                      <h2>{registro.reporte.fb_seguidores}</h2>
                                    </div>
                                    <div className="col-3">
                                      <p className="nm">Me Gusta</p>
                                      <h2>{registro.reporte.fb_me_gusta}</h2>
                                    </div>
                                    <div className="col-3">
                                      <p className="nm">Interacciones</p>
                                      <h2>{registro.reporte.fb_interacciones}</h2>
                                    </div>
                                  </div>



                                <div className="twitter mb-4" style={{padding : '15px 10px'}}>
                                  <h6 className="nm"><i className="fa fa-twitter"/> Twitter</h6>
                                </div>

                                  <div className="row">
                                    <div className="col-3">
                                      <p className="nm">Alcance</p>
                                      <h2>{registro.reporte.tw_alcance}</h2>
                                    </div>
                                    <div className="col-3">
                                      <p className="nm">Seguidores</p>
                                      <h2>{registro.reporte.tw_seguidores}</h2>
                                    </div>
                                    <div className="col-3">
                                      <p className="nm">Interacciones</p>
                                      <h2>{registro.reporte.tw_interacciones}</h2>
                                    </div>
                                  </div>



                                <div className="instagram mb-4" style={{padding : '15px 10px'}}>
                                  <h6 className="nm"><i className="fa fa-instagram"/> Instagram</h6>
                                </div>

                                  <div className="row">
                                    <div className="col-3">
                                      <p className="nm">Alcance</p>
                                      <h2>{registro.reporte.ig_alcance}</h2>
                                    </div>
                                    <div className="col-3">
                                      <p className="nm">Seguidores</p>
                                      <h2>{registro.reporte.ig_seguidores}</h2>
                                    </div>
                                    <div className="col-3">
                                      <p className="nm">Interacciones</p>
                                      <h2>{registro.reporte.ig_interacciones}</h2>
                                    </div>
                                  </div>








                            </>
                          )
                      }




                    </div>
                  </div>


                  {
                    // JSON.stringify(user.dependencia)
                  }



                
              


            </div>






  {
    show_NuevoReporte && (
      <NuevoReporte show={show_NuevoReporte} handleClose={handleClose_NuevoReporte} dependencia={dependencia_seleccionada} />
      )

  }



  {
    show_ModalActualizarReporte && (
      <ModalActualizarReporte show={show_ModalActualizarReporte} handleClose={handleClose_ModalActualizarReporte} uuid_reporte={registro.reporte.uuid} />
      )

  }

          </>

      );
  

}

export default ReportarRedes
