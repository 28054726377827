import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
// import { selectIsAuthenticated } from 'features/auth/authSlice'
// import { useSelector } from 'react-redux'
// import { useLoginMutation, useProtectedMutation } from 'features/auth/auth'
import './login.scss';


import { useNavigate } from "react-router"


import AuthService from '../features/auth/auth.service'

function Login() {

  const isAuthenticated = AuthService.selectIsAuthenticated

  let navigate = useNavigate();


  // const [ username, setUsername ] = useState('admin@gobiernodetodos.com')
  // const [ password, setPass ] = useState('Ok7emGB7')

  const [ username, setUsername ] = useState('')
  const [ password, setPass ] = useState('')


  const loginUser = async() =>{
      AuthService.login( username, password).then(
        () => {
          // toast('lo hiciste')
          window.location.reload();
          // navigate('/profile')
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          toast(resMessage)

        }
      );
  }

  // return (
  //   <div className="content">
  //     {isAuthenticated ? (
  //       <>
  //         <p>You're logged-in.</p>
  //       </>
  //     ) : (
  //       <>
  //         <p>You're in the Home page. Login to visit protected pages.</p>
  //         <button className="button" onClick={loginAsUser}>
  //           Login as User
  //         </button>
  //         <button className="button" onClick={loginAsAdmin}>
  //           Login as Admin
  //         </button>
  //       </>
  //     )}
  //     <button className="button" onClick={makeAuthRequest}>
  //       Authenticated request
  //     </button>
  //   </div>
  // )

  const setearUsername = (event:any)=> setUsername(event.target.value)
  const setearContrasena = (event:any)=> setPass(event.target.value)


  useEffect(() => {
    if(AuthService.selectIsAuthenticated){
      navigate('/profile')
    }
  }, [ navigate ]);



const handleTest = (e:any) => { if (e.charCode === 13) { loginUser() } if (e.keyCode === 13) { loginUser() } }




  return (
    <div className="content">

      {isAuthenticated ? (
        <>
          <p>You're logged-in.</p>

        </>
      ) : (
        <>

<div className="sidenav">
   <div className="login-main-text">
       <img src="../banner_logo_.png" alt="" height="80px" />
      <h2>Unidad de <br/> Comunicación Social</h2>
      <p style={{'fontSize': '40px'}}>Plataforma Voceros</p>
   </div>
</div>
<div className="main">
   <div className="col-md-6 col-sm-12">
      <div className="login-form">
         <form>

           <h2>Acceso al Sistema</h2>


            <div className="form-group">
               <label>Usuario</label>
               <input type="text" className="form-control" onKeyPress={handleTest} onChange={ setearUsername } />
            </div>
            <div className="form-group">
               <label>Contraseña</label>
               <input type="password" className="form-control" onKeyPress={handleTest} onChange={ setearContrasena } />
            </div>

              <button type="button" className="btn wu btn-primary" onClick={loginUser}>Entrar al Sistema</button>

         </form>
      </div>
   </div>
</div>



        </>
      )}

    </div>
  )
}

export default Login




          // <p>You're in the Home page. Login to visit protected pages.</p>
          // <button className="button" onClick={loginUser}>
          //   Login
          // </button>