import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'



import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';



import Dialog from '../../services/Dialogs'

import moment from 'moment'

const ModalNuevaTarea = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, fecha_limite } = params

const obtenerFechaEn7Dias = () => {
    const hoy = new Date();
    const diasEnElFuturo = 7;
    const fechaFutura = new Date(hoy.getTime() + (diasEnElFuturo * 24 * 60 * 60 * 1000));
    return fechaFutura;
}




  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          detalle_tarea : yup.string().required(mensajes_validacion_generico),
          fecha_limite : yup.string().required(mensajes_validacion_generico),

  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: `
            <p class="nm"><small>Descripción de la Tarea o Asignación</small></p>
            <h6 class="nm" style="max-width:300px">${registro.detalle_tarea}</h6>
            <p class="nm"><small>Fecha</small></p>
            <h6 class="nm" style="max-width:300px">${moment(registro.fecha_limite).format('YYYY-MM-DD')}</h6>
          `
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.create( `tareas/nueva_tarea` , { ...registro, fecha_limite : moment(registro.fecha_limite).format('YYYY-MM-DD') })

          if(res_){
            toast.success('La tarea se agregó')
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas agregando tarea')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }






  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Nueva Tarea</Modal.Title>
        </Modal.Header>
        <Modal.Body>



            <Formik
              validationSchema={schema}
              onSubmit={(values,e)=>{enviarFormulario(values, e)}}
              initialValues={{

                    detalle_tarea : "",
                    fecha_limite : !fecha_limite ? obtenerFechaEn7Dias() : new Date(fecha_limite),

              }}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form id='my-form' noValidate onSubmit={handleSubmit}>


                    <Form.Group className="mb-3" controlId="detalle_tarea">
                      <Form.Label>Descripción de la Tarea o Asignación</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={10}
                        required
                        name="detalle_tarea"
                        value={values.detalle_tarea}
                        onChange={(e)=>{ setFieldValue('detalle_tarea', (e.target.value || "").toUpperCase() ) }}
                        isInvalid={!!errors.detalle_tarea}
                      />
                      <Form.Control.Feedback type="invalid"> {<>{errors.detalle_tarea}</>} </Form.Control.Feedback>
                    </Form.Group>

                    {
                      !fecha_limite && (
                          <div className="row">
                            <div className="col-sm-6">
                                <div className="mb-3">
                                  <Form.Label>Fecha Límite para subir tarea</Form.Label>
                                  <DatePicker selected={values.fecha_limite} inline className="form-control" minDate={new Date()} dateFormat="yyyy-MM-dd"  onChange={(date) => setFieldValue('fecha_limite', date ) } />
                                </div>
                            </div>
                          </div>
                        )
                    }





                </Form>
              )}
            </Formik>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalNuevaTarea;