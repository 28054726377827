import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'


import moment from 'moment'
import 'moment/locale/es'

// import './Etiquetas.scss';

import { Dropdown, Form } from 'react-bootstrap'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import es from 'date-fns/locale/es';

// import FullCalendar from '@fullcalendar/react' // must go before plugins
// import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
// import timeGridPlugin from '@fullcalendar/timegrid'

// import { Link } from 'react-router-dom'
import Select from 'react-select';


import { SERVER_ } from  '../../config';
import AuthService from '../../features/auth/auth.service'


import ModalNuevaPublicacion from './ModalNuevaPublicacion'
import ModalEditarPublicacion from './ModalEditarPublicacion'


import ModalDetallesPublicacion from './ModalDetallesPublicacion'


import ModalEnviarRectificarPublicacion from './ModalEnviarRectificarPublicacion'
import ModalRectificarPublicacion from './ModalRectificarPublicacion'
import ModalArchivarPublicacion from './ModalArchivarPublicacion'
import ModalAnexos from './ModalAnexos'

import Dialog from '../../services/Dialogs'

import Archivos from '../Archivos/Archivos'


import {labelType} from '../Tickets/labelType'


import './mis_publicaciones.scss'


const MisPublicaciones = () =>{


  const dialog = new Dialog();


  const User : any = AuthService.getCurrentUser()


  const formateoFecha = (fecha:any) =>{ return moment(fecha).format('DD [DE] MMMM [DE] YYYY').toUpperCase() }


  React.useEffect(() => {
    actualizarFecha(startDate,endDate)
    cargarDependencias()
  }, []);




  const [ modal_editar_publicacion, setModalEditarPublicacion ] = useState<boolean>(false)
  const [ publicacion_seleccionada, setPublicacionSeleccionada ] = useState<string>("")

  const handleShowEditar = async (uuid_publicacion:string) => {
    try{
      let res = await RequestService.getEndPoint('publicacion/ficha/'+uuid_publicacion)
      setPublicacionSeleccionada(res.data)
      setModalEditarPublicacion(true)
    }
    catch(e){
      toast.error('Error al editar')
    }
  }
  const handleCloseModalEditarPublicacion = () => {
    actualizarFecha(startDate,endDate)
    setModalEditarPublicacion(false)
  }




  const [ modal_nueva_publicacion, setModalNuevaPublicacion ] = useState<boolean>(false)

  const handleShow = () => {
    setModalNuevaPublicacion(true)
  }
  const handleCloseModalNuevaPublicacion = () => {
    actualizarFecha(startDate,endDate)
    setModalNuevaPublicacion(false)
  }


  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const onChange = (dates:any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };



  const [startDate_selected, setStartDateSelected] = useState<any>(null);
  const [endDate_selected, setEndDateSelected] = useState<any>(null);

  const [publicaciones, setPublicaciones] = useState<[]>([]);


  const actualizarFecha = async (startDate:any, endDate:any) => {
    try{
      var sD = moment(startDate).format('YYYY-MM-DD')
      var eD = moment(endDate).format('YYYY-MM-DD')
      setStartDateSelected(sD);
      setEndDateSelected(eD);
      let res = await RequestService.getEndPointPost('publicaciones/reporte/'+arreglo_estatus+'/'+sD+'/'+eD, { dependencias : etiquetas_seleccionadas })
      setPublicaciones(res.data)
      
    }
    catch(e){
      toast.error('Error')
    }

  }



  const aceptarPublicacion = async (uuid_publicacion:string, e:any) => {

    try{

        await dialog.open({
          accept: 'Si',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: e.target,
          template: `<p>La publicación pasará al estatus ACEPTADO para esperar a ser hecha</p>
          <p><small>Este cambio es irreversible</small></p>
          `
        })
        let dialogo : any = await dialog.waitForUser()
        // console.log(dialogo)
        if(dialogo){
            await RequestService.update('publicacion/aceptar/'+uuid_publicacion, {})
            actualizarFecha(startDate,endDate)
            toast.success('La publicación fue aceptada')
        }

    }
    catch(e){
      toast.error('Problemas.')
    }


  }



  const rechazarPublicacion = async (uuid_publicacion:string, e:any) => {

    try{

        await dialog.open({
          accept: 'Si',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: e.target,
          template: `<p>La publicación pasará al estatus RECHAZADA para esperar a ser hecha</p>
          <p><small>Este cambio es irreversible</small></p>
          `
        })
        let dialogo : any = await dialog.waitForUser()
        // console.log(dialogo)
        if(dialogo){
            await RequestService.update('publicacion/rechazar/'+uuid_publicacion, {})
            actualizarFecha(startDate,endDate)
            toast.success('La publicación fue aceptada')
        }

    }
    catch(e){
      toast.error('Problemas.')
    }


  }



    const [ modal_detalle_publicacion, setModalDetallePublicacion ] = useState<boolean>(false)
    const [ uuid_evento_seleccionado, setUUIDSeleccionado ] = useState<string>("")

    const handleShowModalDetalle = (uuid_publicacion:string ) => {
      setUUIDSeleccionado(uuid_publicacion)
      setModalDetallePublicacion(true)
    }
    const handleCloseModalDetallePublicacion = () => {
      setModalDetallePublicacion(false)
    }






  const [showModalRectifica, setShowModalRectifica] = useState(false);

  const handleClose = () =>{
    actualizarFecha(startDate,endDate)
    setShowModalRectifica(false)
  }
  const handleShowEnviarRectificacion = async (uuid_publicacion:string) =>{ 
    setUUIDSeleccionado(uuid_publicacion)
    setShowModalRectifica(true)
    // await cargarComentarios(uuid_publicacion)
  }




  const [showModalRectificaVocero, setShowModalRectificaVocero] = useState(false);

  const handleCloseVocero = () =>{
    setShowModalRectificaVocero(false)
  }
  const handleShowEnviarRectificacionVocero = async (uuid_publicacion:string) =>{ 
    setUUIDSeleccionado(uuid_publicacion)
    setShowModalRectificaVocero(true)
    // await cargarComentarios(uuid_publicacion)
  }






  const [showModalArchivar, setShowModalArchivar] = useState(false);

  const handleCloseArchivar = () =>{
    setShowModalArchivar(false)
  }
  const handleShowArchivar = async (uuid_publicacion:string) =>{ 
    setUUIDSeleccionado(uuid_publicacion)
    setShowModalArchivar(true)
    // await cargarComentarios(uuid_publicacion)
  }







  const [showModalAnexos, setShowModalAnexos] = useState(false);

  const handleCloseAnexos = () =>{
    setShowModalAnexos(false)
  }
  const handleShowAnexos = async (uuid_publicacion:string) =>{ 
    setUUIDSeleccionado(uuid_publicacion)
    setShowModalAnexos(true)
  }





  const [ arreglo_estatus, setEstatus ] = useState<any>([true,true,true,true,true])



  const todos = () =>{
    setEstatus([true,true,true,true,true])
  }
  const ninguno = () =>{
    setEstatus([false,false,false,false,false])
  }





const defaultValue : labelType[] = []
  const [ etiquetas_seleccionadas, setEtiquetasSeleccionada ] = useState<any>([])


  const [ dependencias, setDependencias ] = useState<any>([])
  const cargarDependencias = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('dependencias')
      setDependencias(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar dependencias')
    }
  }



  const eliminar =async (idevento:string)=>{
    try{
      if(await dialog.confirm('¿Estás seguro?',{},'El Evento se eliminará permanentemente y ningún usuario podrá verlo')){
        let res = await RequestService.delete('publicacion/'+idevento)
        if(res.data){
          toast.warning('El Evento se elimino correctamente')
          actualizarFecha(startDate,endDate)
        }
        else{ toast.error('Error al eliminar Evento') }
      }
    }
    catch(e){
      toast('Error al intentar eliminar el Evento')
    }
  }





  const [ show_Archivos, setArchivos ] = useState<boolean>(false)
  const handleShow_Archivos = (registro:any) => {
    setPublicacionSeleccionada(registro)
    setArchivos(true)
  }
  const handleClose_Archivos = () => {
    setArchivos(false)
  }




  // const actualizarFecha = async (startDate:any, endDate:any) => {
  //   try{
  //     var sD = moment(startDate).format('YYYY-MM-DD')
  //     var eD = moment(endDate).format('YYYY-MM-DD')
  //     setStartDateSelected(sD);
  //     setEndDateSelected(eD);
  //     let res = await RequestService.getEndPointPost('reporte_publicaciones/'+arreglo_estatus+'/'+sD+'/'+eD, { dependencias : etiquetas_seleccionadas })
  //     // setPublicaciones(res.data)
      
  //   }
  //   catch(e){
  //     toast.error('Error')
  //   }

  // }


    const [ generando_reporte, setGenerandoReporte ] = useState<boolean>(false)

  const abrirReporteExcel = async () => {

    try{

        var sD = moment(startDate).format('YYYY-MM-DD')
        var eD = moment(endDate).format('YYYY-MM-DD')

        setGenerandoReporte(true)
            let res = await RequestService.getEndPointPostExcel(`reporte_publicaciones/${arreglo_estatus}/${sD}/${eD}`, { dependencias : etiquetas_seleccionadas })
        // console.log(res)
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Reporte Publicaciones `+moment().format('YYYY_MM_DD_HH_mm')+`.xlsx`);
        document.body.appendChild(link);
        link.click();
        setGenerandoReporte(false)
      }
      catch(e){
        setGenerandoReporte(false)
      }

  }

  const abrirReportePDF = async () => {

    try{

        var sD = moment(startDate).format('YYYY-MM-DD')
        var eD = moment(endDate).format('YYYY-MM-DD')

        setGenerandoReporte(true)
            let res = await RequestService.getEndPointPostExcel(`reporte_publicaciones_pdf/${arreglo_estatus}/${sD}/${eD}`, { dependencias : etiquetas_seleccionadas })
        // console.log(res)
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Reporte Publicaciones `+moment().format('YYYY_MM_DD_HH_mm')+`.pdf`);
        document.body.appendChild(link);
        link.click();
        setGenerandoReporte(false)
      }
      catch(e){
        setGenerandoReporte(false)
      }

  }

      return (
          <>

              <div className="container-fluid contenedor">
                  


                  <h3 className="mt-3 mb-3">Publicaciones</h3>


                  

                  <div className="row">
                    <div className="col-md-2">


                        <div className="text-center">
                          

                          <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            inline
                          />

                          <button disabled={!endDate} onClick={()=>{actualizarFecha(startDate,endDate)}} className="btn btn-xs btn-default">Actualizar la lista <i className="fa fa-refresh"/></button>

                        </div>




                          <Form.Label>Estatus</Form.Label>

                          <ul className="list-group list-group-flush mt-4">
                            <li className="list-group-item list-group-item-action" onClick={handleShow}><button type="button" className="btn btn-link">Nuevo Registro</button></li>
                            {
                              // <li className="list-group-item list-group-item-action"><a rel="noreferrer" target="_blank" href={SERVER_+'reporte/caratulas/'+startDate_selected+'/'+endDate_selected+'/'+arreglo_estatus} > <i className="fa fa-file-excel-o"/></a> Carátulas</li>
                            }
                            {
                              // <li className="list-group-item list-group-item-action"><a rel="noreferrer" target="_blank" href={SERVER_+'reporte/listado/'+startDate_selected+'/'+endDate_selected+'/'+arreglo_estatus} > <i className="fa fa-file-excel-o"/></a> Reporte</li>
                            }
                          <li className="list-group-item"><a onClick={abrirReporteExcel} className="btn btn-link"><i className="fa fa-file-excel-o"/> Reporte</a></li>
                          <li className="list-group-item"><a onClick={abrirReportePDF} className="btn btn-link"><i className="fa fa-file-pdf-o"/> Reporte</a></li>

                          </ul>



                    </div>
                    <div className="col-md-10">

                    {
                      // JSON.stringify(User)
                    }




                                  {
                                    startDate_selected === endDate_selected && (
                                        <h4>PUBLICACIONES DEL DÍA {moment(startDate_selected).format('DD [DE] MMMM [DEL] YYYY').toUpperCase()}</h4>
                                      )
                                  }
                              

                                  {
                                    startDate_selected !== endDate_selected && (
                                        <h4>PUBLICACIONES ENTRE EL DÍA {moment(startDate_selected).format('DD [DE] MMMM [DEL] YYYY').toUpperCase()} Y EL DÍA {moment(endDate_selected).format('DD [DE] MMMM [DEL] YYYY').toUpperCase()}</h4>
                                      )
                                  }








                                    <div className="row">
                                      <div className="col-6">
                                        

                                      <div key={`inline-checkbox`} className="">
                                        <Form.Label>Estatus</Form.Label>

                                      {
                                        // JSON.stringify(arreglo_estatus)
                                      }

                                        <Form.Check
                                          inline
                                          name="group1"
                                          type="checkbox"
                                          label="REVISIÓN"
                                          checked={arreglo_estatus[0]}
                                          onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}}
                                          id={`opciones_1`}
                                        />

                                        <Form.Check
                                          inline
                                          name="group2"
                                          type="checkbox"
                                          label="MODIFICACIÓN"
                                          checked={arreglo_estatus[1]}
                                          onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[1] = e.target.checked;  setEstatus(ne);}}
                                          id={`opciones_2`}
                                        />

                                        <Form.Check
                                          inline
                                          name="group3"
                                          type="checkbox"
                                          label="ACEPTADO"
                                          checked={arreglo_estatus[2]}
                                          onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[2] = e.target.checked;  setEstatus(ne);}}
                                          id={`opciones_3`}
                                        />

                                        <Form.Check
                                          inline
                                          name="group4"
                                          type="checkbox"
                                          label="PUBLICADO"
                                          checked={arreglo_estatus[3]}
                                          onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[3] = e.target.checked;  setEstatus(ne);}}
                                          id={`opciones_4`}
                                        />

                                        <Form.Check
                                          inline
                                          name="group5"
                                          type="checkbox"
                                          label="RECHAZADO"
                                          checked={arreglo_estatus[4]}
                                          onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[4] = e.target.checked;  setEstatus(ne);}}
                                          id={`opciones_5`}
                                        />

                                      </div>

                                    <ul className="list-inline">
                                      <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
                                    </ul>

                                      </div>
                                      <div className="col-6">

                                            {
                                              User.role_id === 0 && (

                                                  <Form.Group controlId="ticket_type_id" className="mb-3"  >
                                                    <Form.Label>Dependencias</Form.Label>

                                                          <Select
                                                            isMulti
                                                            defaultValue={defaultValue}
                                                            onChange={(opt:any,e:any)=>{ setEtiquetasSeleccionada(opt) }}
                                                            isClearable={true}
                                                            name="colors"
                                                            options={dependencias}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                          />
                                                  </Form.Group>

                                              )
                                            }


                                      </div>
                                    </div>



                          {
                            // JSON.stringify(publicaciones)
                          }


                          {
                            publicaciones.length === 0 && (

                                <React.Fragment>
                                    
                                    <div className="alert alert-primary d-flex align-items-center" role="alert">
                                        <i className="fa fa-warning" />  No hay publicaciones para esa fecha
                                    </div>

                                </React.Fragment>

                              )
                          }
                          {
                            publicaciones.length > 0 && (

                                <React.Fragment>

                      {
                        // display dataTable no-footer 
                      }
                                      <table className="table mb-3" style={{fontSize:12,padding:0}}>
                                        <thead>
                                          <tr>
                                            <th>Detalles de la Publicación</th>
                                            {
                                              User.rol === "ROLE_ADMIN" && (
                                                  <React.Fragment>
                                                    <th style={{width:180}}>Vocero</th>
                                                    <th style={{width:80}}>Dependencia</th>
                                                  </React.Fragment>
                                              )
                                            }
                                            <th style={{width:120}}>Opciones</th>
                                            <th style={{width:120}}>Estatus</th>
                                            <th style={{width:120}}>Tipo</th>
                                            <th  >Publicación / Creación</th>

                                            <th style={{width:10}}><i className="fa fa-facebook"/></th>
                                            <th style={{width:10}}><i className="fa fa-twitter"/></th>
                                            <th style={{width:10}}><i className="fa fa-instagram"/></th>

                                            <th></th>
                                            <th></th>
                                            <th></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                        {
                                          publicaciones.map( (publicacion:any) =>

                                              <tr key={ Math.random().toString(36).substr(2, 9) }>
                                                <td className="v" style={{width : '300px'}}>
                                                  <p onClick={()=>{handleShowModalDetalle(publicacion.uuid_publicacion)}} className="nm pointer link-primary" ><small>{publicacion.publicacion}</small></p>
                                                </td>
                                                {
                                                  User.rol === "ROLE_ADMIN" && (
                                                    <React.Fragment>
                                                      <td className="v" >
                                                        <p className="nm" style={{fontSize:12}}><small>{publicacion.first_name}</small></p> 
                                                        <p className="nm" style={{fontSize:12}}><small><b>{publicacion.last_name}</b></small></p> 
                                                      </td>
                                                      <td className="v" >
                                                        <p className="nm" style={{fontSize:12}}><small>{publicacion.acronimo_dependencia}</small></p> 
                                                      </td>
                                                    </React.Fragment>
                                                  )
                                                }
                                                <td className="v text-center">
                                                   
                                                {
                                                  User.rol === "ROLE_ADMIN" && (

                                                    <React.Fragment>
                                                      
                                                      {
                                                        (publicacion.uuid_estatus === "6f86275d-2db4-47aa-8910-f1bce4d1ad64") && ( // REVISIÓN

                                                                    <Dropdown>
                                                                      <Dropdown.Toggle variant="success" size="sm" id="dropdown-basic">
                                                                        <i className="fa fa-list" />
                                                                      </Dropdown.Toggle>

                                                                      <Dropdown.Menu>
                                                                        <Dropdown.Item onClick={()=>{handleShowEnviarRectificacion(publicacion.uuid_publicacion)}}>{publicacion.estatus}</Dropdown.Item>
                                                                        <Dropdown.Item onClick={(e)=>{aceptarPublicacion(publicacion.uuid_publicacion,e)}}>ACEPTAR</Dropdown.Item>
                                                                        <Dropdown.Item onClick={(e)=>{rechazarPublicacion(publicacion.uuid_publicacion,e)}}>RECHAZAR</Dropdown.Item>
                                                                      </Dropdown.Menu>
                                                                    </Dropdown>
                                                          
                                                        )
                                                      }             


                                                      {
                                                        (publicacion.uuid_estatus === "2f55ae20-9b9a-44b4-90ef-63ca9a0f11da") && ( // EN ATENCIÓN
                                                          
                                                                  <p className="nm" style={{fontSize:12}}><small>En Corrección</small></p>
                                                          
                                                        )
                                                      }

                                                                                   
                                                      {
                                                        (publicacion.uuid_estatus === "853f9010-32b0-4c00-883d-b234bce9a4c9") && ( // ACEPTADO
                                                          
                                                                  <p onClick={()=>{handleShowArchivar(publicacion.uuid_publicacion)}} style={{fontSize:12}} className="nm pointer"><small>Archivar</small></p>
                                                          
                                                        )
                                                      }




                                                      {
                                                        (publicacion.uuid_estatus === "b2e10b33-7d8a-4cff-8c98-3502afd3cd2d") && ( // PUBLICADA
                                                          
                                                                  <p className="nm pointer"><a rel="noreferrer" target="_blank" href={publicacion.url_publicacion} style={{fontSize:12}}><small>VER PUBLICACIÓN</small></a></p>
                                                          
                                                        )
                                                      }

                                                    </React.Fragment>


                                                    
                                                  )
                                                }
                                                {
                                                  User.rol !== "ROLE_ADMIN" && ( // VOCERO
                                                    
                                                    <React.Fragment>
                                                      
                                                      {
                                                        (publicacion.uuid_estatus === "6f86275d-2db4-47aa-8910-f1bce4d1ad64") && ( // REVISIÓN
                                                            <p  className="nm pointer" style={{fontSize:12}}><small><i className="fa fa-clock-o" /></small></p>
                                                          
                                                        )
                                                      }

                                                      {
                                                        (publicacion.uuid_estatus === "2f55ae20-9b9a-44b4-90ef-63ca9a0f11da") && ( // EN ATENCIÓN
                                                          
                                                                  <p onClick={()=>{handleShowEnviarRectificacionVocero(publicacion.uuid_publicacion)}} className="nm pointer" style={{fontSize:12}}><small>ATENDER <i className="fa fa-warning" /></small></p>
                                                          
                                                        )
                                                      }


                                                      {
                                                        (publicacion.uuid_estatus === "b2e10b33-7d8a-4cff-8c98-3502afd3cd2d") && ( // PUBLICADA
                                                          
                                                                  <p className="nm pointer"><a rel="noreferrer" target="_blank" href={publicacion.url_publicacion}><small>VER PUBLICACIÓN</small></a></p>
                                                          
                                                        )
                                                      }



                                                    </React.Fragment>

                                                  )
                                                }
                                                </td>
                                                <td className="v">
                                                   <p className="nm"><small>{publicacion.estatus}</small></p>
                                                </td>
                                                <td className="v">
                                                   <p className="nm"><small>{publicacion.tipo_publicacion}</small></p>
                                                </td>
                                                <td className="v"style={{width : 250}}>
                                                  <p className="nm"><b>{formateoFecha(publicacion.fecha_inicio)}</b></p>
                                                  <p className="nm"><small>{formateoFecha(publicacion.fecha_creacion)}</small></p>
                                                </td>
                                                <td className="v" style={{width : '60px'}}>
                                                  {
                                                    (publicacion.fb_post || publicacion.fb_video || publicacion.fb_historia) && (<i className="fa fa-check-circle" />)
                                                  }
                                                </td>
                                                <td className="v" style={{width : '60px'}}>
                                                    {
                                                      (publicacion.tw_post || publicacion.tw_video) && (<i className="fa fa-check-circle" />)
                                                    }
                                                </td>
                                                <td className="v" style={{width : '60px'}}>
                                                    {
                                                      (publicacion.ig_post || publicacion.ig_historia || publicacion.ig_reel) && (<i className="fa fa-check-circle" />)
                                                    }
                                                </td>
                                                <td className="v" style={{width:30}}>
                                                      {
                                                        (publicacion.uuid_estatus !== "b2e10b33-7d8a-4cff-8c98-3502afd3cd2d") && ( // PUBLICADA
                                                          
                                                                  <button className="btn btn-warning btn-xs" onClick={()=>{handleShowEditar(publicacion.uuid_publicacion)}}><i className="fa fa-edit"/></button>
                                                          
                                                        )
                                                      }
                                                  
                                                </td>
                                                <td className="v" style={{width:30}}>
                                                      {
                                                        (publicacion.uuid_estatus !== "b2e10b33-7d8a-4cff-8c98-3502afd3cd2d") && ( // PUBLICADA
                                                          
                                                                  <button className="btn btn-danger btn-xs" onClick={()=>{eliminar(publicacion.uuid_publicacion)}}><i className="fa fa-trash"/></button>
                                                          
                                                        )
                                                      }
                                                  
                                                </td>
                                                    <td className="v" style={{width:30}}>
                                                      <button type="button" onClick={(e)=>{handleShow_Archivos(publicacion)}} className="btn btn-xs btn-primary"><i className="fa fa-cloud" /></button>
                                                    </td>
                                              </tr>

                                            )
                                        }

                                        </tbody>
                                      </table>


                                </React.Fragment>

                              )
                          }






                    </div>
                  </div>
            </div>
              
                      {
                        modal_nueva_publicacion && (
                          <ModalNuevaPublicacion show={modal_nueva_publicacion} handleClose={handleCloseModalNuevaPublicacion}  />
                          )

                      }
              
                      {
                        modal_editar_publicacion && (
                          <ModalEditarPublicacion show={modal_editar_publicacion} handleClose={handleCloseModalEditarPublicacion} publicacion={publicacion_seleccionada} />
                          )

                      }


              {
                modal_detalle_publicacion && (
                  <ModalDetallesPublicacion show={modal_detalle_publicacion} handleClose={handleCloseModalDetallePublicacion} uuid_evento={uuid_evento_seleccionado} />
                  )

              }

              {
                showModalRectifica && (
                  <ModalEnviarRectificarPublicacion show={showModalRectifica} handleClose={handleClose} uuid_publicacion={uuid_evento_seleccionado} />
                  )

              }

              {
                showModalRectificaVocero && (
                  <ModalRectificarPublicacion show={showModalRectificaVocero} handleClose={handleCloseVocero} uuid_publicacion={uuid_evento_seleccionado} />
                  )

              }
              {
                showModalArchivar && (
                  <ModalArchivarPublicacion show={showModalArchivar} handleClose={handleCloseArchivar} uuid_publicacion={uuid_evento_seleccionado} />
                  )

              }
              {
                showModalAnexos && (
                  <ModalAnexos show={showModalAnexos} handleClose={handleCloseAnexos} uuid_publicacion={uuid_evento_seleccionado} />
                  )

              }




  {
    show_Archivos && (
      <Archivos show={show_Archivos} handleClose={handleClose_Archivos} tipo_archivo="parrilla" registro={publicacion_seleccionada} />
      )

  }




          </>

      );
  

}

export default MisPublicaciones
