import React, { useState } from 'react';

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import moment from 'moment'

import { puntajeCalificacionEtiqueta, calcularTiempoTranscurrido } from '../../services/funcionesPublicas'



const ModalDetallesBoletin = ( params : any ) => {

  const { show, handleClose, boletin } = params

  return (
    <>
      <Modal show={show} dialogClassName="modal-lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Detalles del Boletín</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <dl>
            <dt>Título</dt>
            <dd>
              <p>{boletin.titulo}</p>
            </dd>

            <dt>Bullets</dt>
            <dd>
              <p>{boletin.bullets||'Sin Bullets'}</p>
            </dd>

            <dt>Boletín</dt>
            <dd>
              <p>{boletin.boletin}</p>
            </dd>

            <dt>Estatus</dt>
            <dd>
              <p className="nm">{boletin.estatus.toUpperCase()}</p>

            </dd>
            {
              boletin.estatus == 'rechazado' && (

                <>
                  <dt>Detalles del Rechazo</dt>
                  <dd>
                    <p className="nm">{ boletin.detalles_rechazado && (boletin.detalles_rechazado.toUpperCase() )}</p>
                  </dd>
                </>

              )
            }
            
            {
              boletin.calificacion > 0 && (
                    <p className="nm"><>{puntajeCalificacionEtiqueta(boletin.calificacion)}</></p>
                )
            }

            <dt>Fecha del Boletín</dt>
            <dd>
              <p>{moment(boletin.fecha).format('DD [DE] MMMM [DE] YYYY').toUpperCase()}</p>
            </dd>


            <dt>Fecha de Creación</dt>
            <dd>
                <p className="nm">{ moment(boletin.fecha_creacion).format('YYYY-MM-DD HH:mm') }</p>
                <small>{calcularTiempoTranscurrido(boletin.fecha_creacion)}</small>
            </dd>


            <dt>Link de Imágenes</dt>
            <dd>
              <p>{ boletin.url_imagenes && ( <a target="_blank" href={boletin.url_imagenes} >{boletin.url_imagenes}</a> ) }</p>
              <p>{ !boletin.url_imagenes && ( 'No proporcionado' ) }</p>
            </dd>


            <dt>Link de Audio</dt>
            <dd>
              <p>{ boletin.url_audio && ( <a target="_blank" href={boletin.url_audio} >{boletin.url_audio}</a> ) }</p>
              <p>{ !boletin.url_audio && ( 'No proporcionado' ) }</p>
            </dd>


            <dt>Link de Vídeo</dt>
            <dd>
              <p>{ boletin.url_video && ( <a target="_blank" href={boletin.url_video} >{boletin.url_video}</a> ) }</p>
              <p>{ !boletin.url_video && ( 'No proporcionado' ) }</p>
            </dd>

            
          </dl>

          {
            // JSON.stringify(boletin)
          }

        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalDetallesBoletin;