import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'


import {Button, Modal, Form, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import { toast } from 'react-toastify'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import './date_format.scss'

import Dialog from '../../services/Dialogs'

const ModalNuevaPublicacion = ( params : any ) => {


  const user = AuthService.getCurrentUser()

  const mensajes_validacion_generico = "Este campo es obligatorio"

  const dialog = new Dialog();

  const { show, handleClose } = params

  // const [ redes_sociales, setRedes ] = useState<any>([])

  // const cargarListas = async () =>{
  //   // cargarEtiquetas
  //   try{
  //       let redes = await RequestService.getEndPoint("redes_sociales")
  //       if(redes.data) setRedes(redes.data)
  //       // let estatus = await RequestService.getEndPoint("estatus")
  //       // if(estatus.data) setEstatus(estatus.data)

  //   }
  //   catch(e){
  //     toast.error('Problemas cargando las etiquetas')
  //   }
  // }


  React.useEffect(() => {
    // cargarListas()
  }, []);





const schema = yup.object().shape({
  publicacion: yup.string().required(mensajes_validacion_generico),
  descripcion: yup.string().required(mensajes_validacion_generico),
  // idred_social: yup.string().required(),
  fb_post : yup.boolean(), 
  fb_video : yup.boolean(), 
  fb_historia : yup.boolean(), 
  tw_post : yup.boolean(), 
  tw_video : yup.boolean(), 
  ig_post : yup.boolean(), 
  ig_historia : yup.boolean(), 
  ig_reel : yup.boolean(), 

  idtipo_publicacion: yup.string().required(mensajes_validacion_generico),
  fecha_inicio: yup.string().required(mensajes_validacion_generico  ).nullable(),
  fecha_fin: yup.string().nullable(),
  idusuario: yup.number(),
});





  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Nueva Publicación</Modal.Title>
        </Modal.Header>
          {
            // JSON.stringify(estatus)
          }
          {
            // JSON.stringify(redes_sociales)
          }

    <Formik
      validationSchema={schema}
      onSubmit={async(data)=>{
        // console.log('?')
        // console.log(data)
          if(await dialog.confirm('¿Estás seguro?',{})){
            let res = await RequestService.create('nueva_publicacion', { ...data })
            if(!res.data) toast.error('error al crear')
            if(res.data){
              toast.success('El Registro para la publiación se creo correctamente')
              handleClose()
            }
          }

      }}
      initialValues={{
        publicacion: '',
        descripcion: '',

        fb_post : false,
        fb_video : false,
        fb_historia : false,
        tw_post : false,
        tw_video : false,
        ig_post : false,
        ig_historia : false,
        ig_reel : false,


        idtipo_publicacion : "",
        fecha_inicio : null,
        fecha_fin : null,
        idusuario : user._id
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        setFieldValue,
        touched,
        isValid,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>


        <Modal.Body>

        {
          // JSON.stringify(user)
        }


                    <Form.Group
                      controlId="title"
                      className="mb-3"
                    >
                      <Form.Label>Publicación</Form.Label>
                      <Form.Control
                        type="text"
                        name="publicacion"
                        value={values.publicacion}
                        onChange={(e:any)=>{setFieldValue('publicacion',e.target.value )}}
                        isValid={touched.publicacion && !errors.publicacion}
                        isInvalid={!!errors.publicacion}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.publicacion}</Form.Control.Feedback>
                    </Form.Group>



            <Form.Group as={Col}   controlId="idtipo_publicacion">
              <Form.Label>Categoría de la Publicación</Form.Label>
                  <Form.Select name="idtipo_publicacion" value={values.idtipo_publicacion} onChange={handleChange} isValid={touched.idtipo_publicacion && !errors.idtipo_publicacion} isInvalid={!!errors.idtipo_publicacion} >
                    <option disabled={true} value="">Selecciona una categoría</option>
                    <option value="b8698aa4-bd1d-4663-bf24-7ef33f99062b">CAMPAÑA</option>
                    <option value="cb27dfa8-0ecc-4c3f-be9f-cf72cf33df46">PUBLICACIÓN</option>
                  </Form.Select>
              <Form.Control.Feedback type="invalid">
                Debes seleccionar un categoría de públicación
              </Form.Control.Feedback>
            </Form.Group>


            <div className="mt-3">
              <Form.Label>Tipo de Publicación</Form.Label>
                <Form.Group className="mb-3">
                  <Form.Check
                    name="fb_post"
                    label="FACEBOOK POST"
                    onChange={handleChange}
                    id="fb_post"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check
                    name="fb_video"
                    label="FACEBOOK VIDEO"
                    onChange={handleChange}
                    id="fb_video"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check
                    name="fb_historia"
                    label="FACEBOOK HISTORY"
                    onChange={handleChange}
                    id="fb_historia"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check
                    name="tw_post"
                    label="TWITTER POST"
                    onChange={handleChange}
                    id="tw_post"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check
                    name="tw_video"
                    label="TWITTER VIDEO"
                    onChange={handleChange}
                    id="tw_video"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check
                    name="ig_post"
                    label="INSTAGRAM POST"
                    onChange={handleChange}
                    id="ig_post"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check
                    name="ig_historia"
                    label="INSTAGRAM REEL"
                    onChange={handleChange}
                    id="ig_historia"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check
                    name="ig_reel"
                    label="INSTAGRAM HISTORY"
                    onChange={handleChange}
                    id="ig_reel"
                  />
                </Form.Group>

            </div>



                    <Form.Group className="mb-3" >

                      <Form.Label>Fecha Publicación</Form.Label>
                      <Form.Control
                        type="hidden"
                        name="fecha_inicio"
                        onChange={handleChange}
                        isValid={touched.fecha_inicio && !errors.fecha_inicio}
                        isInvalid={!!errors.fecha_inicio}
                      />
                      <DatePicker className={`form-control ${ !!errors.fecha_inicio ? 'is-invalid' : '' } ${ !errors.fecha_inicio && values.fecha_inicio ? 'is-valid' : '' }`} selected={values.fecha_inicio} onChange={e => setFieldValue('fecha_inicio', e)} dateFormat="yyyy-MM-dd"  />
                      <Form.Control.Feedback type="invalid">{errors.fecha_inicio}</Form.Control.Feedback>
                    </Form.Group>






            <Form.Group as={Col} className="mt-2" controlId="descripcion">
              <Form.Label>Detalles de la publicación</Form.Label>
              <Form.Control as="textarea" name="descripcion" value={values.descripcion} rows={6} onChange={e =>{ setFieldValue('descripcion', e.target.value );}} isInvalid={!!errors.descripcion} isValid={touched.descripcion && !errors.descripcion} />
          <Form.Control.Feedback type="invalid">
            Debes de agregar una descripción
          </Form.Control.Feedback>
            </Form.Group>





{
                              // <div className="mb-3 mt-2">
                              //   <label htmlFor="formFile" className="form-label">Anexos de la noticia</label>
                              //   <input type="file" name="file" accept="image/jpeg,image/jpg,image/png,video/mp4,audio/mp3,audio/ogg" multiple={false} onChange={handleFileInputChange} />
                              //   <p className="nm"><small className="form-text text-muted">El limite de tamaño del archivo anexo no debe pasar los 20 mb</small></p>
                              // </div>
}

                             {
                              //archivos_anexos.length > 0 && (
                                 // <div className="row">
                                 //      {archivos_anexos.map( (reg:any, index : number) =>




                                 //                <div className="col-4" key={ Math.random().toString(36).substr(2, 9) } >
                                                


                                 //                    { archivos_anexos_tipo_archivo[index] === "image" && ( 
                                 //                      <>
                                 //                        <img src={reg} className="rounded float-left" alt="..." width="100%" />
                                 //                      </>
                                 //                      )}


                                 //                    { archivos_anexos_tipo_archivo[index] === "video" && ( 
                                 //                      <>
                                 //                            <video controls style={{"width": "100%"}}>
                                 //                                <source type="video/webm" src={reg} />
                                 //                                <source type="video/mp4" src={reg} />
                                 //                            </video>
                                 //                      </>
                                 //                      )}


                                 //                    { archivos_anexos_tipo_archivo[index] === "audio" && ( 
                                 //                      <>
                                 //                            <audio controls style={{"width": "100%"}}>
                                 //                                <source type="audio/ogg" src={reg} />
                                 //                                <source type="audio/mpeg" src={reg} />
                                 //                            </audio>
                                 //                      </>
                                 //                      )}




                                                    
                                 //                    <p className="nm text-center">
                                 //                      <button type="button" onClick={()=>{quitarImagen(index)}} className="btn btn-link"><small>Quitar <i className="fa fa-trash" /></small></button>
                                 //                    </p>
                                 //                </div>






                                              
                                 //      )}
                                 // </div>
                             // )
                           }








        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success"   type="submit">Crear</Button>
        </Modal.Footer>


        </Form>
      )}
    </Formik>




      </Modal>
    </>
  );
}


export default ModalNuevaPublicacion