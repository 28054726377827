import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal, Form } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { Formik } from 'formik';
import * as yup from 'yup';

import Select from 'react-select';


import AuthService from "../../features/auth/auth.service";



import Dialog from '../../services/Dialogs'

import {labelType} from './labelType'


const ModalEditarTicket = ( params : any ) => {

  const { show, handleClose, ticket } = params

    const currentUser  = AuthService.getCurrentUser();

  const dialog = new Dialog();


const schema = yup.object().shape({
  title : yup.string().required('Este campo es obligatorio'),
  project_id : yup.string().nullable(),
  assigned_to : yup.string().nullable(),
  status : yup.string(),
  ticket_type_id : yup.string().required('Este campo es obligatorio'),
  entregado : yup.boolean(),
});



  const [ proyectos, setProyectos ] = useState<any>([])
  const cargarProyectos = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('proyectos')
      setProyectos(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar proyectos')
    }
  }

  const [ disenadores, setDisenadores ] = useState<any>([])
  const cargarDisenadores = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('tickets/lista_disenadores')
      setDisenadores(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar los diseñadores')
    }
  }

  // const [ proyectos, setProyectos ] = useState<any>([])
  const cargarEtiquetas = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('todas_las_etiquetas')
      setEtiquetas(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar proyectos')
    }
  }

  React.useEffect(() => {
    cargarProyectos()
    cargarEtiquetas()
    cargarDisenadores()
  }, []);



const [colourOptions, setEtiquetas ] = useState<labelType[]>([]);


const defaultValue : labelType[] = ticket.labels.map( (label:any) =>( { value: label.id, label: label.title, color: label.color } ))
  // ,

  const [ etiquetas_seleccionadas, setEtiquetasSeleccionada ] = useState<any>( defaultValue )


const DEFAULT_PROYECTO = ticket.project_id ? [{value:ticket.project_id.id,label:ticket.project_id.title}] : []


  return (
    <>
      <Modal show={show} dialogClassName="modal-lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Editar Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(ticket)
          }



            <Formik
              validationSchema={schema}
              onSubmit={async(values:any)=>{
              
                        try{

                              if(await dialog.confirm('¿Estás seguro?',{})){
                                  await RequestService.update('ticket/editar/'+ticket.id, { ...values, labels : etiquetas_seleccionadas.map((label:any)=>label.value).join(',')} )
                                  toast.success('El Ticket se creo correctamente')
                                  handleClose()
                              }
              
                        }
                        catch(e){
                          toast.error('Error al dar de alta')
                        }
                        // console.log({ ...values })
                      }}
              initialValues={{

                title : ticket.title, 
                project_id : ticket.project_id ? ticket.project_id.id : "",
                assigned_to : ticket.assigned_to ? ticket.assigned_to.id : "",
                ticket_type_id : ticket.ticket_type_id,
                status : ticket.status,
                entregado : ticket.entregado,


              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form id='my-form' noValidate onSubmit={handleSubmit}>


{
  // JSON.stringify(values)
}


                    <Form.Group
                      controlId="title"
                      className="mb-3"
                    >
                      <Form.Label>Título</Form.Label>
                      <Form.Control
                        type="text"
                        name="title"
                        as="textarea"
                        rows={4}
                        value={values.title}
                        onChange={handleChange}
                        isValid={touched.title && !errors.title}
                        isInvalid={!!errors.title}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                    </Form.Group>


                    <Form.Group
                      controlId="ticket_type_id"
                      className="mb-3">
                      <Form.Label>Tipo de Ticket</Form.Label>
                      <Form.Select aria-label="Default select example" name="ticket_type_id" value={values.ticket_type_id} onChange={handleChange} isValid={touched.project_id && !errors.project_id} isInvalid={!!errors.project_id}>
                        <option value="" disabled={true}>Selecciona una opción</option>
                        <option value="1">Solicitud de imagen</option>
                        <option value="2">Solicitud de Lona</option>
                        <option value="3">Solicitud de Campaña</option>
                        <option value="4">Solicitud de Video</option>
                        <option value="5">Solicitud de Diseño</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">{errors.ticket_type_id}</Form.Control.Feedback>
                    </Form.Group>






{
 ['ROLE_ADMIN'].indexOf(currentUser.rol) > -1 && (

  <>
  {
    // JSON.stringify(disenadores)
  }


                    <Form.Group
                      controlId="assigned_to"
                      className="mb-3">
                      <Form.Label>Asigando a</Form.Label>
                      <Form.Select aria-label="Default select example" name="assigned_to" value={values.assigned_to} onChange={handleChange} isValid={touched.project_id && !errors.project_id} isInvalid={!!errors.project_id}>
                        <option value="" disabled={false}>Selecciona una opción</option>
                        {
                          disenadores.map((disenador:any)=>
                              <option key={ Math.random().toString(36).substr(2, 9) } value={disenador.id}>{disenador.first_name}</option>
                            )
                        }
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">{errors.assigned_to}</Form.Control.Feedback>
                    </Form.Group>


                    <div key={`inline-radio`} className="mb-3">
                    
                        <Form.Group
                          controlId="price"
                          className="mb-3"
                        >
                          <Form.Label>Estatus</Form.Label>

                            <Form.Check
                              inline
                              label="Nuevo"
                              value="new"
                              name="status"
                              checked={values.status=='new'}
                              onChange={handleChange}
                              type={'radio'}
                              id={`new`}
                            isValid={touched.status && !errors.status}
                            isInvalid={!!errors.status}
                            />
                            <Form.Check
                              inline
                              label="En Proceso"
                              value="client_replied"
                              name="status"
                              checked={values.status=='client_replied'}
                              onChange={handleChange}
                              type={'radio'}
                              id={`client_replied`}
                            isValid={touched.status && !errors.status}
                            isInvalid={!!errors.status}
                            />
                        {    
                                                    // <Form.Check
                                                    //   inline
                                                    //   label="Abierto"
                                                    //   value="open"
                                                    //   name="status"
                                                    //   checked={values.status=='open'}
                                                    //   onChange={handleChange}
                                                    //   type={'radio'}
                                                    //   id={`open`}
                                                    // isValid={touched.status && !errors.status}
                                                    // isInvalid={!!errors.status}
                                                    // />
                        }
                            <Form.Check
                              inline
                              label="Cerrado"
                              value="closed"
                              name="status"
                              checked={values.status=='closed'}
                              onChange={handleChange}
                              type={'radio'}
                              id={`closed`}
                            isValid={touched.status && !errors.status}
                            isInvalid={!!errors.status}
                            />
                          <Form.Control.Feedback type="invalid">{errors.status}</Form.Control.Feedback>
                        </Form.Group>

                    </div>




<div className="mb-3">
  <label className="form-label">Entregado</label>
  <div className="form-check">
    <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={values.entregado} onChange={(e:any)=>{setFieldValue('entregado',e.target.checked)}} />
    <label className="form-check-label" htmlFor="flexCheckDefault">
      Trabajo entregado
    </label>
  </div>
</div>




  </>
  )
}




{
  // JSON.stringify(etiquetas_seleccionadas)
}

                    <Form.Group
                      controlId="ticket_type_id"
                      className="mb-3"
                    >

                      <Form.Label>Etiquetas</Form.Label>

                            <Select
                              isMulti
                              defaultValue={defaultValue}
                              onChange={(opt:any,e:any)=>{ setEtiquetasSeleccionada(opt) }}
                              isClearable={true}
                              name="colors"
                              options={colourOptions}
                              className="basic-multi-select"
                              classNamePrefix="select"
                            />
                    </Form.Group>



                </Form>
              )}
            </Formik>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalEditarTicket;