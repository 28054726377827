import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import './comentarios.scss'


import Dialog from '../../services/Dialogs'

import AuthService from '../../features/auth/auth.service'

import moment from 'moment'

const ModalComentariosTicket = ( params : any ) => {


  const { show, handleClose, ticket } = params

const dialog = new Dialog();


  const user = AuthService.getCurrentUser()



const [ mensaje, setMensaje ] = useState<string>('')
const [ envio_server, setSeEnvio ] = useState<boolean>(false)
const enviarFormulario = async (event:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.create( `ticket/agregar_mensaje/${ticket.id}` , { mensaje : mensaje })

        if(res_){
          toast.success('Consulta_correcta')
          setTimeout(()=>{
            setMensaje('')
            cargarMensajes()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}




  const [ mensajes, setMensajes ] = useState<any>([])
  const cargarMensajes = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`tickets/mensajes/${ticket.id}`)
      setMensajes(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarMensajes()
  }, []);



    








  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Comentarios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(user.id)
          }

            <div className="panel panel-primary">
                <div className="panel-body">
                    <ul className="chat">
                      {
                        mensajes.map((mensaje:any)=>

                                <li className={`clearfix ${ (mensaje.idusuario.id === user.id ? 'left' : 'right' ) }`} key={ Math.random().toString(36).substr(2, 9) }>
                                    <div className="chat-body ">
                                        <div className="header">
                                                <strong className={`primary-font ${ (mensaje.idusuario.id === user.id ? '' : 'pull-rig ht' ) }`}>{mensaje.idusuario.first_name} {mensaje.idusuario.last_name}</strong> 
                                                <small className={`text-muted ${ (mensaje.idusuario.id === user.id ? 'pull-l eft' : 'pull-r ght' ) }`} >
                                                  <span className="fa fa-time"></span> {moment(mensaje.fecha_creacion).format('YYYY-MM-DD HH:mm:ss')}
                                                </small>
                                        </div>
                                        <p>
                                        {mensaje.mensaje}
                                          {
                                            // JSON.stringify(mensaje.idusuario)
                                          }
                                        </p>
                                    </div>
                                </li>

                          )
                      }
{
                    // <ul class="chat">
                    //     <li class="left clearfix"><span class="chat-img pull-left">
                    //         <img src="http://placehold.it/50/55C1E7/fff&text=U" alt="User Avatar" class="img-circle" />
                    //     </span>
                    //         <div class="chat-body clearfix">
                    //             <div class="header">
                    //                 <strong class="primary-font">Jack Sparrow</strong> <small class="pull-right text-muted">
                    //                     <span class="glyphicon glyphicon-time"></span>12 mins ago</small>
                    //             </div>
                    //             <p>
                    //                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur bibendum ornare
                    //                 dolor, quis ullamcorper ligula sodales.
                    //             </p>
                    //         </div>
                    //     </li>
                    //     <li class="right clearfix"><span class="chat-img pull-right">
                    //         <img src="http://placehold.it/50/FA6F57/fff&text=ME" alt="User Avatar" class="img-circle" />
                    //     </span>
                    //         <div class="chat-body clearfix">
                    //             <div class="header">
                    //                 <small class=" text-muted"><span class="glyphicon glyphicon-time"></span>13 mins ago</small>
                    //                 <strong class="pull-right primary-font">Bhaumik Patel</strong>
                    //             </div>
                    //             <p>
                    //                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur bibendum ornare
                    //                 dolor, quis ullamcorper ligula sodales.
                    //             </p>
                    //         </div>
                    //     </li>
                    //     <li class="left clearfix"><span class="chat-img pull-left">
                    //         <img src="http://placehold.it/50/55C1E7/fff&text=U" alt="User Avatar" class="img-circle" />
                    //     </span>
                    //         <div class="chat-body clearfix">
                    //             <div class="header">
                    //                 <strong class="primary-font">Jack Sparrow</strong> <small class="pull-right text-muted">
                    //                     <span class="glyphicon glyphicon-time"></span>14 mins ago</small>
                    //             </div>
                    //             <p>
                    //                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur bibendum ornare
                    //                 dolor, quis ullamcorper ligula sodales.
                    //             </p>
                    //         </div>
                    //     </li>
                    //     <li class="right clearfix"><span class="chat-img pull-right">
                    //         <img src="http://placehold.it/50/FA6F57/fff&text=ME" alt="User Avatar" class="img-circle" />
                    //     </span>
                    //         <div class="chat-body clearfix">
                    //             <div class="header">
                    //                 <small class=" text-muted"><span class="glyphicon glyphicon-time"></span>15 mins ago</small>
                    //                 <strong class="pull-right primary-font">Bhaumik Patel</strong>
                    //             </div>
                    //             <p>
                    //                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur bibendum ornare
                    //                 dolor, quis ullamcorper ligula sodales.
                    //             </p>
                    //         </div>
                    //     </li>
                    // </ul>

}

                    </ul>
                </div>
                {
                  !ticket.entregado && (
                        <div className="panel-footer">
                            <div className="input-group">
                              <textarea className="form-control" rows={5} value={mensaje} onChange={(e:any)=>{setMensaje(e.target.value.toUpperCase())}}></textarea>
                              <span onClick={enviarFormulario} className="input-group-text pointer">Enviar</span>
                            </div>
                        </div>
                    )
                }

            </div>
        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalComentariosTicket;