import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'


import {Button, Modal, Form, Col, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import { toast } from 'react-toastify'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import './date_format.scss'
import './mis_publicaciones.scss'





import Dialog from '../../services/Dialogs'

import moment from 'moment'




const ModalEditarRegistroPublicacion = ( params : any ) => {

  const { show, handleClose, publicacion } = params


  const dialog = new Dialog();


  // const { show, handleClose, tipo  } = params

const [ envio_server, setSeEnvio ] = useState<boolean>(false)



  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          url : yup.string().required(mensajes_validacion_generico),
          descripcion : yup.string().required(mensajes_validacion_generico),
          fecha_publicacion : yup.string().required(mensajes_validacion_generico),

            me_gusta : yup.number(),
            me_encanta : yup.number(),
            me_importa : yup.number(),
            me_divierte : yup.number(),
            me_asombra : yup.number(),
            me_entristece : yup.number(),
            me_enoja : yup.number(),
            retweet : yup.number(),
            compartidos : yup.number(),
            comentarios : yup.number(),
            estadisticas : yup.number(),


  });

const enviarFormulario = async (registro:any,event:any) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ''
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `mi_publicacion/${publicacion.uuid}` , { ...registro  })

        if(res_){
          toast.success('La publicación se agrego correctamente')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}


  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Editar Publicación</Modal.Title>
        </Modal.Header>
        <Modal.Body>



    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

            descripcion : publicacion.descripcion,
            url : publicacion.url,
            fecha_publicacion : new Date(moment(publicacion.fecha_publicacion).toString()),


      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>




            <Form.Group   className="mb-3" controlId="url">
              <Form.Label>Descripción o Detalle de la publicación</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="descripcion"
                value={values.descripcion}
                onChange={(e)=>{ setFieldValue('descripcion', (e.target.value).toUpperCase() ) }}
                isInvalid={!!errors.descripcion}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>



            <Form.Group   className="mb-3" controlId="url">
              <Form.Label>URL Link de la Publicación</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="url"
                value={values.url}
                onChange={(e)=>{ setFieldValue('url', (e.target.value) ) }}
                isInvalid={!!errors.url}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>




            <div className="row">
              <div className="col-sm-6">
                  <div className="mb-3">
                    <Form.Label>Fecha</Form.Label>
                    <DatePicker inline selected={values.fecha_publicacion} className="form-control"  dateFormat="yyyy-MM-dd" maxDate={new Date()} onChange={(date) => setFieldValue('fecha_publicacion', date ) } />
                  </div>
              </div>
            </div>


        </Form>
      )}
    </Formik>






        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalEditarRegistroPublicacion;