import React, { useState } from "react";
import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import Pagination from "../../services/Pagination";
import { Form, ProgressBar } from 'react-bootstrap'

import Dialog from '../../services/Dialogs'

import { toast } from 'react-toastify'

import moment from 'moment'
// import 'moment/locale/es'

import Select from 'react-select';


import ModalNuevoProyecto from './ModalNuevoProyecto'
import ModalDetallesProyecto from './ModalDetallesProyecto'
import ModalEditarProyecto from './ModalEditarProyecto'

import Archivos from '../Archivos/Archivos'


import {labelType} from '../Tickets/labelType'


const Proyectos = () =>{

  const dialog = new Dialog();

  const user = AuthService.getCurrentUser()

  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  // const [ text_busqueda, setTextoBusqueda ] = useState('')
  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( true )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})


  const cargar = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*10);
      let response : any = await RequestService.getEndPointPost('proyectos/'+arreglo_estatus+'/busqueda?where='+text_busqueda+'&skip='+skip, { dependencias : etiquetas_seleccionadas })
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargar(0)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true,true,true,true])


  const todos = () =>{
    setEstatus([true,true,true,true])
  }
  const ninguno = () =>{
    setEstatus([false,false,false,false])
  }



  const eliminar =async (idproyecto:string)=>{
    try{
      if(await dialog.confirm('¿Estás seguro que deseas eliminar este Proyecto?',{})){
        let res = await RequestService.delete('proyecto/'+idproyecto)
        if(res.data){
          toast.warning('El proyecto se elimino correctamente')
          cargar(currentPage-1)
        }
        else{ toast.error('Error al eliminar proyecto') }
      }
    }
    catch(e){
      toast('Error al intentar eliminar el proyecto')
    }
  }



  const [ show_ModalNuevoProyecto, setModalNuevoProyecto ] = useState<boolean>(false)
  const handleShow_ModalNuevoProyecto = () => {
    setModalNuevoProyecto(true)
  }
  const handleClose_ModalNuevoProyecto = () => {
    setModalNuevoProyecto(false)
  }


  React.useEffect(() => {
    cargarDependencias()
    busqueda()
  },[]);




  const [ proyecto_seleccionado, setProyectoSeleccionado ] = useState<any>({})
  const [ show_ModalDetallesProyecto, setModalDetallesProyecto ] = useState<boolean>(false)
  const handleShow_ModalDetallesProyecto = (proyecto:any) => {
    setProyectoSeleccionado(proyecto)
    setModalDetallesProyecto(true)
  }
  const handleClose_ModalDetallesProyecto = () => {
    setModalDetallesProyecto(false)
  }


  const [ show_ModalEditarProyecto, setModalEditarProyecto ] = useState<boolean>(false)
  const handleShow_ModalEditarProyecto = (proyecto:any) => {
    setProyectoSeleccionado(proyecto)
    setModalEditarProyecto(true)
  }
  const handleClose_ModalEditarProyecto = () => {
          cargar(currentPage-1)
    setModalEditarProyecto(false)
  }


  const ESTATUS = (s:string) =>{
    let r = ''
    if(s==='open') r = 'ABIERTO'
    if(s==='completed') r = 'COMPLETADO'
    if(s==='hold') r = 'EN ESPERA'
    if(s==='canceled') r = 'CANCELADO'
    return r 
  }



const defaultValue : labelType[] = []
  const [ etiquetas_seleccionadas, setEtiquetasSeleccionada ] = useState<any>([])


  const [ dependencias, setDependencias ] = useState<any>([])
  const cargarDependencias = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('dependencias')
      setDependencias(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar dependencias')
    }
  }


  const [ show_Archivos, setArchivos ] = useState<boolean>(false)
  const handleShow_Archivos = (registro:any) => {
    setProyectoSeleccionado(registro)
    setArchivos(true)
  }
  const handleClose_Archivos = () => {
    setArchivos(false)
  }

      return (
          <>
              <div className="container-fluid contenedor">
                  


                  <h3 className="mt-3 mb-3">Proyectos</h3>





        <div className="row">
        
            <div className="">
              <label className="form-label">Búsqueda</label>
              <div className="input-group mb-3">
                <input type="text" className="form-control" onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                <button onClick={busqueda} className="btn btn-primary" type="button" ><i className="fa fa-search" /></button>
              </div>
            </div>




                <div className="row">
                  <div className="col-sm-6">
                    
                            <div className="">
                              <Form.Label>Estatus</Form.Label>

                                <Form.Check
                                  inline
                                  name="group1"
                                  type="checkbox"
                                  label="ABIERTO"
                                  checked={arreglo_estatus[0]}
                                  onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}}
                                  id={`opciones_1`}
                                />
                                

                                <Form.Check
                                  inline
                                  name="group2"
                                  type="checkbox"
                                  label="EN ESPERA"
                                  checked={arreglo_estatus[1]}
                                  onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[1] = e.target.checked;  setEstatus(ne);}}
                                  id={`opciones_2`}
                                />
                                

                                <Form.Check
                                  inline
                                  name="group3"
                                  type="checkbox"
                                  label="COMPLETADO"
                                  checked={arreglo_estatus[2]}
                                  onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[2] = e.target.checked;  setEstatus(ne);}}
                                  id={`opciones_3`}
                                />
                                

                                <Form.Check
                                  inline
                                  name="group4"
                                  type="checkbox"
                                  label="CANCELADO"
                                  checked={arreglo_estatus[3]}
                                  onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[3] = e.target.checked;  setEstatus(ne);}}
                                  id={`opciones_4`}
                                />

                          </div>

                          <ul className="list-inline">
                            <li className="list-inline-item"><small className="cursor" onClick={()=>{todos()}}>Todos</small> / <small className="cursor" onClick={()=>{ninguno()}}>Ninguno</small></li>
                            <li className="list-inline-item"><button type="button" onClick={handleShow_ModalNuevoProyecto} className="btn btn-link">Nuevo Proyecto</button></li>
                          </ul>

                  </div>
                  <div className="col-sm-6">

                        {
                          user.role_id === 0 && (

                              <Form.Group
                                controlId="ticket_type_id"
                                className="mb-3"  >
                                    <Form.Label>Dependencias</Form.Label>
                                    <Select
                                      isMulti
                                      defaultValue={defaultValue}
                                      onChange={(opt:any,e:any)=>{ setEtiquetasSeleccionada(opt) }}
                                      isClearable={true}
                                      name="colors"
                                      options={dependencias}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                    />
                            </Form.Group>

                          )
                        }          

                  </div>
                </div>




        </div>







                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <table className="table mb-3">
                                          <thead>
                                            <tr>
                                              <th scope="col">Estatus</th>
                                              <th scope="col"></th>
                                              <th scope="col">Título</th>

                                              {
                                                user.role_id === 0 && (

                                                  <th scope="col">Dependencia</th>

                                                )
                                              }


                                              <th scope="col">Monto</th>
                                              <th scope="col">Fecha de Inicio</th>
                                              <th scope="col">Fecha Límite</th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (cual:any,indice:number) =>
                                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}}>
                                                    <td className="v text-left" style={{"width":"100px"}}>
                                                      {ESTATUS(cual.status)}
                                                    </td>
                                                    <td className="v text-left" style={{width:100}}>
                                                          <ProgressBar now={cual.porcentaje_avance_tareas} variant="info" />
                                                    </td>
                                                    <td className="v text-left" >
                                                        <p onClick={(e)=>{handleShow_ModalDetallesProyecto(cual)}} className="nm pointer"><b>{cual.title}</b></p>
                                                        {
                                                          // <p className="nm"><small>{cual.description}</small></p>
                                                        }

                                                        {
                                                          cual.labels.map( (label:any) => <span key={ Math.random().toString(36).substr(2, 9) } style={{marginRight:5, backgroundColor : label.color}} className="badge">{label.title}</span> )
                                                        }


                                                    </td>


                                                    {
                                                      user.role_id === 0 && (

                                                          <td style={{width:250}}>
                                                              {cual.dependencia.company_name}
                                                          </td>

                                                      )
                                                    }

                                                    <td style={{width:100}}>
                                                    $ {cual.price.toFixed(2)}
                                                    </td>
                                                    <td style={{width:100}}>
                                                    { cual.start_date && ( moment(cual.start_date).format('DD-MM-YYYY').toUpperCase() ) }
                                                    </td>
                                                    <td style={{width:100}}>
                                                    {cual.deadline ? moment(cual.deadline).format('DD-MM-YYYY').toUpperCase() : ''}
                                                    </td>

                                                    <td style={{width:40}}>
                                                      <button type="button" onClick={(e)=>{handleShow_ModalEditarProyecto(cual)}} className="btn btn-sm btn-warning"><i className="fa fa-edit" /></button>
                                                    </td>
                                                    <td style={{width:40}}>
                                                      <button type="button" onClick={(e)=>{eliminar(cual.id)}} className="btn btn-sm btn-danger"><i className="fa fa-trash" /></button>
                                                    </td>
                                                    <td className="v" style={{width:40}}>
                                                      <button type="button" onClick={(e)=>{handleShow_Archivos(cual)}} className="btn btn-sm btn-primary"><i className="fa fa-cloud" /></button>
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargar}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}







              </div>






              {
                show_ModalNuevoProyecto && (
                  <ModalNuevoProyecto show={show_ModalNuevoProyecto} handleClose={handleClose_ModalNuevoProyecto}  />
                  )

              }


              {
                show_ModalDetallesProyecto && (
                  <ModalDetallesProyecto show={show_ModalDetallesProyecto} handleClose={handleClose_ModalDetallesProyecto} proyecto={proyecto_seleccionado}  />
                  )

              }


              {
                show_ModalEditarProyecto && (
                  <ModalEditarProyecto show={show_ModalEditarProyecto} handleClose={handleClose_ModalEditarProyecto} proyecto={proyecto_seleccionado} />
                  )

              }





  {
    show_Archivos && (
      <Archivos show={show_Archivos} handleClose={handleClose_Archivos} tipo_archivo="proyectos" registro={proyecto_seleccionado} />
      )

  }




          </>

      );
  

}

export default Proyectos
