import { Navigate } from 'react-router-dom'
import AccessDenied from './pages/AccessDenied'
import AuthService from './features/auth/auth.service'

import { ROLE } from './features/auth/role'



interface Props {
  component: React.ComponentType
  path?: string
  roles: Array<ROLE>
}

export const PrivateRoute: React.FC<Props> = ({ component: RouteComponent, roles }) => {
  // const user = useSelector(selectCurrentUser)
  const user = AuthService.getCurrentUser()

  // roles = Array.from(roles)
  var userHasRequiredRole = false

  var ROLES : any = []
  for (var i = 0; i < roles.length; ++i) {
    ROLES.push(roles[i])
    if(roles[i] === user.rol){
         userHasRequiredRole = true
    }
  }

  const isAuthenticated = AuthService.selectIsAuthenticated

  if (isAuthenticated && userHasRequiredRole) {
    return <RouteComponent />
  }

  if (isAuthenticated && !userHasRequiredRole) {
    return <AccessDenied />
  }

  return <Navigate to="/" />
}
