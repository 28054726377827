import moment from 'moment'

export const puntajeCalificacionEtiqueta = (calificacion:number) => {
  let cali = ''
  if(calificacion===0) cali = ''
  if(calificacion===1) cali = 'Pobre'
  if(calificacion===2) cali = 'Regular'
  if(calificacion===3) cali = 'Aceptable'
  if(calificacion===4) cali = 'Buena'
  if(calificacion===5) cali = 'Excelente'
  return cali
}





export const esFechaFinMayor = (fecha_inicio:any, fecha_fin:any) => {
    const inicio = new Date(fecha_inicio);
    const fin = new Date(fecha_fin);

    return fin > inicio;
}





export const calcularTiempoTranscurrido = (fecha:any)=>{
  const fechaConsulta = moment();
  const fechaParametro = moment(fecha);

  // Caso 1: Mismo día
  if (fechaConsulta.isSame(fechaParametro, 'day')) {
    const horasTranscurridas = fechaConsulta.diff(fechaParametro, 'hours');
    const minutosTranscurridos = fechaConsulta.diff(fechaParametro, 'minutes') % 60;

    if (horasTranscurridas > 0) {
      return `Hace ${horasTranscurridas} horas y ${minutosTranscurridos} minutos`;
    } else if (minutosTranscurridos > 0) {
      return `Hace ${minutosTranscurridos} minutos`;
    } else {
      return 'Hace unos momentos';
    }
  }

  // Caso 2: Ayer
  if (fechaConsulta.isSame(fechaParametro.clone().add(1, 'day'), 'day')) {
    return 'Ayer';
  }

  // Caso 3: Más de 2 días
  if (fechaConsulta.diff(fechaParametro, 'months') >= 2) {
    return `Hace ${fechaConsulta.diff(fechaParametro, 'months')} meses`;
  }

  // Caso 4: Más de 12 meses + 1 día
  if (fechaConsulta.diff(fechaParametro, 'years') >= 1) {
    return `Hace ${fechaConsulta.diff(fechaParametro, 'years')} años`;
  }

  // Caso 5: Más de 2 meses pero menos de 12 meses
  const diasTranscurridos = fechaConsulta.diff(fechaParametro, 'days');
  return diasTranscurridos > 0 ? `Hace ${diasTranscurridos} días` : 'Hoy';
}





export const esHoy = (fecha:string)=> {
  const fechaActual = new Date();
  const fechaIngresada = new Date(fecha);

  // Compara el año, mes y día de la fecha actual con la fecha ingresada
  return (
    fechaActual.getFullYear() === fechaIngresada.getFullYear() &&
    fechaActual.getMonth() === fechaIngresada.getMonth() &&
    fechaActual.getDate() === fechaIngresada.getDate()
  );
}




export const  obtenerDiaDeLaSemana = (fechaCadena:any)=> {
    const dias = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    const fecha = new Date(fechaCadena);
    return dias[fecha.getDay()];
}
















