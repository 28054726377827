import React, { useState } from 'react';

import RequestService from "../../services/request.service";


import { Button, Modal, Form } from 'react-bootstrap';

import moment from 'moment'

import { toast } from 'react-toastify'


import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Select from 'react-select'


import Dialog from '../../services/Dialogs'

import AuthService from "../../features/auth/auth.service";

import './nuevo_boletin.scss'


const ModalEditarBoletin = ( params : any ) => {

  const { show, handleClose, boletin } = params

  const dialog = new Dialog();
    const currentUser  = AuthService.getCurrentUser();


const schema = yup.object().shape({
  folio : yup.string().nullable().required('Este campo es obligatorio'),
  titulo : yup.string().required('Este campo es obligatorio'),
  boletin : yup.string().required('Este campo es obligatorio'),
  estatus : yup.string(),
  bullets : yup.string().nullable(),
  idtipo_boletin : yup.string().nullable(),
  fecha : yup.string().nullable().required(),
  url_imagenes : yup.string().nullable(),
  url_audio : yup.string().nullable(),
  url_video : yup.string().nullable(),

});




  const [ tipo_boletin, setTipoBoletin ] = useState<any>([])
  const cargar = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('tipo_boletin')
      setTipoBoletin(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar los tipo boletin')
    }
  }

  React.useEffect(() => {
    cargar()
  }, []);





  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Editar Información</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(boletin)
          }



            <Formik
              validationSchema={schema}
              onSubmit={async(values:any)=>{
              
                        try{

                              if(await dialog.confirm('¿Estás seguro?',{})){
                                // console.log(values)
                                  await RequestService.update('boletin/actualizar/'+boletin.uuid, { ...values } )
                                  toast.success('El Boletín se actualizó correctamente')
                                  // handleClose()
                              }
              
                        }
                        catch(e){
                          toast.error('Error al actualizar')
                        }
                        // console.log({ ...values })
                      }}
              initialValues={{

                folio : boletin.folio, 
                titulo : boletin.titulo, 
                boletin : boletin.boletin, 
                bullets : boletin.bullets, 
                idtipo_boletin : boletin.boletin_tipo.uuid, 
                estatus : boletin.estatus, 
                fecha : new Date(moment(boletin.fecha).toString()),

                url_imagenes : boletin.url_imagenes,
                url_audio : boletin.url_audio,
                url_video : boletin.url_video,

              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form id='my-form' noValidate onSubmit={handleSubmit}>


{
  // JSON.stringify(values)
}


                    <Form.Group
                      controlId="folio"
                      className="mb-3"
                    >
                      <Form.Label>Folio</Form.Label>
                      <Form.Control
                        type="text"
                        name="folio"
                        value={values.folio}
                        onChange={handleChange}
                        isValid={!errors.folio}
                        isInvalid={!!errors.folio}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.folio}</Form.Control.Feedback>
                    </Form.Group>




                    <Form.Group
                      controlId="titulo"
                      className="mb-3"
                    >
                      <Form.Label>Título</Form.Label>
                      <Form.Control
                        type="text"
                        name="titulo"
                        value={values.titulo}
                        onChange={handleChange}
                        isValid={!errors.titulo}
                        isInvalid={!!errors.titulo}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.titulo}</Form.Control.Feedback>
                    </Form.Group>




                    <Form.Group
                      controlId="bullets"
                      className="mb-3"
                    >
                      <Form.Label>Bullets</Form.Label>
                      <Form.Control
                        type="text"
                        name="bullets"
                        as="textarea"
                        rows={4}
                        value={values.bullets}
                        onChange={handleChange}
                        isValid={!errors.bullets}
                        isInvalid={!!errors.bullets}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.bullets}</Form.Control.Feedback>
                    </Form.Group>





                    <Form.Group
                      controlId="boletin"
                      className="mb-3"
                    >
                      <Form.Label>Boletín</Form.Label>
                      <Form.Control
                        type="text"
                        name="boletin"
                        as="textarea"
                        rows={8}
                        value={values.boletin}
                        onChange={handleChange}
                        isValid={!errors.boletin}
                        isInvalid={!!errors.boletin}
                      />
                      
                      <Form.Control.Feedback type="invalid">{errors.boletin}</Form.Control.Feedback>
                    </Form.Group>


 
                    <Form.Group
                      controlId="idtipo_boletin"
                      className="mb-3"
                    >
                      <Form.Label>Tipo de Producto</Form.Label>
                        <Form.Select name="idtipo_boletin" value={values.idtipo_boletin} onChange={handleChange} isValid={touched.idtipo_boletin && !errors.idtipo_boletin} isInvalid={!!errors.idtipo_boletin}  >
                          <option value="" disabled>Selecciona un Tipo de Producto</option>
                          {
                            tipo_boletin.map( (tipo:any)=> <option key={ Math.random().toString(36).substr(2, 9) } value={tipo.value}>{tipo.label}</option> )
                          }
                        </Form.Select>
                       <Form.Control.Feedback type="invalid">{<>{errors.idtipo_boletin}</>}</Form.Control.Feedback>
                    </Form.Group>





{
// currentUser.rol === 'ROLE_ADMIN' && (

//                     <div key={`inline-radio`} className="mb-3">
                    
//                         <Form.Group
//                           controlId="price"
//                           className="mb-3"
//                         >
//                           <Form.Label>Estatus</Form.Label>

//                             <Form.Check
//                               inline
//                               label="Nuevo"
//                               value="nuevo"
//                               checked={values.estatus=='nuevo'}
//                               name="estatus"
//                             onChange={handleChange}
//                               type={'radio'}
//                               id={`nuevo`}
//                             isValid={touched.estatus && !errors.estatus}
//                             isInvalid={!!errors.estatus}
//                             />
//                             <Form.Check
//                               inline
//                               label="Cambios"
//                               value="cambios"
//                               checked={values.estatus=='cambios'}
//                               name="estatus"
//                             onChange={handleChange}
//                               type={'radio'}
//                               id={`cambios`}
//                             isValid={touched.estatus && !errors.estatus}
//                             isInvalid={!!errors.estatus}
//                             />
//                             <Form.Check
//                               inline
//                               label="Aceptado"
//                               value="aceptado"
//                               checked={values.estatus=='aceptado'}
//                               name="estatus"
//                             onChange={handleChange}
//                               type={'radio'}
//                               id={`aceptado`}
//                             isValid={touched.estatus && !errors.estatus}
//                             isInvalid={!!errors.estatus}
//                             />
//                           <Form.Control.Feedback type="invalid">{errors.estatus}</Form.Control.Feedback>
//                         </Form.Group>

//                     </div>


//   )
}





 
                    <div className="row">
                      <div className="col-6">


                    <Form.Group className="mb-3" >

                      <Form.Label>Fecha</Form.Label>
                      <Form.Control
                        type="hidden"
                        name="fecha"
                        onChange={handleChange}
                        isValid={touched.fecha && !errors.fecha}
                        isInvalid={!!errors.fecha}
                      />
                      <DatePicker inline className={`form-control ${ !!errors.fecha ? 'is-invalid' : '' } ${ !errors.fecha && values.fecha ? 'is-valid' : '' }`} selected={values.fecha} onChange={e => setFieldValue('fecha', e)} dateFormat="yyyy-MM-dd"  />
                      <Form.Control.Feedback type="invalid">{errors.fecha}</Form.Control.Feedback>
                    </Form.Group>


                      </div>
                    </div>






                    <Form.Group
                      controlId="url_imagenes"
                      className="mb-3"
                    >
                      <Form.Label>Link de Imágenes</Form.Label>
                      <Form.Control
                        type="text"
                        name="url_imagenes"
                        as="textarea"
                        rows={3}
                        value={values.url_imagenes}
                        onChange={handleChange}
                        isValid={touched.url_imagenes && !errors.url_imagenes}
                        isInvalid={!!errors.url_imagenes}
                      />
                    </Form.Group>


                

                    <Form.Group
                      controlId="url_audio"
                      className="mb-3"
                    >
                      <Form.Label>Link de Audio</Form.Label>
                      <Form.Control
                        type="text"
                        name="url_audio"
                        as="textarea"
                        rows={3}
                        value={values.url_audio}
                        onChange={handleChange}
                        isValid={touched.url_audio && !errors.url_audio}
                        isInvalid={!!errors.url_audio}
                      />
                    </Form.Group>




                    <Form.Group
                      controlId="url_video"
                      className="mb-3"
                    >
                      <Form.Label>Link de Vídeo</Form.Label>
                      <Form.Control
                        type="text"
                        name="url_video"
                        as="textarea"
                        rows={3}
                        value={values.url_video}
                        onChange={handleChange}
                        isValid={touched.url_video && !errors.url_video}
                        isInvalid={!!errors.url_video}
                      />
                    </Form.Group>





                </Form>
              )}
            </Formik>



        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Actualizar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalEditarBoletin;