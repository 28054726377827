import React, { useState } from "react";
import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'


import { Button, Modal, Form, Dropdown  } from 'react-bootstrap';


import Select from 'react-select';

import Pagination from "../../services/Pagination";
import Dialog from '../../services/Dialogs'


import {labelType} from '../Tickets/labelType'



import ModalNuevoBoletin from './ModalNuevoBoletin'
import ModalEditarBoletin from './ModalEditarBoletin'
import ModalDetallesBoletin from './ModalDetallesBoletin'



import ModalEnviarRectificarBoletin from './ModalEnviarRectificarBoletin'
import ModalRectificarBoletin from './ModalRectificarBoletin'





import { SERVER_ } from '../../config'
import { puntajeCalificacionEtiqueta, calcularTiempoTranscurrido } from '../../services/funcionesPublicas'


import Archivos from '../Archivos/Archivos'


import './boletines.scss'


const Boletines = () =>{

      const dialog = new Dialog();

  const user = AuthService.getCurrentUser()

      React.useEffect(() => {
        cargar(0)
        cargarDependencias()
        cargarProductos()
      }, []);



      const [allSessionsCount,setCounter] = useState(0);
      const sessionsPerPage = 10
      const [currentPage, setCurrentPage] = useState(1);

      const [ text_busqueda, setTextoBusqueda ] = useState('')

      const [ cargando_, setCargando ] = useState( true )

      const [ resultados, setResultados ] = useState<any>([])


      const cargar = async(page:number=1)=>{
        try{
          setCargando(true)
          let skip = 0
          if(page===0) skip = 0; else skip = (page*10);
          setCurrentPage(page===0?1:page+1)
          let response : any = await RequestService.getEndPointPost('boletines/'+arreglo_estatus+'/busqueda?where='+text_busqueda+'&skip='+skip, { dependencias : etiquetas_seleccionadas, productos : productos_seleccionados })
          if(response){
            setCounter(response.data.totalCount)
            setResultados(response.data.resultados)
          }
          setCargando(false)
        }
        catch(e){
          setCargando(false)
          setResultados([])
          toast.error('Problemas al intentar cargar tus resultados')
        }
      }


      const busqueda = async () =>{
        setCurrentPage(1)
        cargar(0)
      }

      const [ arreglo_estatus, setEstatus ] = useState<any>([true,true,true,true,true])


      const todos = () =>{
        setEstatus([true,true,true,true,true])
      }
      const ninguno = () =>{
        setEstatus([false,false,false,false,false])
      }



const defaultValue : labelType[] = []
  const [ etiquetas_seleccionadas, setEtiquetasSeleccionada ] = useState<any>([])
  const [ productos_seleccionados, setProductosSeleccionados ] = useState<any>([])


  const [ dependencias, setDependencias ] = useState<any>([])
  const cargarDependencias = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('dependencias')
      setDependencias(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar dependencias')
    }
  }



  const [ productos, setProductos ] = useState<any>([])
  const cargarProductos = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('tipo_boletin')
      setProductos(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar productos')
    }
  }



  const [ show_ModalNuevoBoletin, setModalNuevoBoletin ] = useState<boolean>(false)
  const handleShow_ModalNuevoBoletin = () => {
    setModalNuevoBoletin(true)
  }
  const handleClose_ModalNuevoBoletin = () => {
    setModalNuevoBoletin(false)
    cargar(currentPage-1)
  }



  const [ boletin_seleccionado, setBoletinSeleccionado ] = useState<any>({})
  const [ show_ModalEditarBoletin, setModalEditarBoletin ] = useState<boolean>(false)
  const handleShow_ModalEditarBoletin = (boletin:any) => {
    setBoletinSeleccionado(boletin)
    setModalEditarBoletin(true)
  }
  const handleClose_ModalEditarBoletin = () => {
    cargar(currentPage-1)
    setModalEditarBoletin(false)
  }




  const [ show_ModalDetallesBoletin, setModalDetallesBoletin ] = useState<boolean>(false)
  const handleShow_ModalDetallesBoletin = (boletin:any) => {
    setBoletinSeleccionado(boletin)
    setModalDetallesBoletin(true)
  }
  const handleClose_ModalDetallesBoletin = () => {
    cargar(currentPage-1)
    setModalDetallesBoletin(false)
  }




  const eliminar =async (uuid_boletin:string)=>{
    try{
      if(await dialog.confirm('¿Estás seguro que deseas eliminar ese boletín?',{})){
        let res = await RequestService.delete('boletin/'+uuid_boletin)
        if(res.data){
          toast.warning('El boletín se elimino correctamente')
          cargar(currentPage-1)
        }
        else{ toast.error('Error al eliminar boletín') }
      }
    }
    catch(e){
      toast('Error al intentar eliminar el boletín')
    }
  }


  const [ show_Archivos, setArchivos ] = useState<boolean>(false)
  const handleShow_Archivos = (registro:any) => {
    setBoletinSeleccionado(registro)
    setArchivos(true)
  }
  const handleClose_Archivos = () => {
    cargar(currentPage-1)
    setArchivos(false)
  }



  const [showModalRectifica, setShowModalRectifica] = useState(false);

  const handleClose = () =>{
    cargar(currentPage-1)
    setShowModalRectifica(false)
  }
  const handleShowEnviarRectificacion = async (boletin:any) =>{ 
    setBoletinSeleccionado(boletin)
    setShowModalRectifica(true)
    // await cargarComentarios(uuid_publicacion)
  }






const [ envio_server, setSeEnvio ] = useState<boolean>(false)
const rechazarBoletin = async (event:any, boletin:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
          template: `<div class="mb-3">
                <label for="exampleFormControlTextarea1" class="form-label">Escribe el detalle del rechazo del Producto Informativo</label>
                <textarea class="form-control" name="detalles_rechazado" id="exampleFormControlTextarea1" rows="3"></textarea>
              </div>

                      <div class="mb-3">
                        <label class="form-label">Calificación en la calidad de la información del contenido:</label>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" name="calificacion" type="radio" id="Pobre" value="1">
                            <label class="form-check-label" for="pobre">Pobre</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" name="calificacion" type="radio" id="Regular" value="2">
                            <label class="form-check-label" for="Regular">Regular</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" name="calificacion" type="radio" id="Aceptable" value="3">
                            <label class="form-check-label" for="Aceptable">Aceptable</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" name="calificacion" type="radio" id="Buena" value="4">
                            <label class="form-check-label" for="Buena">Buena</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" name="calificacion" type="radio" id="Excelente" value="5">
                            <label class="form-check-label" for="Excelente">Excelente</label>
                          </div>
                      </div>

              `
      })
      let dialogo : any = await dialog.waitForUser()

      console.log(dialogo)

      if(dialogo){


        try{
          if(dialogo.detalles_rechazado === '' || !dialogo.calificacion){
            toast.warning('La descripción del rechazo es obligatorio y la calificación sobre la calidad del contenido')
            return
          }
        setSeEnvio(true)
        let res_ = await RequestService.update( `boletin/rechazar/${boletin.uuid}` , { detalles_rechazado : dialogo.detalles_rechazado, calificacion : dialogo.calificacion })

        if(res_){
          toast.success('El Producto Informativo fue marcado como rechazado')
          setTimeout(()=>{
              cargar(currentPage-1)
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}








  const aceptarPublicacion = async (uuid_publicacion:string, e:any) => {

    try{

        await dialog.open({
          accept: 'Si',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: e.target,
          template: `<p>El Producto Informativo pasará al estatus <b>ACEPTADO</b> para esperar a ser hecha</p>

                      <div class="mb-3">
                        <label class="form-label">Calificación en la calidad de la información del contenido:</label>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" name="calificacion" type="radio" id="Pobre" value="1">
                            <label class="form-check-label" for="pobre">Pobre</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" name="calificacion" type="radio" id="Regular" value="2">
                            <label class="form-check-label" for="Regular">Regular</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" name="calificacion" type="radio" id="Aceptable" value="3">
                            <label class="form-check-label" for="Aceptable">Aceptable</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" name="calificacion" type="radio" id="Buena" value="4">
                            <label class="form-check-label" for="Buena">Buena</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" name="calificacion" type="radio" id="Excelente" value="5">
                            <label class="form-check-label" for="Excelente">Excelente</label>
                          </div>
                      </div>


          `
        })
        let dialogo : any = await dialog.waitForUser()
        // console.log(dialogo)
        if(dialogo && dialogo.calificacion){
            await RequestService.update('boletin/aceptar/'+uuid_publicacion, dialogo )
            cargar(currentPage-1)
            toast.success('El Producto Informativo fue aceptada')
        }
        if(!dialogo.calificacion && dialogo){
          toast.error('Debes seleccionar una Calificación para la calidad de contenido del Producto Informativo ')
          return 
        }

    }
    catch(e){
      toast.error('Problemas.')
    }


  }




  const [showModalRectificaVocero, setShowModalRectificaVocero] = useState(false);

  const handleCloseVocero = () =>{
    cargar(currentPage-1)
    setShowModalRectificaVocero(false)
  }
  const handleShowEnviarRectificacionVocero = async (boletin:any) =>{ 
    setBoletinSeleccionado(boletin)
    setShowModalRectificaVocero(true)
    // await cargarComentarios(uuid_publicacion)
  }







const revivirProducto = async (uuid_publicacion:string, e:any) =>{

    try{

        await dialog.open({
          accept: 'Si',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: e.target,
          template: `<p>Se cambiará el estatus del producto para hacer cambios</p>`
        })
        let dialogo : any = await dialog.waitForUser()
        // console.log(dialogo)
        if(dialogo){
            await RequestService.update('boletin/revivir/'+uuid_publicacion, {} )
            cargar(currentPage-1)
            toast.success('El Producto fue aceptada cambiado de estatus')
        }
        // if(!dialogo){
        //   toast.warning('El estatus del producto no fue alterado')
        //   return 
        // }

    }
    catch(e){
      toast.error('Problemas.')
    }


  }



      return (
          <>




            <div className="container-fluid contenedor">
              

              {
                // JSON.stringify(User)
              }

              <h3 className="mt-3 mb-3">Boletines</h3>


        <div className="row">
        
                <div className="">
                  <label className="form-label">Búsqueda</label>
                  <div className="input-group mb-3">
                    <input type="text" className="form-control" onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={(event) =>{ setTextoBusqueda(event.target.value)}} />
                    <button onClick={busqueda} className="btn btn-primary" type="button" ><i className="fa fa-search" /></button>
                  </div>
                </div>


                <div className="row">
                  <div className="col-sm-5">
                    
                            <div className="">
                              <Form.Label>Estatus</Form.Label>

                              <Form.Check
                                inline
                                name="group1"
                                type="checkbox"
                                label="Nuevo"
                                checked={arreglo_estatus[0]}
                                onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}}
                                id={`opciones_1`}
                              />
                              
                              <Form.Check
                                inline
                                name="group1"
                                type="checkbox"
                                label="En Cambios"
                                checked={arreglo_estatus[1]}
                                onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[1] = e.target.checked;  setEstatus(ne);}}
                                id={`opciones_2`}
                              />
                              
                              <Form.Check
                                inline
                                name="group1"
                                type="checkbox"
                                label="Corregido"
                                checked={arreglo_estatus[2]}
                                onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[2] = e.target.checked;  setEstatus(ne);}}
                                id={`opciones_3`}
                              />

                              <Form.Check
                                inline
                                name="group1"
                                type="checkbox"
                                label="Aceptado"
                                checked={arreglo_estatus[3]}
                                onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[3] = e.target.checked;  setEstatus(ne);}}
                                id={`opciones_4`}
                              />

                              <Form.Check
                                inline
                                name="group1"
                                type="checkbox"
                                label="Rechazado"
                                checked={arreglo_estatus[4]}
                                onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[4] = e.target.checked;  setEstatus(ne);}}
                                id={`opciones_5`}
                              />
                            
                          </div>

                          <ul className="list-inline">
                            <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
                            <li className="list-inline-item"><button type="button" onClick={handleShow_ModalNuevoBoletin} className="btn btn-link">Nuevo Boletín</button></li>
                            
                          </ul>


                  </div>
                  <div className="col-sm">

                    
                      <Form.Group
                        controlId="ticket_type_id"
                        className="mb-3"  >
                            <Form.Label>Tipo de Producto</Form.Label>
                            <Select
                              isMulti
                              defaultValue={[]}
                              onChange={(opt:any,e:any)=>{ setProductosSeleccionados(opt) }}
                              isClearable={true}
                              name="productos"
                              options={productos}
                              className="basic-multi-select"
                              classNamePrefix="select"
                            />
                    </Form.Group>


                  </div>

                      {
                        user.role_id === 0 && (

                            <div className="col-sm-4">

                                      <Form.Group
                                        controlId="ticket_type_id"
                                        className="mb-3"  >
                                            <Form.Label>Dependencias</Form.Label>
                                            <Select
                                              isMulti
                                              defaultValue={defaultValue}
                                              onChange={(opt:any,e:any)=>{ setEtiquetasSeleccionada(opt) }}
                                              isClearable={true}
                                              name="colors"
                                              options={dependencias}
                                              className="basic-multi-select"
                                              classNamePrefix="select"
                                            />
                                    </Form.Group>
                            </div>

                        )
                      }
                    


                </div>








          </div>





                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <table className="table mb-3">
                                          <thead>
                                            <tr>
                                              <th scope="col"></th>
                                              <th>Folio</th>
                                              <th scope="col">Título</th>
                                              <th>Usuario</th>
                                              <th>Dependencia</th>
                                              <th>Tipo</th>
                                              <th>Fecha</th>
                                              <th>Creación</th>
                                              <th></th>
                                              <th></th>
                                              <th></th>
                                              <th></th>
                                              <th></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (boletin:any,indice:number) =>
                                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}}>
                                                    <td className="v text-left" style={{"width":"100px"}}>
                                                        

                                                            {
                                                              user.rol === "ROLE_ADMIN" && (

                                                                <React.Fragment>
                                                                  
                                                                    {
                                                                      ["aceptado","rechazado"].indexOf(boletin.estatus) > -1 && (
                                                                          <span onClick={(e)=>{revivirProducto(boletin.uuid,e)}} className={'badge rounded-pill text-bg-primary '+boletin.estatus.toLowerCase() }>{boletin.estatus.toUpperCase()}</span>
                                                                        )
                                                                    }

                                                                    {
                                                                      ["aceptado","rechazado"].indexOf(boletin.estatus) === -1 && (
                                                                          <span className={'badge rounded-pill text-bg-primary '+boletin.estatus.toLowerCase() }>{boletin.estatus.toUpperCase()}</span>
                                                                        )
                                                                    }


                                                                </React.Fragment>


                                                                
                                                              )
                                                            }

                                                            {
                                                              user.rol !== "ROLE_ADMIN" && (

                                                                <React.Fragment>
                                                                  <span className={'badge rounded-pill text-bg-primary '+boletin.estatus.toLowerCase() }>{boletin.estatus.toUpperCase()}</span>
                                                                </React.Fragment>


                                                                
                                                              )
                                                            }


                                                    </td>
                                                    <td className="text-left v" style={{"width":"100px"}}>
                                                      {
                                                        boletin.folio||'?'
                                                      }
                                                    </td>
                                                    <td className="v text-left"  >
                                                        <p className="nm pointer" onClick={()=>{handleShow_ModalDetallesBoletin(boletin)}} ><small><b>{boletin.titulo}</b></small></p>
                                                    </td>
                                                    <td className="text-left v" style={{"width":"140px"}}>
                                                      <p className="nm"><b>{boletin.first_name }</b></p>
                                                      <small>{boletin.last_name}</small>
                                                    </td>
                                                    <td className="text-left v" style={{"width":"300px"}}>
                                                      {
                                                        boletin.dependencia.company_name
                                                      }
                                                    </td>
                                                    <td className="text-left v" style={{"width":"100px"}}>
                                                      {
                                                        boletin.boletin_tipo.tipo_boletin
                                                      }
                                                    </td>
                                                    <td className="text-left v" style={{"width":"100px"}}>
                                                      <p className="nm">{ moment(boletin.fecha).format('YYYY-MM-DD') }</p>
                                                    </td>
                                                    <td className="text-left v" style={{"width":"130px"}}>
                                                      <p className="nm">{ moment(boletin.fecha_creacion).format('YYYY-MM-DD HH:mm') }</p>
                                                      <small>{calcularTiempoTranscurrido(boletin.fecha_creacion)}</small>
                                                    </td>
                                                    <td className="text-center v" style={{"width":"50px"}}>
                                                      <a href={SERVER_+'boletin/formato/'+boletin.uuid} target="_blank"><i className="fa fa-file-pdf-o" /></a>
                                                    </td>

                                                      {
                                                        ["aceptado","rechazado"].indexOf(boletin.estatus) > -1 && (
                                                          <>




                                                                <td className="text-center v" colSpan={4} style={{width:170}}>
                                                                  {
                                                                    boletin.calificacion > 0 && (
                                                                          <p className="nm"><>{puntajeCalificacionEtiqueta(boletin.calificacion)}</></p>
                                                                      )
                                                                  }
                                                                </td>



                                                          </>
                                                        )
                                                      }


                                                      {
                                                        ["aceptado","rechazado"].indexOf(boletin.estatus) < 0 && (
                                                          <>




                                                    <td className="text-center v" style={{"width":"50px"}}>
                                                      {
                                                        ["aceptado","rechazado"].indexOf(boletin.estatus) < 0 && (
                                                        <button type="button" onClick={()=>{handleShow_ModalEditarBoletin(boletin)}} className="btn btn-sm btn-warning"><i className="fa fa-edit" /></button>
                                                        )
                                                      }
                                                    </td>
                                                    <td className="v"  style={{width:40}}>
                                                      {
                                                        ["aceptado","rechazado"].indexOf(boletin.estatus) < 0 && (
                                                        <button type="button" onClick={(e)=>{eliminar(boletin.uuid)}} className="btn btn-sm btn-danger"><i className="fa fa-trash" /></button>
                                                        )
                                                      }
                                                      
                                                    </td>
                                                    <td className="v" style={{width:40}}>
                                                      {
                                                        ["aceptado","rechazado"].indexOf(boletin.estatus) < 0 && (
                                                        <button type="button" onClick={(e)=>{handleShow_Archivos(boletin)}} className="btn btn-sm btn-primary"><i className="fa fa-cloud" /></button>
                                                        )
                                                      }
                                                    </td>




                                                        <td className="v" style={{width:40}}>
                                                               
                                                            {
                                                              user.rol === "ROLE_ADMIN" && (

                                                                <React.Fragment>
                                                                  
                                                                      {
                                                                        ["nuevo","corregido"].indexOf(boletin.estatus) > -1  && ( // REVISIÓN

                                                                                    <Dropdown>
                                                                                      <Dropdown.Toggle variant="success" size="sm" id="dropdown-basic">
                                                                                        <i className="fa fa-list" />
                                                                                      </Dropdown.Toggle>

                                                                                      <Dropdown.Menu>
                                                                                        <Dropdown.Item onClick={()=>{handleShowEnviarRectificacion(boletin)}}>ENVIAR A CAMBIOS</Dropdown.Item>
                                                                                        <Dropdown.Item onClick={(e)=>{aceptarPublicacion(boletin.uuid,e)}}>ACEPTAR</Dropdown.Item>
                                                                                        <Dropdown.Item onClick={(e)=>{rechazarBoletin(e,boletin)}}>RECHAZAR</Dropdown.Item>
                                                                                      </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                          
                                                                        )
                                                                      }             


                                                                      {
                                                                        (boletin.estatus === "cambios") && ( // EN ATENCIÓN
                                                                          
                                                                                  <p className="nm" style={{fontSize:12}}><small>En Corrección</small></p>
                                                                          
                                                                        )
                                                                      }

                                                                                                   
                                                                      {
                                                                        (boletin.estatus === "aceptado") && ( // ACEPTADO
                                                                          
                                                                                  <p className="nm text-center" style={{fontSize:12}}><small><i className="fa fa-check-circle"/></small></p>
                                                                          
                                                                        )
                                                                      }



                                                                </React.Fragment>


                                                                
                                                              )
                                                            }


                                                            {
                                                              user.rol !== "ROLE_ADMIN" && ( // VOCERO
                                                                
                                                                <React.Fragment>
                                                                  
                                                                  {
                                                                    (boletin.estatus === "nuevo") && ( // REVISIÓN
                                                                        <p  className="nm pointer" style={{fontSize:12}}><small><i className="fa fa-clock-o" /></small></p>
                                                                      
                                                                    )
                                                                  }

                                                                  {
                                                                    (boletin.estatus === "cambios") && ( // EN ATENCIÓN
                                                                      
                                                                              <p onClick={()=>{handleShowEnviarRectificacionVocero(boletin)}} className="nm pointer" style={{fontSize:12}}><small>ATENDER <i className="fa fa-warning" /></small></p>
                                                                      
                                                                    )
                                                                  }


                                                                  {
                                                                    (boletin.estatus === "aceptado") && ( // PUBLICADA
                                                                      
                                                                              <p className="nm text-center" style={{fontSize:12}}><small><i className="fa fa-check-circle"/></small></p>
                                                                      
                                                                    )
                                                                  }


                                                                  {
                                                                    (boletin.estatus === "reachazado") && ( // PUBLICADA
                                                                      
                                                                              <p className="nm pointer"><small>Rechazado</small></p>
                                                                      
                                                                    )
                                                                  }



                                                                </React.Fragment>

                                                              )
                                                            }

                                                        </td>



                                                          
                                                          </>
                                                        )
                                                      }







                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargar}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}




            </div>



  {
    show_ModalDetallesBoletin && (
      <ModalDetallesBoletin show={show_ModalDetallesBoletin} handleClose={handleClose_ModalDetallesBoletin} boletin={boletin_seleccionado}  />
      )

  }



  {
    show_ModalNuevoBoletin && (
      <ModalNuevoBoletin show={show_ModalNuevoBoletin} handleClose={handleClose_ModalNuevoBoletin}  />
      )

  }



  {
    show_ModalEditarBoletin && (
      <ModalEditarBoletin show={show_ModalEditarBoletin} handleClose={handleClose_ModalEditarBoletin} boletin={boletin_seleccionado}  />
      )

  }




  {
    show_Archivos && (
      <Archivos show={show_Archivos} handleClose={handleClose_Archivos} tipo_archivo="boletines" registro={boletin_seleccionado} />
      )

  }


              {
                showModalRectifica && (
                  <ModalEnviarRectificarBoletin show={showModalRectifica} handleClose={handleClose} boletin={boletin_seleccionado} />
                  )

              }

              {
                showModalRectificaVocero && (
                  <ModalRectificarBoletin show={showModalRectificaVocero} handleClose={handleCloseVocero} boletin={boletin_seleccionado} />
                  )

              }


          </>

      );
  

}

export default Boletines
