import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import { Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import moment from 'moment'


const ModalDetalleTicket = ( params : any ) => {

  const { show, handleClose, ticket } = params


  const [ comentarios, setComentarios ] = useState<any>([])
  const cargar = async()=>{
    try{
      let response : any = await RequestService.getEndPoint('tickets/comentarios/'+ticket.id)
      setComentarios(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


      React.useEffect(() => {
        cargar()
      }, []);


  return (
    <>
      <Modal show={show} dialogClassName="modal-lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Ticket info #{ticket.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="row">
            <div className="col-sm-4">

                  <dl>
                    <dt><small>Estado</small></dt>
                    <dd><p>{ticket.status}</p></dd>
                    <dt><small>Creado</small></dt>
                    <dd><p>{moment(ticket.created_at).format('YYYY-MM-DD HH:mm:ss')}</p></dd>
                    <dt><small>Dependencia</small></dt>
                    <dd><p>{ticket.client_id.company_name}</p></dd>
                    {
                      ticket.project_id && (
                        <React.Fragment>
                          <dt><small>Proyecto</small></dt>
                          <dd><p>{ticket.project_id.description||''}</p></dd>
                        </React.Fragment>
                      )
                    }

                    <dt><small>Tipo de Ticket</small></dt>
                    <dd><p>{ticket.tipo_ticket}</p></dd>
                    <dt><small>Asignado a</small></dt>
                    <dd>{ ticket.assigned_to && ( <p>{ticket.assigned_to.first_name + ' '+ ticket.assigned_to.last_name}</p> )} { !ticket.assigned_to && ( <p>Sin Asignar</p> )} </dd>
                  </dl>


            </div>
            <div className="col-sm-8">

                {
                  // JSON.stringify(comentarios)
                }

                {
                  comentarios.map( (comentario:any) =>
                    <React.Fragment key={ Math.random().toString(36).substr(2, 9) }>
                        <ul className="list-group list-group-flush nm">
                            <li className="list-group-item d-flex justify-content-between align-items-center" style={{padding:0}} key={ Math.random().toString(36).substr(2, 9) }>
                              <div className="d-flex align-items-center">
                                <img src={'./avatar.jpg'} alt={''} style={{width: "45px", height: "45px"}} className="rounded" />
                                <div className="ms-3">
                                  <p className="fw-bold mb-1">{comentario.created_by.first_name} {comentario.created_by.last_name}</p>
                                  <p className="text-muted mb-0 alias">{comentario.created_by.created_at}</p>
                                </div>
                              </div>
                            </li>
                        </ul>
                        <p>{comentario.description}</p>
                        {
                          JSON.stringify((comentario.files))
                        }
                    </React.Fragment>

                  )
                }

            </div>
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalDetalleTicket;