import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';


import Dialog from '../../services/Dialogs'

const NuevoReporte = ( params : any ) => {


  const { show, handleClose, dependencia } = params



  const dialog = new Dialog();





  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          fb_link : yup.string().required(mensajes_validacion_generico),
            fb_alcance : yup.number().required(mensajes_validacion_generico),
            fb_seguidores : yup.number().required(mensajes_validacion_generico),
            fb_me_gusta : yup.number().required(mensajes_validacion_generico),
            fb_interacciones : yup.number().required(mensajes_validacion_generico),
            tw_link : yup.string().required(mensajes_validacion_generico),
            tw_alcance : yup.number().required(mensajes_validacion_generico),
            tw_seguidores : yup.number().required(mensajes_validacion_generico),
            tw_interacciones : yup.number().required(mensajes_validacion_generico),
            ig_link : yup.string().required(mensajes_validacion_generico),
            ig_alcance : yup.number().required(mensajes_validacion_generico),
            ig_seguidores : yup.number().required(mensajes_validacion_generico),
            ig_interacciones : yup.number().required(mensajes_validacion_generico),

  });



const [ envio_server, setSeEnvio ] = useState<boolean>(false)


const enviarFormulario = async (registro:any,event:any) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ''
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.create( `reporte_red/crear_reporte` , { ...registro })

        if(res_){
          toast.success('El informe se agrego correctamente')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}






  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Reportar Red</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(dependencia)
          }



    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

            fb_link : "",
            fb_alcance : "", 
            fb_seguidores : "", 
            fb_me_gusta : "", 
            fb_interacciones : "", 
            tw_link : "", 
            tw_alcance : "", 
            tw_seguidores : "", 
            tw_interacciones : "", 
            ig_link : "", 
            ig_alcance : "", 
            ig_seguidores : "", 
            ig_interacciones : "", 


      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>


            <div className="facebook mb-4" style={{padding:'6px 8px'}}>


                  <Form.Group   className="mb-3" controlId="fb_link">
                    <Form.Label>Link de Facebook <i className="fa fa-facebook"/></Form.Label>
                    <Form.Control
                      as="textarea"
                      required
                      name="fb_link"
                      value={values.fb_link}
                      onChange={(e)=>{ setFieldValue('fb_link', (e.target.value) ) }}
                      isInvalid={!!errors.fb_link}
                    />
                    <Form.Control.Feedback type="invalid">{errors.fb_link}</Form.Control.Feedback>
                  </Form.Group>



                  <div className="row">
                    <div className="col-3">

                        <Form.Group   className="mb-3" controlId="fb_alcance">
                          <Form.Label>Alcance</Form.Label>
                          <Form.Control
                            required
                            name="fb_alcance"
                            value={values.fb_alcance}
                            onChange={(e)=>{ setFieldValue('fb_alcance', (e.target.value) ) }}
                            isInvalid={!!errors.fb_alcance}
                          />
                          <Form.Control.Feedback type="invalid">{errors.fb_alcance}</Form.Control.Feedback>
                        </Form.Group>

                    </div>
                    <div className="col-3">

                        <Form.Group   className="mb-3" controlId="fb_seguidores">
                          <Form.Label>Seguidores</Form.Label>
                          <Form.Control
                            required
                            name="fb_seguidores"
                            value={values.fb_seguidores}
                            onChange={(e)=>{ setFieldValue('fb_seguidores', (e.target.value) ) }}
                            isInvalid={!!errors.fb_seguidores}
                          />
                          <Form.Control.Feedback type="invalid">{errors.fb_seguidores}</Form.Control.Feedback>
                        </Form.Group>

                    </div>
                    <div className="col-3">

                        <Form.Group   className="mb-3" controlId="fb_me_gusta">
                          <Form.Label>Me Gusta</Form.Label>
                          <Form.Control
                            required
                            name="fb_me_gusta"
                            value={values.fb_me_gusta}
                            onChange={(e)=>{ setFieldValue('fb_me_gusta', (e.target.value) ) }}
                            isInvalid={!!errors.fb_me_gusta}
                          />
                          <Form.Control.Feedback type="invalid">{errors.fb_me_gusta}</Form.Control.Feedback>
                        </Form.Group>

                    </div>
                    <div className="col-3">

                        <Form.Group   className="mb-3" controlId="fb_interacciones">
                          <Form.Label>Interacciones</Form.Label>
                          <Form.Control
                            required
                            name="fb_interacciones"
                            value={values.fb_interacciones}
                            onChange={(e)=>{ setFieldValue('fb_interacciones', (e.target.value) ) }}
                            isInvalid={!!errors.fb_interacciones}
                          />
                          <Form.Control.Feedback type="invalid">{errors.fb_interacciones}</Form.Control.Feedback>
                        </Form.Group>

                    </div>
                  </div>



            </div>




            <div className="twitter mb-4" style={{padding:'6px 8px'}}>

                    <Form.Group   className="mb-3" controlId="tw_link">
                      <Form.Label>Link de Twitter <i className="fa fa-twitter"/></Form.Label>
                      <Form.Control
                        as="textarea"
                        required
                        name="tw_link"
                        value={values.tw_link}
                        onChange={(e)=>{ setFieldValue('tw_link', (e.target.value) ) }}
                        isInvalid={!!errors.tw_link}
                      />
                      <Form.Control.Feedback type="invalid">{errors.tw_link}</Form.Control.Feedback>
                    </Form.Group>



                    <div className="row">
                      <div className="col-4">

                          <Form.Group   className="mb-3" controlId="tw_alcance">
                            <Form.Label>Alcance</Form.Label>
                            <Form.Control
                              required
                              name="tw_alcance"
                              value={values.tw_alcance}
                              onChange={(e)=>{ setFieldValue('tw_alcance', (e.target.value) ) }}
                              isInvalid={!!errors.tw_alcance}
                            />
                            <Form.Control.Feedback type="invalid">{errors.tw_alcance}</Form.Control.Feedback>
                          </Form.Group>

                      </div>
                      <div className="col-4">

                          <Form.Group   className="mb-3" controlId="tw_seguidores">
                            <Form.Label>Seguidores</Form.Label>
                            <Form.Control
                              required
                              name="tw_seguidores"
                              value={values.tw_seguidores}
                              onChange={(e)=>{ setFieldValue('tw_seguidores', (e.target.value) ) }}
                              isInvalid={!!errors.tw_seguidores}
                            />
                            <Form.Control.Feedback type="invalid">{errors.tw_seguidores}</Form.Control.Feedback>
                          </Form.Group>

                      </div>
                      <div className="col-4">

                          <Form.Group   className="mb-3" controlId="tw_interacciones">
                            <Form.Label>Interacciones</Form.Label>
                            <Form.Control
                              required
                              name="tw_interacciones"
                              value={values.tw_interacciones}
                              onChange={(e)=>{ setFieldValue('tw_interacciones', (e.target.value) ) }}
                              isInvalid={!!errors.tw_interacciones}
                            />
                            <Form.Control.Feedback type="invalid">{errors.tw_interacciones}</Form.Control.Feedback>
                          </Form.Group>

                      </div>
                    </div>


            </div>


            <div className="instagram" style={{padding:'6px 8px'}}>


                  <Form.Group   className="mb-3" controlId="ig_link">
                    <Form.Label>Link de Instagram <i className="fa fa-instagram"/></Form.Label>
                    <Form.Control
                      as="textarea"
                      required
                      name="ig_link"
                      value={values.ig_link}
                      onChange={(e)=>{ setFieldValue('ig_link', (e.target.value) ) }}
                      isInvalid={!!errors.ig_link}
                    />
                    <Form.Control.Feedback type="invalid">{errors.ig_link}</Form.Control.Feedback>
                  </Form.Group>



                  <div className="row">
                    <div className="col-4">

                        <Form.Group   className="mb-3" controlId="ig_alcance">
                          <Form.Label>Alcance</Form.Label>
                          <Form.Control
                            required
                            name="ig_alcance"
                            value={values.ig_alcance}
                            onChange={(e)=>{ setFieldValue('ig_alcance', (e.target.value) ) }}
                            isInvalid={!!errors.ig_alcance}
                          />
                          <Form.Control.Feedback type="invalid">{errors.ig_alcance}</Form.Control.Feedback>
                        </Form.Group>

                    </div>
                    <div className="col-4">

                        <Form.Group   className="mb-3" controlId="ig_seguidores">
                          <Form.Label>Seguidores</Form.Label>
                          <Form.Control
                            required
                            name="ig_seguidores"
                            value={values.ig_seguidores}
                            onChange={(e)=>{ setFieldValue('ig_seguidores', (e.target.value) ) }}
                            isInvalid={!!errors.ig_seguidores}
                          />
                          <Form.Control.Feedback type="invalid">{errors.ig_seguidores}</Form.Control.Feedback>
                        </Form.Group>

                    </div>
                    <div className="col-4">

                        <Form.Group   className="mb-3" controlId="ig_interacciones">
                          <Form.Label>Interacciones</Form.Label>
                          <Form.Control
                            required
                            name="ig_interacciones"
                            value={values.ig_interacciones}
                            onChange={(e)=>{ setFieldValue('ig_interacciones', (e.target.value) ) }}
                            isInvalid={!!errors.ig_interacciones}
                          />
                          <Form.Control.Feedback type="invalid">{errors.ig_interacciones}</Form.Control.Feedback>
                        </Form.Group>

                    </div>
                  </div>


            </div>



        </Form>
      )}
    </Formik>






        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default NuevoReporte;